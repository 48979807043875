import React from "react";
import { Flex, HStack, IconButton, useBreakpoint } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { FiMenu } from "react-icons/fi";

import { Logo } from "./sidebar";
import { UserMenu } from "./user-menu";
import { NotificationPopover } from "./notification-popover";
import styled from "@emotion/styled";

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  &:hover:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["400"]};
  }
`;

export const Header = ({ onClose, onToggle }) => {
  let location = useLocation();
  const screenSize = useBreakpoint() ?? "";

  React.useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <Flex
      as="header"
      w="100%"
      alignItems="center"
      justifyContent={{ base: "space-between", md: "flex-end" }}
      borderBottomWidth="1px"
      position="fixed"
      height={{ base: "50px", md: "60px" }}
      width="100%"
      zIndex="10"
      bg={{ base: "gray.900", md: "white" }}
    >
      {screenSize === "sm" && <Logo />}

      <HStack mx={6} spacing={{ base: 0, md: 3 }}>
        <NotificationPopover />
        <UserMenu />
        <StyledIconButton
          display={{ base: "block", md: "none" }}
          ml={0}
          mx={{ base: 0, md: 6 }}
          icon={<FiMenu fontSize="28px" />}
          boxSize="40px"
          aria-label="Open the Menu"
          onClick={onToggle}
          variant="ghost"
          color={{ base: "white", md: "gray.900" }}
          borderRadius="99px"
        />
      </HStack>
    </Flex>
  );
};
