import React, { useCallback } from "react";
import { createAPIClient } from "../api";
import { useAsync } from "react-async";
import { useMap, useUnmount } from "react-use";

type Fetcher =
  | ReturnType<typeof createAPIClient>["cases"]["getAttachment"]
  | ReturnType<typeof createAPIClient>["images"]["attachments"]["get"];

type Props = {
  id: string;
  fetcher: Fetcher;
  filename: string;
  createdAt?: string;
  children?: (
    data: string | undefined,
    error: Error | undefined,
    isPending: boolean
  ) => React.ReactNode;
};
export const FetchAttachment: React.FC<Props> = props => {
  const {
    id,
    filename,
    createdAt = "",
    children,
    fetcher: propFetcher
  } = props;
  const { get, set } = useObjectURLCache();
  const fetcher = useCallback(
    async (_, { signal }: AbortController) => {
      // Attachment has no explicit id, make union one here,
      //  in case a new file having same filename uses the URL of the old file.
      const key = [filename, createdAt].join();
      if (!get(key)) {
        set(key, await propFetcher(id, filename, { signal }));
      }
      return get(key);
    },
    [id, filename, createdAt, get, set, propFetcher]
  );
  const { data, error, isPending } = useAsync(fetcher);
  return <>{children?.(data, error, isPending) ?? null}</>;
};

/**
 * Provide a local cache to hold Object URLs and clean Objects when unmounting.
 * We could, but better not, put this at higher level such as CaseAttachmentsTabContent or even global, for better effect.
 * Better not to hold big Blobs in memory for a long time.
 */
function useObjectURLCache() {
  const [cache, { get, set }] = useMap<{ [key: string]: string }>({});
  // Clean up by letting GC know
  useUnmount(() => {
    Object.values(cache).forEach(x => {
      URL.revokeObjectURL(x);
    });
  });
  return { get, set };
}
