import React from "react";
import {
  ButtonProps,
  Flex,
  ListItem,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";

import { Button, CloseButton, useModal } from "components/core";

export const DialogDetails = ({ onClose }) => {
  return (
    <>
      <ModalHeader display="flex" justifyContent="space-between">
        Purpose of use of Image Analysis AI results
        <CloseButton onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <Flex>
          <Stack>
            <Text>
              The User agrees that the Image Analysis AI functionality may only
              be used for the following purposes and shall not be used for the
              diagnosis, treatment or prevention of human or animal diseases or
              for any purpose that affects the structure or function of the
              human or animal body.
            </Text>
            <UnorderedList spacing={2} pl={4}>
              <ListItem>
                Medical education purposes, such as improving users'
                pathological diagnosis skills
              </ListItem>
              <ListItem>
                User's physical and chemical research objectives
              </ListItem>
            </UnorderedList>
          </Stack>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </>
  );
};

export const ShowDetailsButton = (props: ButtonProps) => {
  const modal = useModal();

  const showDetails = () => {
    modal.dialog({
      render: close => <DialogDetails onClose={close} />,
      modalProps: { size: "4xl" }
    });
  };

  return (
    <Button
      variant="link"
      onClick={showDetails}
      _focus={{ outline: "none" }}
      {...props}
    >
      Detail
    </Button>
  );
};
