import React, { useRef, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useList } from "react-use";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";

import { useAPI, useSWRApi } from "api";
import { useLocale } from "app/locale";
import { hasPermission } from "app/permissions";
import { UploadCasesImagesContainer } from "app/upload-cases-images-container";
import {
  AddIcon,
  Alert,
  Button,
  CloseIcon,
  FileChooser,
  PDFViewer,
  Spinner,
  useModal
} from "components/core";
import { FetchCaseState } from "pages/cases/case-item/case-item-fetch-data";
import { isAttachments, SUPPORTED_ATTACHMENT_TYPES } from "utils/files";
import {
  CaseAttachmentList,
  UploadingAttachment
} from "./case-attachment-list";
import { FetchAttachment } from "../../app/attachment";

export const CaseAttachments = ({
  pathologicCase,
  reload
}: Pick<FetchCaseState, "pathologicCase" | "reload">) => {
  const locale = useLocale();
  const toast = useToast();
  const fileChooserRef = useRef<FileChooser | null>(null);
  const modal = useModal();
  const api = useAPI();

  const { id: caseId, caseNumber } = pathologicCase;
  const { addFiles } = UploadCasesImagesContainer.useContainer();
  const { data, error, mutate } = useSWRApi(api.cases.listAttachments, caseId, {
    initialData: { caseId, attachments: pathologicCase.attachments }
  });
  const [viewFile, setViewFile] = useState<Medmain.Attachment>();
  // TODO Should we cancel upload when unmounting
  const [files, filesActions] = useList<UploadingAttachment>();
  const canAddAttachment = hasPermission("case/update", pathologicCase);

  // show automatically the single PDF available when data is loaded OR after the 1st PDF is added
  useEffect(() => {
    if (data?.attachments?.length === 1) {
      const firstAttachment = data?.attachments?.[0];
      setViewFile(firstAttachment);
    }
  }, [data]);

  if (!data) return <Spinner />;
  if (error) return <Alert>Error! {error.message}</Alert>;

  async function handleDelete(attachment: Medmain.Attachment) {
    const ok = await modal.confirm(
      locale.todo("Are you sure you want to delete the file?"),
      { title: locale.todo("Delete Attachment") }
    );
    if (!ok) return;
    try {
      await api.cases.deleteAttachment(caseId, attachment.filename);
      if (viewFile?.filename === attachment.filename) {
        setViewFile(undefined);
      }
      mutate();
      reload(); // Needed to sync comment count in the panel header
    } catch (e) {
      toast({
        description: `Failed to delete "${attachment.filename}"`,
        status: "error"
      });
    }
  }

  async function handleDownload(attachment: Medmain.Attachment) {
    try {
      const blobUrl = await api.cases.getAttachment(
        caseId,
        attachment.filename
      );
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${attachment.filename}`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      toast({
        description: `Failed to download "${attachment.filename}"`,
        status: "error"
      });
    }
  }

  // TODO Together with api restructuring, make the entire process better.
  async function onChooseFiles(addedFiles: File[]) {
    if (!ensurePDF(addedFiles)) return;
    if (!ensureUniqueNames(addedFiles)) return;

    const attachments: UploadingAttachment[] = addedFiles.map(
      file => new UploadingAttachment(file)
    );
    filesActions.push(...attachments);

    await addFiles({
      caseId,
      caseNumber,
      filesToUpload: addedFiles,
      onProgress: (file: Medmain.UploadFile, progress: number) => {
        const attachment = findAttachmentByName(file.filename);
        if (!attachment) return;
        filesActions.updateAt(
          files.indexOf(attachment),
          attachment.setProgress(progress)
        );
      },
      onError: (file: Medmain.UploadFile, error: Error) => {
        const attachment = findAttachmentByName(file.filename);
        if (!attachment) return;
        filesActions.removeAt(files.indexOf(attachment));
      },
      onEnd: (file: Medmain.UploadFile) => {
        const attachment = findAttachmentByName(file.filename);
        if (!attachment) return;
        mutate(data => {
          filesActions.filter(({ filename }) => filename !== file.filename);
          return { ...data, attachments: [...data.attachments, attachment] };
        });
        reload(); // Needed to sync comment count in the panel header
      }
    });

    function ensurePDF(files: File[]) {
      const invalidFilenames: string[] = files
        .map(file => file.name)
        .filter(filename => !isAttachments(filename));

      if (invalidFilenames.length) {
        const description = `Unsupported files: ${invalidFilenames.join(", ")}`;
        toast({
          title: "Please choose PDF files.",
          description,
          status: "error"
        });
        return false;
      }

      return true;
    }

    function ensureUniqueNames(files: File[]): boolean {
      const filenames = data?.attachments.map(file => file.filename) || [];
      const duplicateFilenames = files
        .map(file => file.name)
        .filter(filename => filenames.includes(filename));

      if (duplicateFilenames.length) {
        const description = duplicateFilenames.join(", ");
        toast({
          title: "Duplicate files",
          description,
          status: "error"
        });
        return false;
      }

      return true;
    }

    function findAttachmentByName(filename: string) {
      return attachments.find(attachment => attachment.filename === filename);
    }
  }

  const attachments = [...data.attachments, ...files].sort((a, b) =>
    a.filename < b.filename ? -1 : a.filename > b.filename ? 1 : 0
  );

  return (
    <>
      <Box pt={2}>
        <FileChooser
          onChoose={onChooseFiles}
          ref={fileChooserRef}
          acceptedTypes={SUPPORTED_ATTACHMENT_TYPES.map(
            file => file.acceptedTypes
          )}
          acceptedExtensions={SUPPORTED_ATTACHMENT_TYPES.map(
            file => file.acceptedExtensions
          )}
        />
        <CaseAttachmentList
          caseId={caseId}
          attachments={attachments}
          canAddAttachment={canAddAttachment}
          onDeleteAttachment={handleDelete}
          onDownloadAttachment={handleDownload}
        />
        {canAddAttachment && (
          <Flex mt={4} justifyContent="flex-end">
            <Button
              onClick={() => fileChooserRef?.current?.open()}
              primary
              leftIcon={<AddIcon />}
            >
              {locale.todo("Add Attachments")}
            </Button>
          </Flex>
        )}
      </Box>
    </>
  );
};

type AttachmentViewerProps = {
  caseId: string;
  filename: string;
  createdAt: string;
  height?: string | number;
  isViewHeader?: boolean;
};

export function AttachmentViewer(props: AttachmentViewerProps) {
  const {
    caseId,
    filename,
    createdAt,
    height = "1200",
    isViewHeader = true
  } = props;
  const history = useHistory();
  const location = useLocation();
  const api = useAPI();

  return (
    <FetchAttachment
      id={caseId}
      filename={filename}
      createdAt={createdAt}
      fetcher={api.cases.getAttachment}
    >
      {(data, error, isPending) =>
        error ? (
          <Alert>Please go back and view this PDF again.</Alert>
        ) : isPending || !data ? (
          <Spinner />
        ) : (
          <Box data-testid="pdf-viewer">
            {isViewHeader && (
              <Flex
                fontSize="xl"
                position="relative"
                mb={3}
                flexDirection={{ sm: "column-reverse", md: "row" }}
              >
                <Box
                  flexGrow={1}
                  alignSelf={{ sm: "flex-start", md: "center" }}
                  wordBreak="break-word"
                  title={filename}
                >
                  <Text noOfLines={2}>{filename}</Text>
                </Box>
                <Button
                  onClick={() =>
                    history.push({ ...location, pathname: `/cases/${caseId}` })
                  }
                  rightIcon={<CloseIcon />}
                  minWidth="152px"
                  alignSelf={{ sm: "flex-end", md: "center" }}
                  ml="10px"
                  mb={{ sm: "10px", md: 0 }}
                >
                  Back to Images
                </Button>
              </Flex>
            )}
            <PDFViewer url={data} height={height} />
          </Box>
        )
      }
    </FetchAttachment>
  );
}
