import React from "react";
import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { useCaseLegalWarning, AgreementContainer } from "./agreement";
import { ShowDetailsButton } from "./dialog-details";

export const LegalWarning = () => {
  const { shouldAskAgreement } = AgreementContainer.useContainer();
  const { shouldShowWarning } = useCaseLegalWarning();

  // Don't show the warning if the Agreement modal is showing
  if (shouldAskAgreement()) return null;

  if (!shouldShowWarning()) return null;

  return (
    <Alert
      status="info"
      mb={4}
      py={1}
      borderRadius="4px"
      bg="primary.50"
      borderColor="primary.100"
    >
      <AlertIcon fontSize="16px" color="gray.900" />
      <AlertDescription flexGrow={1}>
        The use of Image Analysis AI results has restriction.
        <ShowDetailsButton color="primary.500" ml="5px" />
      </AlertDescription>
    </Alert>
  );
};
