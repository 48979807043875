import React from "react";
import {
  ButtonGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";
import { useAsync } from "react-async";

import { useLocale } from "app/locale";
import { useAPI } from "api";
import { Button, ModalCloseButton } from "components/core";

export const DialogDeleteCase = ({ pathologicCase, close }) => {
  const api = useAPI();
  const locale = useLocale();

  const { attachments, images, numberOfComments } = pathologicCase;
  const numberOfImages = images.length;
  const numberOfAttachments = attachments.length;
  const hasChildItem =
    numberOfAttachments > 0 || numberOfComments > 0 || numberOfImages > 0;

  const { run: deleteCase, isPending, error } = useAsync({
    deferFn: () =>
      api.cases.delete({ caseId: pathologicCase.id, recursive: hasChildItem }),
    onResolve: () => close(true)
  });
  const onSubmit = event => {
    event.preventDefault();
    deleteCase();
  };

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader>
        {locale.todo("Delete Case")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text>
          {locale.todo("Are you sure you want to delete the case")}{" "}
          <code>{pathologicCase.caseNumber}</code>?
        </Text>
        {hasChildItem && (
          <Alert status="warning" alignItems="flex-start" mt={4}>
            <AlertIcon />
            <AlertDescription>
              <Text mb={0}>The following items will be deleted:</Text>
              <UnorderedList styleType="disc">
                {numberOfImages > 0 && (
                  <ListItem>Images: {numberOfImages}</ListItem>
                )}
                {numberOfAttachments && (
                  <ListItem>Attachments: {numberOfAttachments}</ListItem>
                )}
                {numberOfComments && (
                  <ListItem>Comments: {numberOfComments}</ListItem>
                )}
              </UnorderedList>
            </AlertDescription>
          </Alert>
        )}
        {error && (
          <Alert status="error">
            {locale.todo("Unable to delete the case")} {error.message}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => close(false)} isDisabled={isPending}>
            {locale.todo("Cancel")}
          </Button>
          <Button
            type="submit"
            colorScheme="red"
            variant="solid"
            isLoading={isPending}
          >
            {locale.todo("Delete")}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};
