export function getConfig() {
  return {
    api: {
      rootURL: readEnvVariable("API"),
      mockEndPoints: readEnvVariable("MOCK", { required: false })
    },
    paths: {
      logout: "/signed-out"
    },
    auth0: {
      domain: readEnvVariable("AUTH0_DOMAIN"),
      clientID: readEnvVariable("AUTH0_CLIENT_ID"),
      audience: readEnvVariable("AUTH0_AUDIENCE"),
      redirectUri: getAbsoluteURL(readEnvVariable("AUTH0_REDIRECT_URI"))
    }
  };
}

export const isNikon = !!readEnvVariable("IS_NIKON", { required: false });

// Read variable from enviroment, also from `.env` file when in development.
export function readEnvVariable(
  key: string,
  { required = true, fallback = "" } = {}
) {
  // See https://create-react-app.dev/docs/adding-custom-environment-variables/#docsNav
  const value = process.env[`REACT_APP_${key}`];
  if (required && value === undefined)
    throw new Error(`Env. variable "${key}" is required!`);
  return value || fallback;
}

export function getAPIMockEndPoints() {
  const {
    api: { mockEndPoints }
  } = getConfig();
  return mockEndPoints.split(",").filter(value => value !== "");
}

function getAbsoluteURL(relativeURL: string | undefined): string {
  if (!relativeURL) throw new Error(`Invalid relative URL`);
  if (relativeURL.startsWith("http")) return relativeURL;

  const { origin } = window.location; // E.g. `http://localhost:3000`
  return `${origin}${relativeURL}`;
}
