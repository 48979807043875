// Mapping between the filename extensions and the content type sent to the API when uploading
// No need to setup the usual file extensions (jpeg)
const contentTypesByExtension = {
  ".svs": "image/tiff",
  ".ndpi": "image/tiff"
};

export function getFileContentType({ name, type }): string {
  const extension = getFileExtension(name);

  const fileType = contentTypesByExtension[extension];

  if (fileType) {
    return fileType;
  }
  if (type) {
    return type;
  }
  throw new Error(`No file type associated to this extension "${extension}"`);
}

export function getFileExtension(filename: string): string {
  const index = filename.lastIndexOf(".");
  if (index !== -1) {
    return filename.slice(index);
  }
  return "";
}

export const SUPPORTED_ATTACHMENT_TYPES = [
  {
    acceptedTypes: "application/pdf",
    acceptedExtensions: ".pdf"
  },
  {
    acceptedTypes: "application/msword",
    acceptedExtensions: ".doc"
  },
  {
    acceptedTypes:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    acceptedExtensions: ".docx"
  },
  {
    acceptedTypes: "application/vnd.ms-powerpoint",
    acceptedExtensions: ".ppt"
  },
  {
    acceptedTypes:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    acceptedExtensions: ".pptx"
  },
  {
    acceptedTypes: "application/vnd.ms-excel",
    acceptedExtensions: ".xls"
  },
  {
    acceptedTypes:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    acceptedExtensions: ".xlsx"
  },
  {
    acceptedTypes: "text/csv",
    acceptedExtensions: ".csv"
  },
  {
    acceptedTypes: "application/zip",
    acceptedExtensions: ".zip"
  }
] as const;

export function isPDF(filename: string): boolean {
  return filename.toLowerCase().endsWith("pdf");
}

export function isAttachments(filename: string): boolean {
  return SUPPORTED_ATTACHMENT_TYPES.some(
    file =>
      file.acceptedExtensions ===
      filename
        .toLowerCase()
        .split(/(?=\.)/)
        .pop()
  );
}
