import groupBy from "lodash/groupBy";
import {
  Box,
  BoxProps,
  Grid,
  StyleProps,
  useBreakpoint
} from "@chakra-ui/react";
import styled from "@emotion/styled";

import { useLocale } from "app/locale";
import organs from "data/organs.json";
import modelData from "data/ai-prediction-models.json";
import { PredictionModel } from "components/common/model-picker";
import { Select } from "components/core/select";

export type FormLayout = "horizontal" | "vertical";

export const FieldGroup = ({
  layout,
  ...props
}: { layout?: FormLayout } & BoxProps) => {
  return layout === "horizontal" ? (
    <Grid templateColumns={"220px 1fr"} rowGap={4} {...props} />
  ) : (
    <VerticalContainer templateColumns="100%" rowGap={2} {...props} />
  );
};

// Add extra spacing after every field in the vertical layout
const VerticalContainer = styled(Grid)`
  > *:nth-of-type(2n) {
    padding-bottom: 0.5rem;
  }
`;

/*
Generic component to build a form row, with a horizontal layout by default:
- the field label on the left
- the form control (input, select...) on the right
*/
export const EditableField = ({
  label,
  htmlFor,
  children,
  isRequired,
  ...props
}: {
  label: string;
  children: React.ReactNode;
  isRequired?: boolean;
  htmlFor?: string;
} & StyleProps) => {
  return (
    <>
      <Box display="flex" alignItems="center" fontWeight={500} {...props}>
        <label htmlFor={htmlFor}>{label}</label>{" "}
        {isRequired && (
          <Box as="span" color="red.700">
            *
          </Box>
        )}
      </Box>
      <Box padding={1} {...props}>
        {children || <EmptyContent />}
      </Box>
    </>
  );
};

export const ReadonlyField = ({
  label,
  children,
  ...props
}: {
  label: string;
  children: React.ReactNode;
} & BoxProps) => {
  return (
    <>
      <Box pt={2} pb={2} borderStyle="dashed" fontWeight={500} {...props}>
        {label}
      </Box>
      <Box
        pb={2}
        borderBottomWidth="1px"
        borderStyle="dashed"
        whiteSpace="pre-line"
        {...props}
      >
        {children || <EmptyContent />}
      </Box>
    </>
  );
};

const EmptyContent = () => <Box color="gray.400">-</Box>;

type SpecimenTypes = {
  [key: string]: string;
};
export const SpecimenTypePicker = ({ value, onChange, ...otherProps }) => {
  const locale = useLocale();
  const specimenTypes: SpecimenTypes = locale.caseFields.specimenType.options;
  const specimenTypeOptions = Object.entries(specimenTypes).map(
    ([value, label]) => ({
      value,
      label
    })
  );
  const options = [emptyOption, ...specimenTypeOptions];

  return (
    <Select
      id="specimenType"
      value={value}
      onChange={onChange}
      options={options}
      {...otherProps}
    />
  );
};

export const OrganPicker = ({ value, onChange, ...otherProps }) => {
  const organOptions = organs.map(organ => ({ label: organ, value: organ }));
  const options = [emptyOption, ...organOptions];
  const screenSize = useBreakpoint() ?? "";

  return (
    <Select
      id="organ"
      value={value}
      onChange={onChange}
      options={options}
      filterOption
      showSearch={screenSize !== "sm"}
      {...otherProps}
    />
  );
};

export const RelatedModelOrganPicker = ({
  value,
  onChange,
  currentModel,
  ...otherProps
}) => {
  const models = modelData as PredictionModel[];
  const organByModel =
    currentModel &&
    models.find(model => model.modelName === currentModel)?.organ.toLowerCase();
  const organsByModel = organs.filter(organ => organ === organByModel);
  const organOptions = (organsByModel.length ? organsByModel : organs).map(
    organ => ({
      label: organ,
      value: organ
    })
  );
  const options = [emptyOption, ...organOptions];
  const screenSize = useBreakpoint() ?? "";

  return (
    <Select
      id="organ"
      value={value}
      onChange={onChange}
      options={options}
      filterOption
      showSearch={screenSize !== "sm"}
      {...otherProps}
    />
  );
};

export const ModelPicker = ({
  value,
  onChange,
  currentOrgan,
  isEnum = false,
  ...otherProps
}) => {
  const models = modelData as PredictionModel[];
  const modelsByOrgan = groupBy(models, "organ");
  const currentModelsKey =
    currentOrgan &&
    Object.keys(modelsByOrgan).find(
      model => model.toUpperCase() === currentOrgan.toUpperCase()
    );
  const currentModels = currentModelsKey
    ? modelsByOrgan[currentModelsKey]
    : models;
  const modelOptions = Array.from(
    new Set([
      ...models.filter(
        model => model[isEnum ? "modelNameEnum" : "modelName"] === value
      ),
      ...currentModels
    ])
  ).map(model => ({
    label: model.displayName,
    value: model[isEnum ? "modelNameEnum" : "modelName"]
  }));

  const options = modelOptions ? [emptyOption, ...modelOptions] : [emptyOption];
  const screenSize = useBreakpoint() ?? "";

  return (
    <Select
      id="model"
      value={value}
      onChange={onChange}
      options={options}
      filterOption
      showSearch={screenSize !== "sm"}
      {...otherProps}
    />
  );
};

const emptyOption = { value: "", label: "" };
