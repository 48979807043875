import { useState } from "react";
import { createContainer } from "unstated-next";
import pMap from "p-map";
import { useCounter } from "react-use";
import debugModule from "debug";

import { useAPI } from "api";
import { isAttachments } from "utils/files";
import { useCaseUploadNotification } from "./notification-container";
import { BulkUploadFile } from "./upload-bulk-images-container";

const debug = debugModule("medmain:upload");

export const SUPPORTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/tiff",
  ".svs",
  ".ndpi"
];

export const SUPPORTED_IMAGE_TYPES_EXTENSIONS = [
  {
    acceptedTypes: "application/octet-stream",
    acceptedExtensions: ".svs"
  },
  {
    acceptedTypes: "application/octet-stream",
    acceptedExtensions: ".ndpi"
  },
  {
    acceptedTypes: "image/png",
    acceptedExtensions: ".png"
  },
  {
    acceptedTypes: "image/jpeg",
    acceptedExtensions: ".jpeg"
  },
  {
    acceptedTypes: "image/jpeg",
    acceptedExtensions: ".jpg"
  },
  {
    acceptedTypes: "application/tiff",
    acceptedExtensions: ".tif"
  },
  {
    acceptedTypes: "application/tiff",
    acceptedExtensions: ".tiff"
  }
] as const;

export interface ImageEditableData {
  displayName?: Medmain.Image["displayName"];
  organ?: Medmain.Image["organ"];
  specimenType?: Medmain.Image["specimenType"];
  modelName?: Medmain.Image["modelName"];
  imageTags?: string[];
}

type CaseUploadParams = {
  caseId: string;
  caseNumber: string;
  files: Medmain.UploadFile[];
  metaData?: ImageEditableData;
  onStart?: (file: Medmain.UploadFile) => void;
  onProgress?: (file: Medmain.UploadFile, value: number) => void;
  onEnd?: (file: Medmain.UploadFile) => void;
  onError?: (file: Medmain.UploadFile, error: Error) => void;
};

function useUploadState() {
  const api = useAPI();

  const {
    notifyStartUpload,
    notifyCompleteUpload,
    notifyUploadError
  } = useCaseUploadNotification();

  const [currentFiles, setCurrentFiles] = useState<Medmain.UploadFile[]>([]);
  const [
    completeCount,
    { inc: incrementCompleteCount, reset: resetCompleteCount }
  ] = useCounter(0);

  const updateByFilename = (
    caseId: Medmain.Case["id"],
    filename: string,
    changes: Record<string, any>
  ) => {
    setCurrentFiles(files =>
      files.map(file =>
        isMatchingFile(caseId, filename)(file) ? { ...file, ...changes } : file
      )
    );
  };

  const removeByFilename = (caseId: Medmain.Case["id"], filename: string) => {
    setCurrentFiles(files =>
      files.filter(file => !isMatchingFile(caseId, filename)(file))
    );
  };

  const addFiles = async ({
    caseId,
    caseNumber,
    filesToUpload,
    metaData,
    ...otherParams
  }: Omit<CaseUploadParams, "files"> & {
    filesToUpload: File[];
  }) => {
    const addedFiles: Medmain.UploadFile[] = filesToUpload.map(
      (file: File) => ({
        addedAt: new Date(),
        caseId,
        caseNumber,
        filename: file.name,
        displayName: file.name,
        organ: metaData?.organ,
        specimenType: metaData?.specimenType,
        modelName: metaData?.modelName,
        imageLinks: [],
        size: file.size,
        status: "uploading",
        uploadProgress: 0,
        file // keep a reference to the `File` browser native object
      })
    );

    setCurrentFiles(files => [...files, ...addedFiles]);
    return uploadFiles({
      caseId,
      caseNumber,
      files: addedFiles,
      metaData,
      ...otherParams
    });
  };

  const uploadFiles = async ({
    caseId,
    caseNumber,
    files,
    metaData,
    onProgress,
    onStart,
    onEnd,
    onError
  }: CaseUploadParams) => {
    return await uploadFileGroup({
      caseId,
      files,
      metaData,
      api,
      onStart: (file: Medmain.UploadFile) => {
        debug("Start upload", file);
        notifyStartUpload(file);
        if (onStart) {
          onStart(file);
        }
      },
      onProgress: (file: Medmain.UploadFile, value: number) => {
        debug("Progress", file, value);
        updateByFilename(caseId, file.filename, { uploadProgress: value });
        if (onProgress) {
          onProgress(file, value);
        }
      },
      onEnd: file => {
        debug("Completed", file);
        updateByFilename(caseId, file.filename, { status: "uploaded" });
        incrementCompleteCount();
        notifyCompleteUpload(file);
        if (onEnd) {
          onEnd(file);
        }
      },
      onError: (file, error) => {
        notifyUploadError(file, error);
        if (error.message.includes("Storage space is not enough")) {
          removeByFilename(caseId, file.filename);
        } else {
          updateByFilename(caseId, file.filename, {
            status: "upload_failed"
          });
        }
        if (onError) {
          onError(file, error);
        }
      }
    });
  };

  const removeFile = (caseId: Medmain.Case["id"], filename: string) => {
    setCurrentFiles(files =>
      files.filter(file => !isMatchingFile(caseId, filename)(file))
    );
  };

  const copyFile = (
    srcCaseId: Medmain.Case["id"],
    dstCaseKey: Medmain.CaseKey,
    filename: string
  ) => {
    const target = currentFiles.find(file =>
      isMatchingFile(srcCaseId, filename)(file)
    );
    if (!target) return;
    setCurrentFiles(files => [
      ...files,
      {
        ...target,
        caseId: dstCaseKey.id,
        caseNumber: dstCaseKey.caseNumber
      }
    ]);
  };

  const moveFile = (
    srcCaseId: Medmain.Case["id"],
    dstCaseKey: Medmain.CaseKey,
    filename: string
  ) => {
    const target = currentFiles.find(file =>
      isMatchingFile(srcCaseId, filename)(file)
    );
    if (target) {
      setCurrentFiles(files => {
        const filtered = files.filter(
          file => !isMatchingFile(srcCaseId, filename)(file)
        );
        return [
          ...filtered,
          {
            ...target,
            caseId: dstCaseKey.id,
            caseNumber: dstCaseKey.caseNumber
          }
        ];
      });
    }
  };

  const retry = (caseId: Medmain.Case["id"], filename: string) => {
    const fileToUpload = currentFiles.find(isMatchingFile(caseId, filename));
    if (!fileToUpload) return;
    updateByFilename(caseId, filename, {
      status: "uploading",
      uploadProgress: 0
    });
    uploadFiles({
      caseId,
      caseNumber: fileToUpload.caseNumber,
      files: [fileToUpload]
    });
  };

  const updateDisplayName = (
    caseId: Medmain.Case["id"],
    filename: Medmain.Image["filename"],
    displayName: Medmain.Image["displayName"]
  ) => {
    updateByFilename(caseId, filename, { displayName });
  };

  const isMatchingFile = (caseId: Medmain.Case["id"], filename: string) => (
    file: Medmain.UploadFile
  ) => file.caseId === caseId && file.filename === filename;

  return {
    currentFiles,
    addFiles,
    retry,
    completeCount,
    removeFile,
    copyFile,
    moveFile,
    resetCompleteCount,
    updateDisplayName
  };
}

export const UploadCasesImagesContainer = createContainer(useUploadState);

export function useCaseUploadImages(pathologicCase: Medmain.Case) {
  const { id: caseId, caseNumber } = pathologicCase;
  const { addFiles, currentFiles } = UploadCasesImagesContainer.useContainer();

  const getFiles = () => {
    return currentFiles.filter(file => file.caseId === caseId);
  };

  return {
    addFiles: (files: File[], metaData: ImageEditableData) =>
      addFiles({ caseId, caseNumber, filesToUpload: files, metaData }),
    getFiles
  };
}

// Upload a group of files using `pMap` package to make a limited number of async requests in parallel
async function uploadFileGroup({
  caseId,
  files,
  metaData,
  api,
  onStart,
  onProgress,
  onEnd,
  onError
}: {
  caseId: Medmain.Case["id"];
  files: BulkUploadFile[];
  metaData?: ImageEditableData;
  api: ReturnType<typeof useAPI>;
  onStart: (file: Medmain.UploadFile) => void;
  onProgress: (file: Medmain.UploadFile, value: number) => void;
  onEnd: (file: Medmain.UploadFile) => void;
  onError: (file: Medmain.UploadFile, error: Error) => void;
}) {
  await pMap(files, uploadSingleFile, {
    concurrency: 2
  });
  debug(`All images uploaded, start polling for Image processor updates`);

  async function uploadSingleFile(file: Medmain.UploadFile) {
    debug("Start uploading the image", file, metaData);
    try {
      onStart(file);
      const uploadParams = {
        caseId,
        file: file.file,
        onProgress: uploadProgress => {
          onProgress(file, uploadProgress);
        }
      };
      if (isAttachments(file.filename)) {
        await api.cases.putAttachment(uploadParams);
      } else {
        await api.cases.images.upload({ ...uploadParams, metaData });
      }
      debug(`Image uploaded successfully, Image Processor should be running`);
      onEnd(file);
    } catch (error) {
      // TODO improve error handling when API response is standardized?
      if (error.message.includes("HTTP Status: 409")) {
        error.message = "Duplicate file"; // ...meanwhile we can mutate objects... oh really, call the police!
      }
      debug("Error while uploading", file, error);
      onError(file, error);
    }
  }
}
