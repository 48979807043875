import React from "react";
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";

import { useLocale } from "app/locale";
import { useTermsAgreement } from "app/terms";
import { Button, Checkbox, CheckIcon } from "components/core";
import {
  FetchTermsData,
  TermsContent
} from "components/terms/fetch-terms-data";
import { AgreementContainer } from "features/ai-legal-terms/agreement";
import { Field } from "utils/form";

export const Agreement = () => {
  const { agree, skip, shouldAskAgreement } = AgreementContainer.useContainer();
  const isOpen = shouldAskAgreement();

  const onSubmit = async () => {
    await agree();
  };

  return <AgreementModal isOpen={isOpen} onAgree={onSubmit} onCancel={skip} />;
};

export const AgreementModal = ({ isOpen, onAgree, onCancel }) => {
  const locale = useLocale();

  const { register, errors, handleSubmit, isSubmitting } = useTermsAgreement({
    type: "ai"
  });

  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => {}}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <FetchTermsData type="ai">
          {({ body, error, isPending }) => (
            <>
              <ModalHeader>
                <Center>{locale.terms.ai.heading}</Center>
              </ModalHeader>
              <ModalBody fontSize="16px">
                <TermsContent
                  body={body}
                  error={error}
                  isPending={isPending}
                  mb="15px"
                />
                <form onSubmit={handleSubmit(onAgree)} id="ai-agreement-modal">
                  <Field name="termsOfAI" label="" errors={errors}>
                    <Flex as="label" htmlFor="termsOfAILabel" cursor="pointer">
                      <Checkbox
                        name="termsOfAI"
                        id="termsOfAILabel"
                        ref={register}
                      />
                      <Box ml="10px">
                        {locale.terms.ai.agreementTermsOfAILabel}
                      </Box>
                    </Flex>
                  </Field>
                </form>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  {error ? (
                    <Button onClick={onCancel} size={"lg"}>
                      {locale.terms.ai.closeButton}
                    </Button>
                  ) : (
                    <>
                      <Button onClick={onCancel} size="lg">
                        {locale.terms.ai.cancelButton}
                      </Button>
                      <Button
                        form="ai-agreement-modal"
                        type="submit"
                        size="lg"
                        primary
                        leftIcon={<CheckIcon />}
                        disabled={isPending || isSubmitting}
                      >
                        {locale.terms.ai.agreementButton}
                      </Button>
                    </>
                  )}
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
        </FetchTermsData>
      </ModalContent>
    </Modal>
  );
};
