import { Switch as ChakraSwitch } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const Switch = styled(ChakraSwitch)`
  ${props => `
    .chakra-switch__track {
      background-color: ${props.theme.colors.gray["850"]};

      &[data-checked] {
        background-color: ${props.theme.colors.gray["850"]};
      }

      &[data-disabled] {
        opacity: 1;
      }
      
      &:hover:not(:disabled) {
        background-color: ${props.theme.colors.gray["650"]};
      }
      
      .chakra-switch__thumb {
        background-color: ${props.theme.colors.gray["250"]};
      }
    }
  `}
`;
