import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import groupBy from "lodash/groupBy";
import {
  Badge,
  Box,
  BoxProps,
  Flex,
  Grid,
  IconButton,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue
} from "@chakra-ui/react";
import { IoMdEye } from "react-icons/io";
import styled from "@emotion/styled";

import { useLocale } from "app/locale";
import { BulkUploadFile } from "app/upload-bulk-images-container";
import {
  Button,
  CheckIcon,
  DateTime,
  DeleteIcon,
  Progress,
  Table,
  WarningIcon
} from "components/core";
import { AssetIcon } from "components/cases/case-icons";

type Props = {
  images: BulkUploadFile[];
  onRemove?: (filename: string) => void;
};
export const ImageFileListByCase = ({ images, onRemove }: Props) => {
  const byCaseNumber = groupBy(images, "caseNumber");
  const caseNumbers = Object.keys(byCaseNumber);

  return (
    <Box>
      {caseNumbers.map(caseNumber => (
        <BulkUploadCase
          key={caseNumber}
          caseNumber={caseNumber}
          caseImages={byCaseNumber[caseNumber]}
          onRemove={onRemove}
        />
      ))}
    </Box>
  );
};

const BulkUploadCase = ({
  caseNumber,
  caseImages,
  onRemove
}: {
  caseNumber: Medmain.Case["caseNumber"];
  caseImages: BulkUploadFile[];
  onRemove: Props["onRemove"];
}) => {
  const history = useHistory();
  const isExistingCase = caseImages[0].caseExists;
  const caseId = caseImages[0].caseId;

  return (
    <Box borderBottomWidth="1px" _last={{ borderBottomWidth: 0 }}>
      <Flex
        key={caseNumber}
        bg="gray.50"
        py={2}
        px={4}
        h="50px"
        alignItems="center"
      >
        <Box>
          <Box display="inline" fontWeight="bold" ml={0}>
            {caseNumber}
          </Box>
          {isExistingCase ? (
            <Badge variant="outline" colorScheme="purple" ml={2}>
              Existing case
            </Badge>
          ) : (
            <NewCaseBadge isSuccessfullyCreated={!!caseId} ml={2} />
          )}
        </Box>
        <Box flexGrow={1} textAlign="right">
          {caseId && (
            <Button
              leftIcon={<IoMdEye />}
              onClick={() => history.push(`/cases/${caseId}`)}
              size="sm"
              bg="white"
            >
              View Case
            </Button>
          )}
        </Box>
      </Flex>
      <ImageFileList images={caseImages} onRemove={onRemove} px={4} />
    </Box>
  );
};

const NewCaseBadge = ({ isSuccessfullyCreated, ...props }) => {
  return isSuccessfullyCreated ? (
    <Badge variant="outline" colorScheme="green" {...props}>
      Case created
    </Badge>
  ) : (
    <Badge variant="outline" colorScheme="pink" {...props}>
      New case
    </Badge>
  );
};

type ImageFileListProps = {
  images: BulkUploadFile[];
  onRemove: Props["onRemove"];
} & BoxProps;
export const ImageFileList = ({
  images,
  onRemove,
  ...props
}: ImageFileListProps) => {
  const locale = useLocale();

  return (
    <Grid
      width="100%"
      templateColumns={`36px 1fr 100px${onRemove ? " 50px" : ""} 200px 60px`}
      alignItems="center"
      {...props}
    >
      {images.map(image => {
        const { uploadProgress } = image;
        const percent = uploadProgress
          ? (uploadProgress * 100).toFixed(1)
          : "--";
        return (
          <Fragment key={image.filename}>
            <Box>
              <AssetIcon
                filename={image.filename}
                boxSize="28px"
                display="inline"
                color={image.fileAlreadyExists ? "red.600" : "gray.500"}
                mr={1}
              />
            </Box>
            <Box
              py={2}
              flexGrow={1}
              color={image.fileAlreadyExists ? "red.600" : undefined}
            >
              {image.filename}
            </Box>
            <Box p={2} flexBasis="100px" textAlign="right">
              {locale.formatFileSize(image.file.size)}
            </Box>
            {onRemove && (
              <Box flexBasis="50px" p={2}>
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={event => {
                    onRemove!(image.filename);
                    event.stopPropagation();
                  }}
                  aria-label="Remove image"
                  fontSize="20px"
                />
              </Box>
            )}
            <Box pl={2}>
              {image.fileAlreadyExists ? (
                <Box color="red.600">
                  {locale.bulkUpload.duplicateFilenameError}
                </Box>
              ) : (
                <UploadStatus image={image} />
              )}
            </Box>
            <Box p={2} color="gray.500" textAlign="right">
              {image.status === "uploading" && `${percent}%`}
            </Box>
          </Fragment>
        );
      })}
    </Grid>
  );
};

const UploadStatus = ({ image }) => {
  const locale = useLocale();

  if (image.status === "uploaded") {
    return (
      <>
        <CheckIcon color="green.500" mr={1} />
        {locale.todo("Uploaded")}
      </>
    );
  }

  if (image.status === "upload_failed") {
    return (
      <Box color="red.700">
        <WarningIcon color="red.700" mr={1} />
        {locale.todo("Failed")}
      </Box>
    );
  }

  return <Progress value={image.uploadProgress * 100} w="100%" size="lg" />;
};

const Container = styled(Box)`
  tr.rejected {
    td,
    svg {
      background-color: ${props => props.theme.colors.red["50"]};
      color: ${props => props.theme.colors.red["700"]};
    }
  }
`;

export const AddedFileList = ({
  files,
  rejected,
  ...props
}: { files: File[]; rejected: File[] } & BoxProps) => {
  const locale = useLocale();
  const device = useBreakpointValue({
    base: "pc",
    sm: "sp",
    md: "tablet",
    pc: "pc"
  });

  const columns = [
    {
      title: "",
      dataIndex: "name",
      width: 44,
      render: filename => {
        return (
          <AssetIcon
            filename={filename}
            boxSize="28px"
            display="inline"
            color="gray.500"
          />
        );
      }
    },
    {
      title: locale.fileList.name,
      dataIndex: "name"
    },
    {
      title: locale.fileList.size,
      dataIndex: "size",
      width: 100,
      render: size => {
        return locale.formatFileSize(size);
      }
    },
    {
      title: locale.fileList.date,
      dataIndex: "lastModified",
      width: 150,
      render: lastModified => {
        return <DateTime date={lastModified} mask="yyyy-MM-dd HH:mm" />;
      }
    }
  ];

  const maxNumberOfRow = 5;
  const totalSupportedFiles = files.length;

  return (
    <>
      {device === "pc" ? (
        <Container>
          <Table
            rowKey="name"
            columns={columns}
            dataSource={files.concat(rejected)}
            rowClassName={(_, index) => {
              return index < totalSupportedFiles ? "" : "rejected";
            }}
            pagination={false}
            shouldShowHoverBackground={false}
            showHeader={true}
            bordered={false}
            size="small"
            scroll={files.length > maxNumberOfRow ? { y: 240 } : undefined}
          />
        </Container>
      ) : (
        <Box>
          {files.concat(rejected).map((file, i) => (
            <TableContainer
              key={i}
              mt={i !== 0 ? 6 : 0}
              whiteSpace="break-spaces"
            >
              <ChakraTable variant="unstyled">
                <Tbody>
                  {columns
                    .filter(column => column.title !== "")
                    .map((item, j) => (
                      <Tr key={j}>
                        <Th
                          width="40%"
                          backgroundColor="#fafafa"
                          textTransform="initial"
                          fontFamily="initial"
                          fontSize="inherit"
                          fontWeight={600}
                          lineHeight={1.5}
                          borderWidth={1}
                          borderColor="gray.200"
                        >
                          {item.title}
                        </Th>
                        <Td borderWidth={1} borderColor="gray.200">
                          {(item?.render &&
                            item.render(file[item.dataIndex])) ??
                            file[item.dataIndex]}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </ChakraTable>
            </TableContainer>
          ))}
        </Box>
      )}
    </>
  );
};
