import {
  Box,
  ButtonGroup,
  HStack,
  IconButton as ChakraIconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useState } from "react";
import { useLocale } from "app/locale";
import {
  AiOutlineDownIcon,
  AiOutlineUpIcon,
  Button,
  ModalCloseButton,
  Select
} from "components/core";
import { Field } from "utils/form";

const IconButton = styled(ChakraIconButton)`
  ${props =>
    props["data-selected"]
      ? `
      color: ${props.theme.colors.gray["900"]};
      background-color: ${props.theme.colors.primary500Alpha(0.2)};
      border-color: ${props.theme.colors.primary700Alpha(0.1)};
      &:focus {
        outline: none;
      }
      &:hover:not(:disabled) {
        background-color: ${props.theme.colors.primary500Alpha(0.2)};
        border-color: ${props.theme.colors.primary700Alpha(0.5)};
      }
      &:active:not(:disabled) {
        background-color: ${props.theme.colors.primary500Alpha(0.5)};
        border-color: ${props.theme.colors.primary700Alpha(0.7)};
      }
    `
      : `
      color: ${props.theme.colors.gray["900"]};
      border-color: ${props.theme.colors.gray["200"]};
      &:focus {
        outline: none;
      }
      &:hover:not(:disabled) {
        background-color: ${props.theme.colors.gray["400"]};
        border-color: ${props.theme.colors.gray["400"]};
      }
      &:active:not(:disabled) {
        background-color: ${props.theme.colors.gray["500"]};
        border-color: ${props.theme.colors.gray["400"]};
      }
    `}
`;

type Props = {
  caseSorts: Medmain.CaseSort[];
  setCaseSorts: (arr: Medmain.CaseSort[]) => void;
  close: () => void;
};

export const DialogSortCaseImages = ({
  caseSorts,
  setCaseSorts,
  close
}: Props) => {
  const locale = useLocale();

  const emptyOption = { value: "", label: "" };
  const priorityOptions: DefaultOptionType[] = [
    {
      value: "filename",
      label: locale.caseSort.options.filename
    },
    {
      value: "createdAt",
      label: locale.caseSort.options.createdAt
    }
  ];

  const [sortBy, setSortBy] = useState([
    { ...caseSorts[0] },
    {
      field: (caseSorts[1]?.field ?? "") as string,
      order: caseSorts[1]?.order ?? "ASC"
    }
  ]);

  const priorities = [
    {
      name: "priority1st",
      label: "Priority 1st",
      priority: sortBy[0].field,
      options: priorityOptions.filter(
        option => option.value !== sortBy[1]?.field
      ),
      changePriority: (value: string) =>
        setSortBy([
          {
            ...sortBy[0],
            field: value
          },
          sortBy[1]
        ]),
      order: sortBy[0].order,
      changeOrder: (value: string) =>
        setSortBy([
          {
            ...sortBy[0],
            order: value as Medmain.CaseSort["order"]
          },
          sortBy[1]
        ])
    },
    {
      name: "priority2nd",
      label: "Priority 2nd",
      priority: sortBy[1]?.field ?? "",
      options: [emptyOption, ...priorityOptions].filter(
        option => option.value !== sortBy[0].field
      ),
      changePriority: (value: string) =>
        setSortBy([
          sortBy[0],
          {
            ...sortBy[1],
            field: value
          }
        ]),
      order: sortBy[1]?.order ?? "ASC",
      changeOrder: (value: string) =>
        setSortBy([
          sortBy[0],
          {
            ...sortBy[1],
            order: value as Medmain.CaseSort["order"]
          }
        ])
    }
  ];

  return (
    <>
      <ModalHeader>
        {locale.todo("Sort")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={4}>
          {priorities.map((priority, index) => (
            <Field
              key={`priority${index + 1}`}
              name={priority.name}
              label={locale.todo(priority.label)}
              errors={[]}
            >
              <HStack>
                <Box flex={4}>
                  <Select
                    value={priority.priority}
                    options={priority.options}
                    onChange={priority.changePriority}
                    filterOption={(input, option) =>
                      option ? option.props.label.indexOf(input) >= 0 : false
                    }
                    showSearch
                  />
                </Box>
                <ButtonGroup size="md" isAttached variant="outline">
                  <IconButton
                    aria-label={`ASC${index + 1}`}
                    mr="-px"
                    icon={<AiOutlineUpIcon fontSize="xl" />}
                    onClick={() => priority.changeOrder("ASC")}
                    isDisabled={priority.priority.length === 0}
                    data-selected={priority.order === "ASC"}
                  />
                  <IconButton
                    aria-label={`DESC${index + 1}`}
                    icon={<AiOutlineDownIcon fontSize="xl" />}
                    onClick={() => priority.changeOrder("DESC")}
                    isDisabled={priority.priority.length === 0}
                    data-selected={priority.order === "DESC"}
                  />
                </ButtonGroup>
              </HStack>
            </Field>
          ))}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            primary
            onClick={() => {
              setCaseSorts(
                sortBy
                  .filter(item => !!item.field)
                  .map(item => item as Medmain.CaseSort)
              );
              close();
            }}
          >
            {locale.todo("Sort")}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};
