import React from "react";
import useSWR from "swr";

import { Select, SelectProps } from "components/core/select";
import { useAPI } from "api";

type Props = {
  type?: "case" | "image";
  tags: string[];
  onChange: (tags: string[]) => void;
} & SelectProps<string[]>;
export const TagPicker = ({
  type = "case",
  tags,
  onChange,
  ...props
}: Props) => {
  const { tags: allTags } = useFetchAvailableTags(type);

  const options = allTags
    ? allTags.map(tag => ({ value: tag, label: tag }))
    : [];

  return (
    <Select
      popupClassName="tags"
      mode="tags"
      value={tags}
      onChange={onChange}
      tokenSeparators={[","]}
      options={options}
      allowClear
      {...props}
    />
  );
};

function useFetchAvailableTags(type: "case" | "image") {
  const api = useAPI();
  const { data: tags, error } = useSWR(`api/${type}/tags`, () =>
    type === "image" ? api.images.listTags() : api.cases.listTags()
  );
  if (error) return { tags: [] }; // Let's be forgiving if an error happens while fetching tags
  return { tags };
}
