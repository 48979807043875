type CasePermissionType =
  | "case/update"
  | "case/delete"
  | "case/upload_image"
  | "case/download_image"
  | "case/share"
  | "case/create_comment"
  | "case/view_prediction"
  | "case/request_prediction"
  | "case/recover_prediction"
  | "case/view_labelled"
  | "case/mod_private_labelled"
  | "case/mod_public_labelled"
  | "case/create_drawing";

type CommentPermissionType = "comment/update" | "comment/delete";

export type ContractPermissionType =
  | "contract/delete_contract"
  | "contract/edit_contract"
  | "contract/invite_user"
  | "contract/remove_member"
  | "contract/see_contract"
  | "contract/see_other_used_file_size"
  | "contract/set_mfauthentication";

export type MenuListPermissionType =
  | "org/create_organization"
  | "contract/create_contract";

export type OrgPermissionType =
  | "org/create_case"
  | "org/create_comment"
  | "org/create_partner" // TODO: not used yet
  | "org/invite_user"
  | "org/read_member_list"
  | "org/read_partner_list"
  | "org/remove_member";

export type RoleType =
  | "role/nikon_customer_support"
  | "role/customer_support"
  | "role/member"
  | "role/administrator"
  | "role/proprietor"
  | "role/medmain_employee";

const permissionHandlers = {
  "case/update"(target: Medmain.Case): boolean {
    return target.userCapabilities.canEdit;
  },
  "case/delete"(target: Medmain.Case): boolean {
    return target.userCapabilities.canDelete;
  },
  "case/upload_image"(target: Medmain.Case): boolean {
    return target.userCapabilities.canUploadImage;
  },
  "case/download_image"(target: Medmain.Case): boolean {
    return target.userCapabilities.canDownloadImage;
  },
  "case/share"(target: Medmain.Case): boolean {
    return target.userCapabilities.canShare;
  },
  "case/create_comment"(target: Medmain.Case): boolean {
    return target.userCapabilities.canComment;
  },
  "case/view_prediction"(target: Medmain.Case): boolean {
    return target.userCapabilities.canViewPrediction;
  },
  "case/request_prediction"(target: Medmain.Case): boolean {
    return target.userCapabilities.canRequestPrediction;
  },
  "case/recover_prediction"(target: Medmain.Case): boolean {
    return target.userCapabilities.canRecoverPrediction;
  },
  "case/view_labelled"(target: Medmain.Case): boolean {
    return target.userCapabilities.canViewLabelled;
  },
  "case/mod_private_labelled"(target: Medmain.Case): boolean {
    return target.userCapabilities.canModPrivateLabelled;
  },
  "case/mod_public_labelled"(target: Medmain.Case): boolean {
    return target.userCapabilities.canModPublicLabelled;
  },
  "case/create_drawing"(target: Medmain.Case): boolean {
    // TODO: This feature has two hardcoded demo organizations in production
    const orgs = (process.env.REACT_APP_DISABLE_DRAWING_FOR_ORGS || "").split(
      ","
    );

    return !orgs.includes(target.organizationId);
  },
  "comment/update"(target: Medmain.Comment): boolean {
    return target.userCapabilities.canEdit;
  },
  "comment/delete"(target: Medmain.Comment): boolean {
    return target.userCapabilities.canEdit;
  },
  "org/create_case"(org: Medmain.Organization): boolean {
    return org.userCapabilities.canCreateCase;
  },
  "org/create_comment"(org: Medmain.Organization): boolean {
    // TODO this permission is not implemented yet in the API
    // so we assume the user can add comments on behalf of org the user belongs to, re-using an existing permission
    return org.userCapabilities.canCreateCase;
  },
  "org/invite_user"(org: Medmain.Organization): boolean {
    return org.userCapabilities.canInviteUser;
  },
  "org/read_member_list"(org: Medmain.Organization): boolean {
    return org.userCapabilities.canSeeListMember;
  },
  "org/read_partner_list"(org: Medmain.Organization): boolean {
    return org.userCapabilities.canSeeListPartner;
  },
  "org/remove_member"(org: Medmain.Organization): boolean {
    return org.userCapabilities.canRemoveMember;
  },
  "org/create_organization"(menuList: Medmain.MenuList): boolean {
    return menuList.userCapabilities.canCreateOrganization;
  },
  "contract/create_contract"(menuList: Medmain.MenuList): boolean {
    return menuList.userCapabilities.canCreateContract;
  },
  "contract/delete_contract"(contract: Medmain.Contract): boolean {
    return contract.userCapabilities.canDeleteContract;
  },
  "contract/edit_contract"(contract: Medmain.Contract): boolean {
    return contract.userCapabilities.canEditContract;
  },
  "contract/invite_user"(contract: Medmain.Contract): boolean {
    return contract.userCapabilities.canInviteUser;
  },
  "contract/remove_member"(contract: Medmain.Contract): boolean {
    return contract.userCapabilities.canRemoveMember;
  },
  "contract/see_contract"(contract: Medmain.Contract): boolean {
    return contract.userCapabilities.canSeeContract;
  },
  "contract/see_other_used_file_size"(contract: Medmain.Contract): boolean {
    return contract.userCapabilities.canSeeOtherUsedFileSize;
  },
  "contract/set_mfauthentication"(contract: Medmain.Contract): boolean {
    return contract.userCapabilities.canSetMFAuthentication;
  },
  "role/customer_support"(org: Medmain.Organization): boolean {
    return org.userCapabilities.isCustomerSupport;
  },
  "role/nikon_customer_support"(org: Medmain.Organization): boolean {
    return org.userCapabilities.isNikonCustomerSupport;
  },
  "role/member"(org: Medmain.Organization): boolean {
    return org.userCapabilities.canSeeOriginalUsedFileSize;
  },
  "role/administrator"(org: Medmain.Organization): boolean {
    return org.userCapabilities.canSeeListMember;
  },
  "role/proprietor"(menuList: Medmain.MenuList): boolean {
    return menuList.userCapabilities.canCreateOrganization;
  },
  "role/medmain_employee"(menuList: Medmain.MenuList): boolean {
    return menuList.userCapabilities.canCreateContract;
  }
};

// Using TS `Function Overloads` to ensure that we only pass valid combinations about Cases or Comments
export function hasPermission(
  permissionType: CasePermissionType,
  target: Medmain.Case
): boolean;
export function hasPermission(
  permissionType: CommentPermissionType,
  target: Medmain.Comment
): boolean;
export function hasPermission(
  permissionType: MenuListPermissionType,
  target: Medmain.MenuList
): boolean;
export function hasPermission(
  permissionType: OrgPermissionType,
  target: Medmain.Organization
): boolean;
export function hasPermission(
  permissionType: ContractPermissionType,
  target: Medmain.Contract
): boolean;
export function hasPermission(
  permissionType: RoleType,
  target: Medmain.Organization
): boolean;
export function hasPermission(
  permissionType: RoleType,
  target: Medmain.MenuList
): boolean;
export function hasPermission(
  permissionType: string,
  target:
    | Medmain.Case
    | Medmain.Comment
    | Medmain.MenuList
    | Medmain.Organization
    | Medmain.Contract
) {
  const handler = permissionHandlers[permissionType];
  if (!handler) {
    throw new Error(`Permission "${permissionType}" is not handled!`);
  }
  const isAllowed = handler(target);
  return isAllowed;
}
