import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  BoxProps
} from "@chakra-ui/react";

import { CaseDetailsReadonly } from "components/cases/case-details-readonly";
import { CaseAttachments } from "components/cases/case-attachments";
import { FetchCaseState } from "./case-item-fetch-data";
import { CaseEditDetails } from "components/cases/case-edit-details";
import { scrollToTop } from "components/layout";
import {
  LinkAttachment,
  findValidLinks
} from "components/cases/case-link-attachment";
export const PANEL_ANCHOR_ID = "info-panel";

type Props = Pick<FetchCaseState, "pathologicCase" | "reload"> & {
  isBeingEdited: boolean;
  setIsBeingEdited: (value: boolean) => void;
};

export const CaseItemInfoPanel = (props: Props) => {
  const { pathologicCase, reload } = props;
  const { attachments, caseLinks } = pathologicCase;
  const numberOfAttachments = attachments.length;

  const validLinks = findValidLinks(caseLinks);
  return (
    <Accordion allowToggle data-testid="info-panel" id={PANEL_ANCHOR_ID}>
      <AccordionItem>
        <PanelHeader>Details</PanelHeader>
        <AccordionPanel pb={4}>
          <CaseDetailsPanel {...props} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <PanelHeader>
          Attachments
          {numberOfAttachments > 0 && (
            <Badge ml={2}>{numberOfAttachments}</Badge>
          )}
        </PanelHeader>
        <AccordionPanel pb={4}>
          <CaseAttachments pathologicCase={pathologicCase} reload={reload} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <PanelHeader>
          Links
          {validLinks.length > 0 && <Badge ml={2}>{validLinks.length}</Badge>}
        </PanelHeader>
        <AccordionPanel pb={4}>
          <LinkAttachment
            links={caseLinks}
            canEdit={pathologicCase.userCapabilities.canEdit}
            id={pathologicCase.id}
            reload={reload}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const PanelHeader = (props: BoxProps) => {
  return (
    <AccordionButton>
      <Box flex="1" textAlign="left" fontSize="16px" {...props} />
      <AccordionIcon />
    </AccordionButton>
  );
};

const CaseDetailsPanel = ({
  pathologicCase,
  reload,
  isBeingEdited,
  setIsBeingEdited
}: Props) => {
  const goToReadOnlyMode = () => {
    setIsBeingEdited(false);
    setTimeout(() => {
      scrollToTop();
    });
  };

  return isBeingEdited ? (
    <CaseEditDetails
      pathologicCase={pathologicCase}
      reload={reload}
      onSaveSuccess={goToReadOnlyMode}
      onCancel={goToReadOnlyMode}
      layout="vertical"
    />
  ) : (
    <CaseDetailsReadonly
      pathologicCase={pathologicCase}
      setEditMode={setIsBeingEdited}
    />
  );
};
