import orderBy from "lodash/orderBy";

// Sort images taking into account numbers that may be included in the filename
export function sortCaseImages(
  pathologicCase: Medmain.Case,
  caseSorts?: Medmain.CaseSort[]
): Medmain.Image[] {
  const getFilename = (image: Medmain.Image) => {
    return normalizeFilename(image.filename);
  };

  if (caseSorts && caseSorts.length > 0) {
    const getCreatedAt = (image: Medmain.Image) => image.createdAt;
    const iteratees = caseSorts.map(cs =>
      cs.field === "filename" ? getFilename : getCreatedAt
    );
    const orders = caseSorts.map(cs => (cs.order === "ASC" ? "asc" : "desc"));
    return orderBy(pathologicCase.images, iteratees, orders);
  }

  return orderBy(pathologicCase.images, getFilename, "asc");
}

// Image filenames are supposed to be made of a case number and a slide number separated by `--`.
// E.g. `2021-533--2.svs`
// The thumbnail cards show the slide number as the title of the image, when available.
export function extractImageTitle(
  image: Medmain.Image,
  caseNumber: Medmain.Case["caseNumber"]
): string {
  const removeExtension = filename => {
    const index = filename.lastIndexOf(".");
    return index === -1 ? filename : filename.slice(0, index);
  };

  const removeCaseNumber = filename => {
    const separator = "--";
    const prefix = `${caseNumber}${separator}`;
    if (prefix === filename) {
      // Edge case: a separator is provided but nothing after!  `<case number>--`
      return caseNumber;
    }
    const index = filename.indexOf(prefix);
    return index === -1 ? filename : filename.slice(index + prefix.length);
  };

  return removeCaseNumber(removeExtension(image.filename));
}

// Process filenames, adding padding to numbers, to be able to perform a "alpha-numerical order"
function normalizeFilename(input: string): string {
  const numberOfDigits = 10;
  return input.toLowerCase().replace(/\d+/g, digits => {
    return digits.padStart(numberOfDigits, "0");
  });
}
