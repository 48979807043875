import React from "react";
import { Box, ButtonGroup, Flex, useBreakpoint } from "@chakra-ui/react";
import { Pagination } from "antd";
import styled from "@emotion/styled";

import { useLocale } from "app/locale";
import { parseInteger, usePagination } from "app/pagination";

type Props = Parameters<typeof usePagination>[0] & {
  children?: React.ReactNode;
};

export const PAGINATION_SIZES = ["10", "20", "50", "100"];
export const PAGINATION_MINIMUM_LIMIT = parseInteger(PAGINATION_SIZES[0], {
  defaultValue: 10,
  minValue: 1
});

const Container = styled(Box, {
  shouldForwardProp: prop => prop !== "screenSize"
})<{ screenSize: string }>`
  .anticon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ screenSize }) =>
    screenSize === "sm" &&
    `
      .ant-pagination-options {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: 10px
      }
    `}
`;

const Wrapper = styled.div`
  .ant-pagination {
    .ant-pagination-item-link {
      color: ${props => props.theme.colors.gray["900"]};
      border-color: ${props => props.theme.colors.gray["300"]};
      border-radius: 2px;
      &:disabled {
        background-color: ${props => props.theme.colors.gray["300"]};
        opacity: 0.4;
      }
      &:hover:not(:disabled) {
        background-color: ${props => props.theme.colors.gray["400"]};
        color: ${props => props.theme.colors.gray["900"]};
      }
      &:active:not(:disabled) {
        background-color: ${props => props.theme.colors.gray["500"]};
        color: ${props => props.theme.colors.gray["900"]};
      }
      &:focus {
        outline: none;
      }
    }
    .ant-pagination-item:not(.ant-pagination-item-active) {
      border-radius: 2px;
      &:focus {
        outline: none;
      }
      &:hover:not(:disabled) {
        border-color: ${props => props.theme.colors.gray["300"]};
      }
      a {
        color: ${props => props.theme.colors.gray["900"]};
        border-color: ${props => props.theme.colors.gray["300"]};
        border-radius: 2px;
        &:disabled {
          background-color: ${props => props.theme.colors.gray["300"]};
          opacity: 0.4;
        }
        &:hover:not(:disabled) {
          background-color: ${props => props.theme.colors.gray["400"]};
          color: ${props => props.theme.colors.gray["900"]};
        }
        &:active:not(:disabled) {
          background-color: ${props => props.theme.colors.gray["500"]};
          color: ${props => props.theme.colors.gray["900"]};
        }
      }
    }
    .ant-pagination-item.ant-pagination-item-active {
      border-color: ${props => props.theme.colors.primary500Alpha(0.2)};
      border-radius: 2px;
      &:focus {
        outline: none;
      }
      &:hover:not(:disabled) {
        border-color: ${props => props.theme.colors.primary["700"]};
      }
      &:active:not(:disabled) {
        border-color: ${props => props.theme.colors.primary["700"]};
      }
      a {
        color: ${props => props.theme.colors.gray["900"]};
        background-color: ${props => props.theme.colors.primary500Alpha(0.2)};
        border-color: ${props => props.theme.colors.primary500Alpha(0.2)};
        border-radius: 2px;
        &:hover:not(:disabled) {
          border-color: ${props => props.theme.colors.primary["700"]};
        }
        &:active:not(:disabled) {
          background-color: ${props => props.theme.colors.primary500Alpha(0.5)};
          border-color: ${props => props.theme.colors.primary700Alpha(0.5)};
        }
      }
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      :hover .ant-pagination-item-link-icon {
        color: ${props => props.theme.colors.gray["900"]};
      }
    }
  }
  .ant-select {
    display: block;
    font-size: 1rem;
    .anticon-close svg {
      display: block;
    }
    .ant-select-arrow {
      color: ${props => props.theme.colors.gray["900"]};
    }
    .ant-select-selector {
      border-color: ${props => props.theme.colors.gray["200"]};
      box-shadow: none;
      color: ${props => props.theme.colors.gray["900"]};
      border-radius: 2px;
      &:hover:not(:disabled) {
        background-color: ${props => props.theme.colors.gray["400"]};
        border: 0;
        box-shadow: none;
      }
      &:active:not(:disabled) {
        background-color: ${props => props.theme.colors.gray["400"]};
        box-shadow: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
`;

export const PaginatedListActionBar = ({
  children,
  searchOptions,
  total
}: Props) => {
  const { updateSearchParams } = usePagination({
    searchOptions,
    total
  });
  const screenSize = useBreakpoint() ?? "";

  function onShowSizeChange(current, pageSize) {
    updateSearchParams({
      page: current,
      limit: pageSize
    });
  }

  return (
    <Flex mt="5" justifyContent={{ base: "center", md: "initial" }}>
      {children}

      <Box flex="1" d={{ base: "none", md: "block" }} />

      {total > PAGINATION_MINIMUM_LIMIT && (
        <Container screenSize={screenSize}>
          <ButtonGroup>
            <Wrapper>
              <Pagination
                showSizeChanger
                onChange={(page, pageSize) => {
                  if (searchOptions.limit === pageSize) {
                    updateSearchParams({ page });
                  }
                }}
                current={searchOptions.page}
                pageSize={searchOptions.limit ?? PAGINATION_MINIMUM_LIMIT}
                onShowSizeChange={onShowSizeChange}
                total={total}
                pageSizeOptions={PAGINATION_SIZES}
              />
            </Wrapper>
          </ButtonGroup>
        </Container>
      )}
    </Flex>
  );
};

export const PaginationNumbers = ({ total, offset, limit }) => {
  const locale = useLocale();
  return (
    <>
      {locale.formatNumber(offset + 1)}-
      {locale.formatNumber(Math.min(offset + limit, total))} of{" "}
      {locale.formatNumber(total)}
    </>
  );
};
