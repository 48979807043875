/**
 * The IIIF tile source loader is ported from OpenSeaDragon
 *
 * Note: for simplicity, the compatibility of versions other than v2 have been removed.
 *
 * TODO
 * - bring back v3 support if in need
 */

/**
 * OpenSeadragon - IIIFTileSource
 *
 * Copyright (C) 2009 CodePlex Foundation
 * Copyright (C) 2010-2013 OpenSeadragon contributors
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are permitted provided that the following conditions are
 * met:
 *
 * - Redistributions of source code must retain the above copyright notice,
 *   this list of conditions and the following disclaimer.
 *
 * - Redistributions in binary form must reproduce the above copyright
 *   notice, this list of conditions and the following disclaimer in the
 *   documentation and/or other materials provided with the distribution.
 *
 * - Neither the name of CodePlex Foundation nor the names of its
 *   contributors may be used to endorse or promote products derived from
 *   this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
 * "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
 * LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
 * A PARTICULAR PURPOSE ARE DISCLAIMED.  IN NO EVENT SHALL THE COPYRIGHT
 * OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
 * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED
 * TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR
 * PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF
 * LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
 * NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS
 * SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 */

import * as z from "zod";
import { ViewState } from "components/viewer/types";

export type Point = {
  x: number;
  y: number;
};

// https://iiif.io/api/image/2.1/#technical-properties
export const IIIFTileSourceSchema = z
  .object({
    "@context": z.literal("http://iiif.io/api/image/2/context.json"),
    "@id": z.string(),
    "@type": z.literal("iiif:Image").optional(),
    protocol: z.literal("http://iiif.io/api/image"),
    width: z.number(),
    height: z.number(),
    profile: z.tuple([
      z.string(),
      z
        .object({
          // Omit other unused fields
          supports: z.array(z.string()).optional()
        })
        .nonstrict()
    ]),
    sizes: z
      .array(
        z.object({
          "@type": z.literal("iiif:Size").optional(),
          width: z.number(),
          height: z.number()
        })
      )
      .optional(),
    tiles: z
      .array(
        z.object({
          "@type": z.literal("iiif:Tile").optional(),
          scaleFactors: z.array(z.number()),
          width: z.number(),
          height: z.number().optional()
        })
      )
      .optional()
  })
  .nonstrict();

export type IIIFTileSourceSchema = z.infer<typeof IIIFTileSourceSchema>;

type Options = {
  maxLevel?: number;
  minLevel?: number;
  tileOverlap?: number;
};

export class IIIFTileSource {
  // Fix to IIIF v2
  version = 2;
  // Fix to jpg
  // TODO: should we accept other situations?
  tileFormat = "jpg";

  id: string;
  width: number;
  height: number;
  tileWidth: number = 0;
  tileHeight: number = 0;
  scaleFactors: number[] = [];
  tileSizePerScaleFactor: {
    [key: number]: { width: number; height: number };
  } = {};
  minLevel: number;
  maxLevel: number;
  aspectRatio: number;
  dimensions: Point;
  tileOverlap: number;
  levelScaleCache: number[];

  constructor(info: IIIFTileSourceSchema, options: Options = {}) {
    this.id = info["@id"];
    this.width = info.width;
    this.height = info.height;

    // FIXME: For certain scaleFactors, width and height are different, this will fail TileLayer which assumes same width and height.
    //  Need to make sure the square one is used, both at frontend side and backend side.
    //  For example https://develop--pidport.netlify.app/cases/cka7bkltm00000150px1i2yfc/viewer/cka7bqrfp00010150bpxbb7j2
    if (info.tiles?.length) {
      // if (info.tiles.length === 1) {
      this.tileWidth = info.tiles[0].width;
      this.tileHeight = info.tiles[0].height || info.tiles[0].width;
      this.scaleFactors = [...info.tiles[0].scaleFactors];
      // When different tileWidth and tileHeight occur, it means that tile boundary or abnormal non-square tile config is met.
      // In this case, try to use the smaller one of square bounging box of the entire image or 512x512
      if (this.tileWidth !== this.tileHeight) {
        this.tileWidth = this.tileHeight = Math.min(
          512,
          Math.max(this.tileWidth, this.tileHeight)
        );
      }
      // } else {
      // FIXME: The original logic allows different tileSize for different scaleFactors, this could fail TileLayer which assumes same width and height.
      // info.tiles.forEach(tile => {
      //   tile.scaleFactors.forEach(sf => {
      //     this.scaleFactors.push(sf);
      //     this.tileSizePerScaleFactor[sf] = {
      //       width: tile.width,
      //       height: tile.height || tile.width
      //     };
      //   });
      // });
      // }
    } else if (this.canBeTiled(info)) {
      const shortDim = Math.min(info.height, info.width);
      const tileOptions = [256, 512, 1024];
      const smallerTiles = tileOptions.filter(opt => opt <= shortDim);
      if (smallerTiles.length > 0) {
        this.tileWidth = this.tileHeight = Math.max(...smallerTiles);
      } else {
        this.tileWidth = this.tileHeight = shortDim;
      }
    } else {
      // Skip the legacy pyramid
      throw new Error(
        "Nothing in the info.json to construct image pyramids from"
      );
    }

    this.minLevel = options.minLevel ?? 0;
    this.maxLevel =
      options.maxLevel ||
      (this.scaleFactors.length > 0
        ? Math.round(Math.log(Math.max(...this.scaleFactors)) * Math.LOG2E)
        : Math.ceil(Math.log(Math.max(this.width, this.height)) * Math.LOG2E));
    this.aspectRatio = info.width / info.height;
    this.dimensions = { x: info.width, y: info.height };
    this.tileOverlap = options.tileOverlap ?? 0;
    this.levelScaleCache = [...Array(this.maxLevel + 1)].map(
      (_, i) => 1 / Math.pow(2, this.maxLevel - i)
    );
  }

  canBeTiled(info: IIIFTileSourceSchema) {
    const level0Profiles = [
      "http://library.stanford.edu/iiif/image-api/compliance.html#level0",
      "http://library.stanford.edu/iiif/image-api/1.1/compliance.html#level0",
      "http://iiif.io/api/image/2/level0.json",
      "level0",
      "https://iiif.io/api/image/3/level0.json"
    ];
    const isLevel0 = level0Profiles.indexOf(info.profile[0]) !== -1;
    const hasCanoncicalSizeFeature =
      info.profile[1].supports?.indexOf("sizeByW") !== -1;
    return !isLevel0 || hasCanoncicalSizeFeature;
  }

  /**
   * Return the tileWidth for the given level.
   * @function
   * @param level
   */
  getTileWidth(level: number) {
    const scaleFactor = Math.pow(2, this.maxLevel - level);
    if (this.tileSizePerScaleFactor[scaleFactor]) {
      return this.tileSizePerScaleFactor[scaleFactor].width;
    }
    return this.tileWidth;
  }

  /**
   * Return the tileHeight for the given level.
   * @function
   * @param level
   */
  getTileHeight(level: number) {
    const scaleFactor = Math.pow(2, this.maxLevel - level);

    if (this.tileSizePerScaleFactor[scaleFactor]) {
      return this.tileSizePerScaleFactor[scaleFactor].height;
    }
    return this.tileHeight;
  }

  /**
   * @function
   * @param {Number} level
   */
  getLevelScale(level: number) {
    return this.levelScaleCache[level];
  }

  /**
   * @function
   * @param {Number} level
   */
  getNumTiles(level: number) {
    const scale = this.getLevelScale(level);
    const x = Math.ceil((scale * this.dimensions.x) / this.getTileWidth(level));
    const y = Math.ceil(
      (scale * this.dimensions.y) / this.getTileHeight(level)
    );
    return { x, y };
  }

  /**
   * @function
   * @returns The highest level in this tile source that can be contained in a single tile.
   */
  getClosestLevel() {
    let i: number;
    for (i = this.minLevel + 1; i <= this.maxLevel; i++) {
      const tiles = this.getNumTiles(i);
      if (tiles.x > 1 || tiles.y > 1) {
        break;
      }
    }
    return i - 1;
  }

  /**
   * @function
   * @param level
   * @param x
   * @param y
   * @returns Either where this tile fits (in normalized coordinates) or the
   * portion of the tile to use as the source of the drawing operation (in pixels), depending on
   * the isSource parameter.
   */
  getTileBounds(level: number, x: number, y: number) {
    const dx = this.dimensions.x * this.getLevelScale(level);
    const dy = this.dimensions.y * this.getLevelScale(level);
    const tileWidth = this.getTileWidth(level);
    const tileHeight = this.getTileHeight(level);
    const px = x === 0 ? 0 : tileWidth * x - this.tileOverlap;
    const py = y === 0 ? 0 : tileHeight * y - this.tileOverlap;
    const sx = Math.min(
      tileWidth + (x === 0 ? 1 : 2) * this.tileOverlap,
      dx - px
    );
    const sy = Math.min(
      tileHeight + (y === 0 ? 1 : 2) * this.tileOverlap,
      dy - py
    );
    return [sx, sy];
  }

  /**
   * Responsible for retrieving the url which will return an image for the
   * region specified by the given x, y, and level components.
   * @function
   * @param {Number} level - z index
   * @param {Number} x
   * @param {Number} y
   * @throws {Error}
   */
  getTileUrl(level: number, x: number, y: number) {
    //## get the scale (level as a decimal)
    const scale = Math.pow(0.5, this.maxLevel - level);
    //# image dimensions at this level
    const levelWidth = Math.ceil(this.width * scale);
    const levelHeight = Math.ceil(this.height * scale);
    const tileWidth = this.getTileWidth(level);
    const tileHeight = this.getTileHeight(level);
    const iiifTileSizeWidth = Math.ceil(tileWidth / scale);
    const iiifTileSizeHeight = Math.ceil(tileHeight / scale);
    const iiifQuality = "default." + this.tileFormat;
    //## iiif region
    let iiifRegion: string;
    let iiifTileX: number;
    let iiifTileY: number;
    let iiifTileW: number;
    let iiifTileH: number;
    let iiifSize: string;
    let iiifSizeW: number;

    if (levelWidth < tileWidth && levelHeight < tileHeight) {
      if (this.version === 2 && levelWidth === this.width) {
        iiifSize = "max";
      } else {
        iiifSize = levelWidth + ",";
      }
      iiifRegion = "full";
    } else {
      iiifTileX = x * iiifTileSizeWidth;
      iiifTileY = y * iiifTileSizeHeight;
      iiifTileW = Math.min(iiifTileSizeWidth, this.width - iiifTileX);
      iiifTileH = Math.min(iiifTileSizeHeight, this.height - iiifTileY);
      if (
        x === 0 &&
        y === 0 &&
        iiifTileW === this.width &&
        iiifTileH === this.height
      ) {
        iiifRegion = "full";
      } else {
        iiifRegion = [iiifTileX, iiifTileY, iiifTileW, iiifTileH].join(",");
      }
      iiifSizeW = Math.ceil(iiifTileW * scale);
      if (this.version === 2 && iiifSizeW === this.width) {
        iiifSize = "max";
      } else {
        iiifSize = iiifSizeW + ",";
      }
    }
    // TODO Should we encode this.id?
    const uri = [this.id, iiifRegion, iiifSize, "0", iiifQuality].join("/");
    return uri;
  }

  tileExists(level: number, x: number, y: number) {
    const numTiles = this.getNumTiles(level);
    return (
      level >= this.minLevel &&
      level <= this.maxLevel &&
      x >= 0 &&
      y >= 0 &&
      x < numTiles.x &&
      y < numTiles.y
    );
  }

  /**
   * Following methods are for TileLayer usage
   * Some parameters are:
   * @param x tile index x, shared by both TileLayer and IIIF worlds
   * @param y tile index y, shared by both TileLayer and IIIF worlds
   * @param z tile index z, the adjusted zoom level by -this.maxLevel, in the TileLayer world
   * @param level zoom level used here, in iiif world
   */

  /**
   * Get level from z
   * @param z tile index z
   */
  getLevel(z: number) {
    return z + this.maxLevel;
  }

  /**
   * Get the tile index z to fix in the bounding box specified by width and height
   * @param width the width of the bounding box, window e.g., to be fix in
   * @param height the height of the bounding box, window e.g., to be fix in
   */
  getFitZ(width: number, height: number) {
    return (
      Math.log(Math.min(width / this.width, height / this.height)) * Math.LOG2E
    );
  }

  /**
   * Get the calculated bounding box, with [level, x, y]
   * @param level zoom level
   * @param x tile index x
   * @param y tile index y
   */
  getTileBoundingBox(
    level: number,
    x: number,
    y: number
  ): [number, number, number, number] {
    const dx = x * this.getTileWidth(level);
    const dy = y * this.getTileHeight(level);
    const [w, h] = this.getTileBounds(level, x, y);
    const scale = this.getLevelScale(level);
    const left = dx / scale;
    const top = dy / scale;
    const right = (dx + w) / scale;
    const bottom = (dy + h) / scale;
    return [left, bottom, right, top];
  }

  // Return named version of getTileBoundingBox
  getTileNamedBoundingBox(level: number, x: number, y: number) {
    const [left, bottom, right, top] = this.getTileBoundingBox(level, x, y);
    return { left, bottom, right, top };
  }

  /**
   * Get the viewstate that fits the image inside width x height dimensions, for DeckGL use
   * @param width the viewport width
   * @param height the viewport height
   */
  getFitViewState(width: number, height: number): Partial<ViewState> {
    // Use original size as max zoomed level, thus offset zoom range by -iiif.maxLevel
    // The offset needs to be applied to iiif as well, to get correct result
    const zoom = this.getFitZ(width, height);
    // Choose the max value between floored fit or minimum iiif level, as the min zoom level
    const minZoom = Math.max(Math.floor(zoom), this.minLevel - this.maxLevel);
    // Digital zoom® : allow 2x over-zooming, i.e. which increase 20x to 40x with 20x-40x as zoomed result of 20x
    const maxZoom = 1;
    return {
      minZoom,
      maxZoom,
      zoom: Math.min(Math.max(zoom, minZoom), maxZoom),
      target: [this.width / 2, this.height / 2, 0],
      rotationOrbit: 0
    };
  }
}
