import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import debugModule from "debug";
import { createContainer } from "unstated-next";
import { useToast } from "@chakra-ui/react";

import { useAPI } from "api";
import { useAccount } from "app/auth-container";
import { readEnvVariable } from "api/config";

const debug = debugModule("medmain:legal");

type Status = "agreed" | "skipped" | "request";

// For orgs with AI prediction enabled, we have to show a required agreement before the user can proceed
export function useAgreement() {
  const { getCurrentOrg } = useAccount();
  const api = useAPI();
  const toast = useToast();
  const [initChecked, setInitChecked] = useState(false);
  const [status, setStatus] = useState<Status | null>(null);
  const agreeCallbackRef = useRef<Function | null>(null);

  const currentOrg = useMemo(() => getCurrentOrg(), [getCurrentOrg]);

  const checkAIAgreement = useCallback(
    async <T extends Function>(callback?: T) => {
      setInitChecked(true);
      if (!currentOrg) return; // no legal stuff when no org is selected (only available for super users)
      if (isMedmainOrg(currentOrg.id)) return;
      if (!currentOrg?.predictionEnabled) return;

      try {
        const { agreed } = await api.terms.checkAIAgreement();
        const status = agreed ? "agreed" : "request";
        setStatus(status);
        if (callback) {
          switch (status) {
            case "agreed":
              callback();
              break;
            case "request":
              agreeCallbackRef.current = callback;
              break;
          }
        }
      } catch (error) {
        debug(error);
        toast({
          title:
            "Failed to confirm that you agreed to the terms of Image Analysis AI",
          description: error.message || "Unexpected error",
          status: "error"
        });
      }
    },
    [currentOrg, setStatus, api, toast, setInitChecked]
  );

  function shouldAskAgreement() {
    if (!currentOrg) return false; // no legal stuff when no org is selected (only available for super users)
    if (isMedmainOrg(currentOrg.id)) return false;
    if (!currentOrg?.predictionEnabled) return false;

    return status === "request";
  }

  async function agree() {
    try {
      await api.terms.agree({ type: "ai" });
      setStatus("agreed");
      agreeCallbackRef.current?.();
      agreeCallbackRef.current = null;
    } catch (error) {
      debug(error);
      toast({
        title: "Failed to agree on the terms of Image Analysis AI",
        description: error.message || "Unexpected error",
        status: "error"
      });
    }
  }

  function skip() {
    setStatus("skipped");
    agreeCallbackRef.current = null;
  }

  const canUsePredictionFeatures = status === "agreed";

  // Heat-map and threshold features are now allowed for all users
  // TODO rename this accordingly?
  const canUseMedmainAIFeatures = true;

  useEffect(() => {
    // After initial display, do not display on any screen other than the case-related screens.
    if (initChecked && window.location.pathname.indexOf("/cases") < 0) return;
    checkAIAgreement();
  }, [checkAIAgreement, initChecked]);

  return {
    agree,
    canUseMedmainAIFeatures,
    canUsePredictionFeatures,
    checkAIAgreement,
    shouldAskAgreement,
    skip,
    status
  };
}

export const AgreementContainer = createContainer(useAgreement);

// For orgs with AI prediction enabled, we have to show warnings on top of the cases pages (list and details)
export function useCaseLegalWarning() {
  const { status } = AgreementContainer.useContainer();
  const { getCurrentOrg } = useAccount();

  function shouldShowWarning() {
    const currentOrg = getCurrentOrg();

    if (!currentOrg) return false; // no legal stuff when no org is selected (only available for super users)
    if (isMedmainOrg(currentOrg.id)) return false;
    if (!currentOrg?.predictionEnabled) return false;

    return status === "agreed";
  }

  return { shouldShowWarning };
}

export function usePredictionWarning() {
  const { getCurrentOrg } = useAccount();

  function shouldShowWarning() {
    const currentOrg = getCurrentOrg();

    if (!currentOrg) return false; // no legal stuff when no org is selected (only available for super users)
    if (isMedmainOrg(currentOrg.id)) return false;

    return true;
  }
  return { shouldShowWarning };
}

// ...but we skip the legal stuff for special Medmain orgs that are hard-coded for now
export function isMedmainOrg(organizationId: Medmain.Organization["id"]) {
  const orgIdsAsString = readEnvVariable("SKIP_AI_AGREEMENT_ORGS");
  const orgs = orgIdsAsString.split(",");
  const isSpecialOrg = orgs.includes(organizationId);
  if (isSpecialOrg) {
    debug("Skip AI legal agreement and warning for Medmain AI orgs");
  }
  return isSpecialOrg;
}
