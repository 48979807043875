import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  IconProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Stack,
  StyleProps,
  Text,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  AiOutlineQuestionCircle,
  AiOutlineStar,
  AiTwotoneStar
} from "react-icons/ai";
import { FiPlusSquare } from "react-icons/fi";
import { HiOutlineFilter } from "react-icons/hi";

import { useLocale } from "app/locale";

const AlertIcon = (props: IconProps) => (
  <Icon viewBox="0 0 19.308 17.013" width="19.308" height="17.013" {...props}>
    <g transform="translate(-1.935 -3.844)" fill="currentColor">
      <path
        d="M20.114,20.856H3.065a1.136,1.136,0,0,1-.976-.562,1.119,1.119,0,0,1,0-1.128L10.615,4.407a1.126,1.126,0,0,1,1.95,0L21.071,19.13a1.128,1.128,0,0,1-.957,1.726ZM11.56,4.948h-.009L3.023,19.705l.042.07H20.113a.05.05,0,0,0,.047-.046c0-.006-.023-.052-.026-.058L11.628,4.948Z"
        transform="translate(0)"
      />
      <path
        d="M17.126,13.585V18.29a.614.614,0,0,0,1.228,0V13.585a.614.614,0,1,0-1.228,0Zm0,0"
        transform="translate(-6.063 -3.642)"
      />
      <path
        d="M17.74,24.209a.614.614,0,0,0-.614.612v.412a.614.614,0,0,0,1.228,0v-.412A.614.614,0,0,0,17.74,24.209Zm0,0"
        transform="translate(-6.063 -8.128)"
      />
    </g>
  </Icon>
);

const CheckCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 18 18" width="18" height="18" {...props}>
    <path
      d="M8.9,16.681A7.785,7.785,0,1,0,1.112,8.9,7.785,7.785,0,0,0,8.9,16.681Zm0,1.112A8.9,8.9,0,1,0,0,8.9,8.9,8.9,0,0,0,8.9,17.794Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M16.844,10.926a.834.834,0,1,1,1.191,1.168L13.6,17.643a.834.834,0,0,1-1.2.022L9.453,14.723a.834.834,0,1,1,1.179-1.179l2.329,2.327,3.862-4.921a.263.263,0,0,1,.022-.024Z"
      transform="translate(-4.644 -5.399)"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Icon>
);

const CheckDottedCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 18 18" width="18" height="18" {...props}>
    <g transform="translate(-0.534 0.216)" fill="currentColor">
      <path
        d="M16.844,10.926a.834.834,0,1,1,1.191,1.168L13.6,17.643a.834.834,0,0,1-1.2.022L9.453,14.723a.834.834,0,1,1,1.179-1.179l2.329,2.327,3.862-4.921a.263.263,0,0,1,.022-.024Z"
        transform="translate(-3.648 -5.399)"
        fillRule="evenodd"
      />
      <g
        transform="translate(0.534 -0.216)"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeDasharray="2 2"
      >
        <circle cx="9" cy="9" r="9" stroke="none" />
        <circle cx="9" cy="9" r="8.5" fill="none" />
      </g>
    </g>
  </Icon>
);

const PUBLIC_LABELLED_ICONS = [
  {
    name: "AlertIcon",
    Icon: AlertIcon,
    color: "#ac1111"
  },
  {
    name: "CheckCircleIcon",
    Icon: CheckCircleIcon,
    color: "#3c7a04"
  },
  {
    name: "CheckDottedCircleIcon",
    Icon: CheckDottedCircleIcon,
    color: "#000000"
  }
];

export const TwotoneStarIcon = styled(AiTwotoneStar)`
  path:nth-of-type(1) {
    color: #ffd500;
  }
  path:nth-of-type(2) {
    color: #d1a000;
  }
`;

export const ToggledPrivateLabelledIcon = ({
  privateLabelled,
  size,
  isDisabled,
  ...props
}: {
  privateLabelled: boolean;
  size: string | number;
  isDisabled: boolean;
} & IconProps) => {
  const theme = useTheme();
  return (
    <IconButton
      aria-label="toggle-private-label"
      variant="unstyled"
      _focus={{ outline: "none" }}
      isDisabled={isDisabled}
      _disabled={{ border: 0 }}
      size={size.toString()}
      icon={
        <Icon
          as={privateLabelled ? TwotoneStarIcon : AiOutlineStar}
          color={privateLabelled ? "#edcb0e" : theme.colors.gray["250"]}
          w={size}
          h={size}
          {...props}
        />
      }
    />
  );
};

type Status<T> = ({ name, color }: { name: string; color: string }) => T;
type Props = {
  onClick: Status<void>;
  isActive: Status<boolean>;
  isDisabled?: boolean;
  isFilter?: boolean;
};
const AddStatusPopover = ({
  onClick,
  isActive,
  isDisabled,
  isFilter
}: Props) => {
  const locale = useLocale();
  const theme = useTheme();
  return (
    <Popover arrowShadowColor="#707070">
      <PopoverTrigger>
        <Center>
          <IconButton
            variant="unstyled"
            size="18px"
            height="18px"
            icon={
              isFilter ? (
                <HiOutlineFilter size={18} />
              ) : (
                <FiPlusSquare size={18} />
              )
            }
            aria-label="add-public-labelled"
            _focus={{ border: "none" }}
          />
        </Center>
      </PopoverTrigger>
      <PopoverContent
        width="auto"
        borderColor="#707070"
        _focus={{ borderColor: "#707070" }}
      >
        <PopoverArrow />
        <PopoverBody p={11} pb={0}>
          <Flex flexDirection="row">
            {PUBLIC_LABELLED_ICONS.map((Element, j) => (
              <IconButton
                key={j}
                onClick={() =>
                  onClick({ name: Element.name, color: Element.color })
                }
                icon={<Element.Icon key={j} color={Element.color} />}
                aria-label="public-labelled"
                variant="ghost"
                bg={
                  isActive({ name: Element.name, color: Element.color })
                    ? theme.colors.gray["100"]
                    : "transparent"
                }
                isDisabled={isDisabled}
                _disabled={{ border: 0 }}
                _hover={{ bg: theme.colors.gray["100"] }}
                _focus={{ boxShadow: "none" }}
              />
            ))}
          </Flex>
        </PopoverBody>
        <PopoverFooter border={0} pt={0}>
          <Center>
            <Stack isInline>
              <Icon as={AiOutlineQuestionCircle} alignSelf="center" />
              <Text align="center">
                {locale.todo("Press selected to deselect")}
              </Text>
            </Stack>
          </Center>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

const StyledWrap = styled(Wrap)`
  color: ${props => props.theme.colors.gray["900"]};
  background-color: ${props => props.theme.colors.gray["200"]};
  &:disabled {
    border-color: ${props => props.theme.colors.gray["300"]};
    opacity: 0.4;
  }
  &:hover:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["400"]};
    border-color: ${props => props.theme.colors.gray["300"]};
  }
  &:active:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["500"]};
    border-color: ${props => props.theme.colors.gray["300"]};
  }
`;

export const StatusIcons = ({
  publicLabelleds,
  onClick,
  isActive,
  isDisabled,
  isFilter,
  canAddIcon,
  iconSize = "20px",
  ...props
}: {
  publicLabelleds?: (
    | Medmain.Labelled
    | { caseId?: Medmain.Case["id"]; name: string; color: string }
  )[];
  canAddIcon: boolean;
  iconSize?: React.ComponentProps<typeof Icon>["boxSize"];
} & Props &
  StyleProps) => {
  return !canAddIcon && (!publicLabelleds || publicLabelleds.length === 0) ? (
    <></>
  ) : (
    <StyledWrap
      justify="center"
      p={2}
      h="35px"
      minW="35px"
      {...props}
      rounded={2}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="2px"
      direction="row"
    >
      {publicLabelleds
        ?.filter(
          l =>
            !!PUBLIC_LABELLED_ICONS.find(Element =>
              "labelName" in l
                ? Element.name === l.labelName.split("-")[0]
                : Element.name === l.name
            )
        )
        .map((checkedIcon, index) => (
          <WrapItem key={index}>
            <Icon
              as={
                PUBLIC_LABELLED_ICONS.find(Element =>
                  "labelName" in checkedIcon
                    ? Element.name === checkedIcon.labelName.split("-")[0]
                    : Element.name === checkedIcon.name
                )?.Icon
              }
              color={
                "labelName" in checkedIcon
                  ? checkedIcon.labelName.split("-")[1]
                  : checkedIcon.color
              }
              boxSize={iconSize}
            />
          </WrapItem>
        ))}
      {canAddIcon && (
        <WrapItem>
          <AddStatusPopover
            onClick={onClick}
            isActive={isActive}
            isDisabled={isDisabled}
            isFilter={isFilter}
          />
        </WrapItem>
      )}
    </StyledWrap>
  );
};

export const TableStatusIcons = (
  props: React.ComponentProps<typeof StatusIcons>
) => {
  const {
    publicLabelleds,
    onClick,
    isActive,
    isDisabled,
    isFilter,
    canAddIcon,
    ...styleProps
  } = props;

  return (
    <Flex {...styleProps} align="center" justify="left">
      {canAddIcon && (
        <Box>
          <AddStatusPopover
            onClick={onClick}
            isActive={isActive}
            isDisabled={isDisabled}
            isFilter={isFilter}
          />
        </Box>
      )}
      <Wrap ml={3} w="80px">
        {publicLabelleds
          ?.filter(l =>
            PUBLIC_LABELLED_ICONS.find(Element =>
              "labelName" in l
                ? Element.name === l.labelName.split("-")[0]
                : Element.name === l.name
            )
          )
          .map((checkedIcon, index) => (
            <WrapItem key={index}>
              <Icon
                as={
                  PUBLIC_LABELLED_ICONS.find(Element =>
                    "labelName" in checkedIcon
                      ? Element.name === checkedIcon.labelName.split("-")[0]
                      : Element.name === checkedIcon.name
                  )?.Icon
                }
                color={
                  "labelName" in checkedIcon
                    ? checkedIcon.labelName.split("-")[1]
                    : checkedIcon.color
                }
                width="20px"
                height="20px"
              />
            </WrapItem>
          ))}
      </Wrap>
    </Flex>
  );
};
