import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { useAccount } from "app/auth-container";
import { CaseListPage } from "pages/cases/case-list-page";
import { ScrollableContainer } from "components/layout/container";

const CaseCreatePage = lazy(() => import("./case-create-page"));
const CaseItemRoot = lazy(() => import("./case-item/case-item-root"));

export const CasesRoot = () => {
  const { currentOrgId, isMemberRoleOfOrg, isSuper } = useAccount();
  return (
    <Switch>
      <Route path="/cases" exact>
        <CaseListPage />
      </Route>
      {(isSuper() || isMemberRoleOfOrg(currentOrgId)) && (
        <Route path="/cases/new">
          <ScrollableContainer>
            <CaseCreatePage />
          </ScrollableContainer>
        </Route>
      )}
      <Route path="/cases/:caseId">
        <CaseItemRoot />
      </Route>
    </Switch>
  );
};
