import {
  CloseButton as ChakraCloseButton,
  CloseButtonProps,
  DrawerCloseButton as ChakraDrawerCloseButton,
  ModalCloseButton as ChakraModalCloseButton
} from "@chakra-ui/react";
import styled from "@emotion/styled";

export const CloseButton = ({ ...props }: CloseButtonProps) => {
  return <ChakraCloseButton {...props} borderRadius="2px" />;
};

const StyledDrawerCloseButton = styled(ChakraDrawerCloseButton)`
  ${props => `
    &:active:not(:disabled) {
      background-color: ${props => props.theme.colors.gray["500"]};
      box-shadow: 0 0 0 2px ${props.theme.colors.gray["300"]};
    }
  `}
`;

export const DrawerCloseButton = ({ ...props }: CloseButtonProps) => {
  return <StyledDrawerCloseButton {...props} borderRadius="2px" />;
};

export const ModalCloseButton = ({ ...props }: CloseButtonProps) => {
  return <ChakraModalCloseButton {...props} borderRadius="2px" />;
};
