import { Box, Flex, Divider, DividerProps } from "@chakra-ui/react";
import { NikonLogo } from "./nikon-logo";
import { ExternalLink } from "./sidebar";

export const Footer = () => {
  const privacyNoticeUrl = process.env.REACT_APP_PRIVACY_POLICY_PDF_URL;
  const serviceTermsPdfUrl = process.env.REACT_APP_SERVICE_TERMS_PDF_URL;
  const cookieNoticePdfUrl = process.env.REACT_APP_COOKIE_NOTICE_PDF_URL;

  return (
    <Box
      display={{ base: "block", lg: "flex" }}
      pt="4px"
      pb="16px"
      pr="12px"
      fontSize="14px"
      float="right"
      color="#000"
    >
      <Flex margin="auto 0">
        <ExternalLink
          href={privacyNoticeUrl}
          _hover={{ textDecoration: "underline", color: "#000" }}
          p="0 auto"
        >
          Privacy Policy
        </ExternalLink>
        <FooterDivider />
        <ExternalLink
          href={cookieNoticePdfUrl}
          _hover={{ textDecoration: "underline", color: "#000" }}
          p="0 auto"
        >
          Cookie Notice
        </ExternalLink>
        <FooterDivider />
        <ExternalLink
          href={serviceTermsPdfUrl}
          _hover={{ textDecoration: "underline", color: "#000" }}
        >
          Terms of Service
        </ExternalLink>
      </Flex>

      <Flex float={{ base: "right", lg: "none" }}>
        <Box margin="auto 0" pr="12px" ml="20px">
          © 2024 Nikon Corporation
        </Box>
        <Box py="12px" ml="4px">
          <NikonLogo width="25px" height="25px" />
        </Box>
      </Flex>
    </Box>
  );
};

const FooterDivider = ({ ...props }: DividerProps) => {
  return (
    <Divider
      borderColor="black"
      orientation="vertical"
      height="10px"
      m="auto 12px"
      {...props}
    />
  );
};
