import { Link as ReactRouterLink, LinkProps } from "react-router-dom";
import { BoxProps, Link } from "@chakra-ui/react";
import styled from "@emotion/styled";

const StyledLink = styled(Link)`
  ${props => `
    :hover {
      background-color: ${props.theme.colors.gray[400]};
      border-color: transparent;
      color: ${props.theme.colors.gray[900]};
    }
    :active {
      background-color: ${props.theme.colors.gray[500]};
      border-color: transparent;
      color: ${props.theme.colors.gray[900]};
    }
    :focus {
      box-shadow: none;
    }
  `}
`;

export const TagLink = (props: BoxProps & LinkProps) => (
  <StyledLink
    as={ReactRouterLink}
    px={2}
    whiteSpace="nowrap"
    textOverflow="ellipsis"
    overflow="hidden"
    borderRadius="4px"
    borderWidth="1px"
    borderColor="gray.500"
    wordBreak="break-all"
    maxWidth="100%"
    width="fit-content"
    fontSize="13px"
    transition="color 0.15s ease-out"
    _hover={{
      borderColor: "primary.500",
      textDecoration: "none"
    }}
    {...props}
  />
);
