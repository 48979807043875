import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  Box,
  ButtonGroup,
  Code,
  Flex,
  Link,
  ListItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
  UnorderedList
} from "@chakra-ui/react";
import { MdCloudUpload } from "react-icons/md";
import { useAsync } from "react-async";

import { useLocale } from "app/locale";
import { useAPI } from "api";
import { useOrgLookup } from "app/auth-container";
import {
  ImageEditableData,
  UploadCasesImagesContainer
} from "app/upload-cases-images-container";
import {
  Button,
  HelpIcon,
  ModalCloseButton,
  Spinner,
  useModal
} from "components/core";
import { AddedFileList } from "components/images/image-file-list";
import { ImageDataForm, SupportedImageFileTypes } from "./dialog-add-images";
import { CaseNumberPicker, CaseOption } from "./case-number-picker";
import { CaseFormData } from "./case-form";

export function useAddImagesToAnyCase() {
  const modal = useModal();
  const history = useHistory();
  const api = useAPI();
  const { addFiles: uploadImages } = UploadCasesImagesContainer.useContainer();

  const addFiles = async (
    files: File[],
    rejectedFiles: File[],
    orgId: Medmain.Organization["id"]
  ) => {
    if (files.length === 0) {
      modal.alert(
        <>
          <Box mb={4}>The selected files are not supported.</Box>
          <UnorderedList mb={4} ml={6}>
            {rejectedFiles.map(file => (
              <ListItem key={file.name}>{file.name}</ListItem>
            ))}
          </UnorderedList>
          <SupportedImageFileTypes />
        </>,
        {
          title: "Unsupported Files",
          modalProps: {
            size: "xl"
          }
        }
      );
      return;
    }

    const data = await modal.dialog({
      render: close => (
        <DialogParseFilename
          close={close}
          files={files}
          rejectedFiles={rejectedFiles}
          orgId={orgId}
        />
      ),
      modalProps: { size: "3xl" }
    });
    if (!data) return;
    const { caseData, imageData } = data as DialogReturnedData;

    const caseId = await getCaseId(caseData, orgId);
    uploadImages({
      caseId,
      caseNumber: caseData.caseNumber,
      filesToUpload: files,
      metaData: imageData
    });
    history.push(`/cases/${caseId}/images`); // TODO handle query string
  };

  const getCaseId = async (caseData: CaseOption, orgId) => {
    const { caseNumber } = caseData;
    if (!caseData.isNewCase) {
      return caseData.id;
    }
    const { id } = await api.cases.create({
      caseNumber,
      ownerOrganizationId: orgId,
      diagnosis: "",
      comments: "",
      grossFindings: "",
      microscopicFindings: "",
      intendedOwnerOrganizationId: orgId,
      tags: []
    } as CaseFormData);
    return id;
  };

  return { addFiles };
}

type DialogReturnedData = {
  caseData: CaseOption;
  imageData: ImageEditableData;
};

type DialogProps = {
  close: (data?: DialogReturnedData) => void;
  files: File[];
  rejectedFiles: File[];
  orgId: string;
};

const DialogParseFilename = (props: DialogProps) => {
  const api = useAPI();
  const { files, orgId } = props;
  const { data, error, isPending } = useAsync(api.batchImport.parseFiles, {
    organizationId: orgId,
    filenames: [files[0].name]
  });

  if (isPending) {
    return (
      <DialogContainer>
        <Box py={24}>
          <Spinner />
        </Box>
      </DialogContainer>
    );
  }

  if (error) {
    return (
      <DialogContainer>
        <Alert status="error" mb={8}>
          Error while parsing the file: {files[0].name}
        </Alert>
      </DialogContainer>
    );
  }
  const {
    caseId: existingCaseId,
    caseNumber,
    exists,
    fileAlreadyExists
  } = data[0];

  const defaultCaseData: CaseOption = {
    id: existingCaseId,
    caseNumber,
    isNewCase: !exists,
    images: fileAlreadyExists ? [files[0].name] : []
  };

  return (
    <DialogAddImagesToAnyCase defaultCaseData={defaultCaseData} {...props} />
  );
};

const DialogContainer = ({ children }: { children: React.ReactNode }) => {
  const locale = useLocale();
  return (
    <>
      <ModalHeader>
        {locale.caseUpload.modalHeading}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </>
  );
};

type DialogUploadProps = DialogProps & {
  defaultCaseData: CaseOption;
};

const DialogAddImagesToAnyCase = ({
  close,
  files,
  rejectedFiles,
  orgId,
  defaultCaseData
}: DialogUploadProps) => {
  const locale = useLocale();
  const [imageData, setImageData] = useState<ImageEditableData>({});

  const [caseData, setCaseData] = useState<CaseOption>(defaultCaseData);
  const fileAlreadyExists = caseData.images?.includes(files[0].name) || false;
  const { getOrgById } = useOrgLookup();
  const org = getOrgById(orgId);

  const onSubmit = event => {
    event.preventDefault();
    close({ caseData, imageData });
  };

  return (
    <DialogContainer>
      <form onSubmit={onSubmit}>
        <ModalBody p={0}>
          <Box mb={4} borderWidth="1px" p={4} borderRadius="4px">
            <Flex>
              <Box color="gray.500" fontWeight="bold" mb={2}>
                {locale.caseFields.caseNumber}
              </Box>
              <Tooltip
                label={`Edit Case Number to pick an existing case or create a new one`}
                aria-label="Case Number"
                placement="right"
              >
                <Box as="span">
                  <HelpIcon ml={2} color="gray.500" />
                </Box>
              </Tooltip>
            </Flex>
            <Box>
              <CaseNumberPicker
                defaultCaseData={caseData}
                orgId={orgId}
                onChange={data => {
                  setCaseData(data);
                }}
              />
              <Info
                caseData={caseData}
                fileAlreadyExists={fileAlreadyExists}
                close={close}
                mt={4}
              />
            </Box>
          </Box>
          <Box p={4} borderWidth="1px" borderRadius="4px">
            <AddedFileList files={files} rejected={rejectedFiles} />
          </Box>
          <ImageDataForm
            values={imageData}
            onChange={(key, value) =>
              setImageData(values => ({ ...values, [key]: value }))
            }
            canCreatePrediction={!!org?.predictionEnabled}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button onClick={() => close()}>{locale.cancelButtonLabel}</Button>
            <Button
              primary
              type="submit"
              isDisabled={!caseData.caseNumber || fileAlreadyExists}
            >
              {locale.caseUpload.uploadButtonLabel(files.length)}
              <Box
                as={MdCloudUpload}
                size="24px"
                color="white"
                display="inline"
                ml={2}
              />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </DialogContainer>
  );
};

const Info = ({
  caseData,
  fileAlreadyExists,
  close,
  ...props
}: {
  caseData: CaseOption;
  fileAlreadyExists: boolean;
  close: DialogUploadProps["close"];
} & AlertProps) => {
  const { id, caseNumber, isNewCase } = caseData;

  if (fileAlreadyExists) {
    return (
      <Alert status="error" fontSize="1rem" {...props}>
        <AlertIcon />
        <AlertDescription>
          The image already exists in the case{" "}
          <Code bg="transparent">{caseNumber}</Code>.
          <br />
          Please enter an other case number.
        </AlertDescription>
      </Alert>
    );
  }
  if (isNewCase) {
    return (
      <Alert status="info" fontSize="1rem" {...props}>
        <AlertIcon />
        <AlertDescription>A new case will be created.</AlertDescription>
      </Alert>
    );
  }
  return (
    <Alert status="info" fontSize="1rem" {...props}>
      <AlertIcon />
      <AlertDescription>
        The image will be added to an existing case:{" "}
        <Link
          as={RouterLink}
          to={`/cases/${id}`}
          fontWeight="bold"
          textDecor="underline"
          onClick={() => close(undefined)}
        >
          {caseNumber}
        </Link>
      </AlertDescription>
    </Alert>
  );
};
