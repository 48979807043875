import { yupResolver } from "@hookform/resolvers";
import { useAsync } from "react-async";
import { useForm } from "react-hook-form";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Text,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  useToast
} from "@chakra-ui/react";
import {
  AddIcon,
  Button,
  DeleteIcon,
  IconButton,
  ImageViewerIconButton,
  Input,
  useModal
} from "components/core";
import { ExternalLink } from "components/layout/sidebar";
import * as yup from "yup";
import { format, isAfter, isBefore, parseISO } from "date-fns";
import { AiOutlineLink } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { useAPI } from "api";
import { useLocale } from "app/locale";
import { Field, useYupSetLocale } from "utils/form";

export const LinkAttachment = ({
  links,
  id,
  canEdit,
  isViewer = false,
  reload
}: {
  links: Medmain.CaseLink[] | undefined;
  id: Medmain.Case["id"];
  canEdit: boolean;
  isViewer?: boolean;
  reload: () => void;
}) => {
  const api = useAPI();
  const toast = useToast();
  const modal = useModal();
  const locale = useLocale();

  const LinkHandleIconButton = isViewer ? ImageViewerIconButton : IconButton;

  const showHandleLinkDialog = async (
    linkObj: Pick<
      Medmain.CaseLink,
      "label" | "url" | "expiredAt" | "id"
    > | null,
    operation: "create" | "update"
  ) => {
    await modal.dialog({
      render: close => (
        <UrlLinkSettingForm
          close={close}
          attachedId={id} // caseId Or imageId
          linkObj={linkObj}
          operation={operation}
          isViewer={isViewer}
        />
      ),
      modalProps: {
        size: "3xl",
        isCentered: true
      }
    });

    reload();
  };

  const handleDeleteLink = async (urlLinkId: string) => {
    const isDeleted = await modal.confirm(
      locale.todo("Are you sure you want to delete the link?"),
      { title: "Delete link" }
    );
    if (!isDeleted) return;
    try {
      await api.links.delete(urlLinkId);
      toast({
        description: "The link has been deleted",
        isClosable: true
      });
    } catch (err) {
      toast({
        title: "Failed to delete link.",
        description: "Please try again later.",
        status: "error",
        isClosable: true
      });
    } finally {
      reload();
    }
  };

  const validLinks = findValidLinks(links);
  const expiredLinks = findExpiredLinks(links);

  return (
    <>
      <Box>
        <Text pb={2} borderStyle="dashed" fontWeight={500}>
          {locale.link.theSetLink}
        </Text>
        {validLinks &&
          validLinks.map(linkItem => (
            <Flex justifyContent="space-between" mb={1}>
              {linkItem.isOwner || linkItem.isAdministrator ? (
                <ExternalLink
                  href={addUrlProtocol(linkItem.url)}
                  _hover={{ textDecoration: "underline", color: "gray.800" }}
                  title={linkItem.label || linkItem.url}
                >
                  <HStack>
                    <AiOutlineLink />
                    <Text maxW="200px" noOfLines={1}>
                      {linkItem.label || linkItem.url}
                    </Text>
                  </HStack>
                </ExternalLink>
              ) : (
                <HStack
                  color="gray.500"
                  _hover={{ color: "gray.500", cursor: "not-allowed" }}
                >
                  <AiOutlineLink />
                  <Text maxW="200px" noOfLines={1}>
                    {linkItem.label || linkItem.url}
                  </Text>
                </HStack>
              )}
              <HStack>
                <LinkHandleIconButton
                  icon={<MdEdit fontSize="15px" />}
                  aria-label="edit"
                  isDisabled={!linkItem.isOwner && !linkItem.isAdministrator}
                  onClick={() => showHandleLinkDialog(linkItem, "update")}
                />
                <LinkHandleIconButton
                  icon={<DeleteIcon fontSize="15px" />}
                  aria-label="delete"
                  isDisabled={!linkItem.isOwner && !linkItem.isAdministrator}
                  onClick={() => handleDeleteLink(linkItem.id)}
                />
              </HStack>
            </Flex>
          ))}
        {validLinks && validLinks.length !== 3 && (
          <Flex justifyContent="space-between">
            <HStack color="gray.400">
              <AiOutlineLink />
              <Text>{locale.link.noLinkSetting}</Text>
            </HStack>
            <LinkHandleIconButton
              icon={<AddIcon fontSize="11px" />}
              aria-label="add"
              isDisabled={!canEdit}
              onClick={() => showHandleLinkDialog(null, "create")}
            />
          </Flex>
        )}
      </Box>
      {canEdit && expiredLinks && expiredLinks.length !== 0 && (
        <>
          <Divider py={2} />
          <Text py={2} borderStyle="dashed" fontWeight={500}>
            {locale.link.expiredLink}
          </Text>
          {expiredLinks.map(expiredLink => (
            <ExpiredLinkItem
              expiredLink={expiredLink}
              reload={reload}
              isViewer={isViewer}
            />
          ))}
        </>
      )}
    </>
  );
};

const UrlLinkSettingForm = ({
  close,
  attachedId,
  linkObj,
  operation,
  isViewer = false
}: {
  close: (values: FormData) => void;
  attachedId: string;
  linkObj: Pick<Medmain.CaseLink, "label" | "url" | "expiredAt" | "id"> | null;
  operation: "create" | "update";
  isViewer?: boolean;
}) => {
  // TODO Put this to root level?
  useYupSetLocale();

  const api = useAPI();
  const toast = useToast();
  const locale = useLocale();

  const validationSchema = yup.object().shape({
    label: yup.string().max(1024),
    url: yup
      .string()
      .required()
      .trim()
      .max(1024),
    expiredAt: yup
      .string()
      .nullable()
      .test(
        "is-valid-date",
        "Expired date must be later than current date",
        function(value) {
          if (!value) return true;

          const now = new Date();
          const inputValue = new Date(value);

          return inputValue > now;
        }
      )
  });

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(validationSchema)
  });

  const { run: editLinkAttach } = useAsync({
    deferFn: async ([values]) => {
      try {
        if (operation === "create") {
          await api.links.create({
            linkType: isViewer ? "image" : "case",
            id: attachedId,
            url: values.url,
            label: values.label,
            expiredAt: values.expiredAt
              ? format(new Date(values.expiredAt), "yyyy-MM-dd HH:mm:ss")
              : undefined
          });
        } else if (operation === "update") {
          await api.links.update({
            urlLinkId: linkObj?.id,
            url: values.url,
            label: values.label,
            expiredAt: values.expiredAt
              ? format(new Date(values.expiredAt), "yyyy-MM-dd HH:mm:ss")
              : undefined
          });
        }
        toast({
          description: "Link added",
          status: "success",
          isClosable: true
        });
      } catch (err) {
        toast({
          title: "Failed",
          description: "Please try again later.",
          status: "error",
          isClosable: true
        });
      } finally {
        close(values);
      }
    }
  });

  return (
    <>
      <ModalHeader>
        {locale.todo("Link Setting")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody mb={2}>
        <form id="linkEdit" onSubmit={handleSubmit(editLinkAttach)}>
          <Stack spacing={4}>
            <Field name="label" label={locale.link.linkName} errors={errors}>
              <Input ref={register} defaultValue={linkObj?.label} />
            </Field>
            <Field name="url" label={locale.link.linkURL} errors={errors}>
              <Input ref={register} defaultValue={linkObj?.url} />
            </Field>
            <Field
              name="expiredAt"
              label={locale.link.deadline}
              errors={errors}
            >
              <Input
                ref={register}
                type="datetime-local"
                width="-moz-fit-content"
                defaultValue={
                  linkObj?.expiredAt
                    ? format(new Date(linkObj.expiredAt), "yyyy-MM-dd HH:mm:ss")
                    : undefined
                }
                min={new Date().toISOString().substring(0, 16)}
              />
            </Field>
          </Stack>
        </form>
      </ModalBody>
      <ModalFooter justifyContent="center">
        <Button type="submit" primary form="linkEdit">
          {locale.saveButtonLabel}
        </Button>
      </ModalFooter>
    </>
  );
};

export const ExpiredLinkItem = ({
  isViewer,
  expiredLink,
  setExpiredLinks,
  reload
}: {
  isViewer?: boolean;
  expiredLink: Medmain.CaseLink;
  setExpiredLinks?: React.Dispatch<React.SetStateAction<Medmain.CaseLink[]>>;
  reload?: ((values?: any) => Promise<any>) | (() => void);
}) => {
  const api = useAPI();
  const toast = useToast();

  const LinkHandleIconButton = isViewer ? ImageViewerIconButton : IconButton;

  const { run: deleteExpiredLink, isPending: isDeletePending } = useAsync({
    deferFn: async () => {
      try {
        await api.links.delete(expiredLink.id);

        if (setExpiredLinks) {
          setExpiredLinks(links => {
            if (!links) return [];
            return links.filter(link => link.id !== expiredLink.id);
          });
        }
        toast({
          description: "The link has been deleted",
          isClosable: true
        });
      } catch (err) {
        toast({
          title: "Failed to delete link.",
          description: "Please try again later.",
          status: "error",
          isClosable: true
        });
      } finally {
        if (reload) reload();
      }
    }
  });

  return (
    <Flex mb={1}>
      <HStack
        flex="1"
        color="gray.400"
        onClick={e => {
          e.preventDefault();
        }}
        _hover={{ cursor: "default" }}
      >
        <AiOutlineLink />
        <Text maxW="200px" noOfLines={1}>
          {expiredLink.label || expiredLink.url}
        </Text>
      </HStack>
      <LinkHandleIconButton
        icon={<DeleteIcon fontSize="15px" />}
        aria-label="delete"
        size="xs"
        isDisabled={!expiredLink.isOwner && !expiredLink.isAdministrator}
        isLoading={isDeletePending}
        onClick={deleteExpiredLink}
      />
    </Flex>
  );
};

export const addUrlProtocol = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return `//${url}`;
  }
  return url;
};

export const findValidLinks = (links: Medmain.CaseLink[] | undefined) => {
  if (!links) {
    return [];
  }
  return links.filter(link => {
    if (link.expiredAt) {
      return isAfter(parseISO(link.expiredAt), new Date());
    } else {
      return true;
    }
  });
};

export const findExpiredLinks = (links: Medmain.CaseLink[] | undefined) => {
  if (!links) {
    return [];
  }
  return links.filter(link => {
    if (link.expiredAt) {
      return isBefore(parseISO(link.expiredAt), new Date());
    } else {
      return false;
    }
  });
};
