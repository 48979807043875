/**
 * There are Medmain.Image and Image class, to make things even worse here is another one.
 * The reasons not updating the existing one:
 * - Medmain.Image has extra fields and status for uploading, and misses some fields.
 * - Image class has a different signature from the following model class.
 * - Several mixed usages which makes the one-step change hard to be made.
 *
 * TODO:
 * - The target is to achieve the merge progressively.
 * - For uploading, the class better to be extended or wrapped to use client-only status
 * - Image class should be replaced.
 */

/* eslint-disable  @typescript-eslint/no-redeclare */
import GL from "@luma.gl/constants";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import mapKeys from "lodash/mapKeys";
import pick from "lodash/pick";
import round from "lodash/round";
import uniq from "lodash/uniq";
import * as z from "zod";
import {
  ColorAdjustment,
  OverlayGetter,
  ArrayType
} from "components/viewer/types";
import {
  aiLabelsMap,
  getWithUnit,
  normalizeScaleSize,
  notEmpty,
  roundSignificand
} from "components/viewer/utils";

// Here try to rewire DTO in schema and then auto-inferred static type
// Also, extra methods are included in the model class below, instead of here.
export const PredictionSchema = z
  .object({
    id: z.string(),
    // TODO: Remove optional() and nullable() when having more strict API definition
    modelName: z
      .string()
      .optional()
      .nullable(),
    createdAt: z
      .string()
      .optional()
      .nullable(),
    updatedAt: z
      .string()
      .optional()
      .nullable(),
    verifiedAt: z
      .string()
      .optional()
      .nullable(),
    status: z.enum(["waiting", "running", "completed", "failed"]),
    verificationStatus: z
      .string()
      .optional()
      .nullable(),
    result: z
      .object({
        cellSize: z.number(),
        version: z.number(),
        numberOfConsideredCells: z.number(),
        labels: z.record(z.string()),
        cells: z
          .record(z.record(z.number()))
          .optional()
          .nullable(),
        threshold: z.number().optional()
      })
      .nonstrict()
      .optional()
      .nullable()
  })
  .nonstrict();

export type PredictionSchema = z.infer<typeof PredictionSchema>;

/**
 * The schema strictly (almost) represents the DTO from/to server
 */
export const ImageMetaSchema = z
  .object({
    id: z.string(),
    imageLayers: z.array(
      z.object({
        id: z.string(),
        imageId: z.string(),
        layerNumber: z.number(),
        pyramidalSize: z.number(),
        metadata: z
          .object({
            the_z: z
              .number()
              .optional()
              .nullable(),
            position_z: z
              .number()
              .optional()
              .nullable(),
            position_z_unit: z
              .string()
              .optional()
              .nullable()
          })
          .nonstrict(),
        createdAt: z.string(),
        updatedAt: z.string()
      })
    ),
    uploadAccountId: z.string(),
    caseId: z.string(),
    filename: z.string(),
    status: z.enum([
      "reserved",
      "uploaded",
      "processing",
      "available",
      "process_failed",
      "deleting"
    ]),
    imageLinks: z.array(
      z.object({
        id: z.string(),
        linkType: z.enum(["case", "image"]),
        accountId: z.string(),
        caseId: z.string().nullable(),
        imageId: z.string().nullable(),
        label: z.string(),
        url: z.string(),
        expiredAt: z.string().nullable(),
        isAdministrator: z.boolean(),
        isOwner: z.boolean(),
        updatedAt: z
          .string()
          .optional()
          .nullable(),
        createdAt: z
          .string()
          .optional()
          .nullable()
      })
    ),
    size: z.number(),
    format: z.string(),
    staining: z.string().nullable(),
    scannedIn: z.string().nullable(),
    scannedAt: z.string().nullable(),
    dimensions: z.object({ width: z.number(), height: z.number() }).nullable(),
    resolution: z
      .object({
        x: z.number().nullable(),
        y: z.number().nullable(),
        unit: z.enum(["dpi", "dpc"]).nullable()
      })
      .nullable(),
    // TODO: zod has not Date literal
    createdAt: z.string(),
    predictions: z
      .array(PredictionSchema)
      .optional()
      .nullable(),
    prediction: PredictionSchema.optional().nullable(),
    magnification: z.number().nullable(),
    organ: z.string().nullable(),
    specimenType: z.string().nullable()
  })
  .nonstrict();

export type ImageMetaSchema = z.infer<typeof ImageMetaSchema>;

export class ImageMeta {
  constructor(readonly data: ImageMetaSchema) {}

  get id() {
    return this.data.id;
  }

  get layers() {
    return this.data.imageLayers || [];
  }

  getLayer(idx: number) {
    return this.layers[idx];
  }

  get firstLayerId() {
    return this.layers[0]?.id ?? "";
  }

  getInitialLayerIndex() {
    return Math.max(
      0,
      this.layers.findIndex(layer => layer.metadata.position_z === 0)
    );
  }

  get width() {
    return this.data.dimensions?.width || 0;
  }

  get height() {
    return this.data.dimensions?.height || 0;
  }

  get createdAt() {
    return new Date(this.data.createdAt);
  }

  /**
   * Get resolution dpc (dot per cm)
   *
   * TODO:
   * - ppm value will be extracted in future
   * - better way than null value?
   */
  get dpc() {
    if (!this.data.resolution) return null;
    const { unit } = this.data.resolution;
    let { x } = this.data.resolution;
    if (!x || !unit) return null;
    // The resolution is incorrect sometimes. Here, as heuristic rule of magnification below, makes a simple guess about whether dpc is valid.
    // The 22620 is taken from image.js getResolutionDPI()
    const validateDpc = (v: number) => (v === 10 ? 22620 : v > 5000 ? v : null);
    switch (unit.toLowerCase()) {
      case "dpi":
        return validateDpc(x / 2.54);
      case "dpc":
        return validateDpc(x);
      default:
        return null;
    }
  }

  get magnification() {
    const { magnification } = this.data;
    if (magnification && magnification > 0) return magnification;
    // NOTE: Taken from magnification-container.ts as heuristic rule for large image
    if (this.width > 5000 || this.height > 5000) return 20;
    return null;
  }

  getScalebarSizeAndText(minSize: number, ratio: number) {
    if (!this.dpc) return null;
    const value = normalizeScaleSize(this.dpc, minSize / ratio);
    const factor = roundSignificand(((value / this.dpc) * minSize) / ratio, 3);
    const size = value * minSize;
    const valueWithUnit = getWithUnit(factor);
    return {
      size,
      text: valueWithUnit
    };
  }

  getMagnificationLevelOrZoomRatio(zoom: number) {
    const level = Math.pow(2, zoom) * (this.magnification || 100);
    return `${round(level, level > 10 ? 0 : 1)}${
      this.magnification ? "×" : "%"
    }`;
  }
}

type GetOverlay = {
  isHeatmapMode: boolean;
  isHighlightMode: boolean;
  threshold: number;
  showGradientEdge: boolean;
  labels: string[];
  imageWidth: number;
  imageHeight: number;
};

export type GetCells = { labels: string[]; threshold: number };

type GetGridData = GetCells & { width: number; height: number };

type Cell = ArrayType<ReturnType<Prediction["getCells"]>>;

// For Pidport, prob result is in the [0, 100] scale
export const DEFAULT_OPTIMAL_THRESHOLD = 50;

// TODO: memorize?
export class Prediction {
  constructor(readonly data: PredictionSchema) {}

  static create(prediction?: PredictionSchema | null) {
    if (!prediction) return null;
    return new Prediction(prediction);
  }

  get allLabels() {
    return Object.values(this.data.result?.labels || {});
  }

  get labels() {
    return this.getLabelsWithThreshold(0);
  }

  get optimal_threshold() {
    return this.data.result?.threshold ?? DEFAULT_OPTIMAL_THRESHOLD;
  }

  getLabelsWithThreshold(threshold: number) {
    return uniq(
      flatten(
        Object.values(this.data.result?.cells || {}).map(cell =>
          Object.keys(cell).filter(key => cell[key] >= threshold)
        )
      )
    )
      .map(key => (this.data.result?.labels || {})[key])
      .filter(notEmpty)
      .sort();
  }

  hasLabelsWithThreshold(threshold: number) {
    return Object.values(this.data.result?.cells || {}).some(cell =>
      Object.values(cell).some(x => x >= threshold)
    );
  }

  /**
   * Get an array of cells which meet criteria of provided labels and threshold.
   * @param labels labels of which a picked cell must include at least one
   * @param threshold min average probability threshold to pick a cell
   */
  getCells({ labels, threshold }: GetCells) {
    if (!this.data.result?.cells) return [];
    const { labels: labelsMap, cells } = this.data.result;
    return Object.keys(cells)
      .map(key => {
        const [x, y] = key.split(":").map(Number);
        const item = cells[key];
        // Limit to labels provided
        const pickedLabels = pick(
          // Replace internal label ids such as "0" with label name such as "stomach-polyp", for easier access.
          // TODO: Clarify the reason of introducing the internal id, as it increases the complexity of the data structure.
          mapKeys(item, (_, key) => labelsMap[key]),
          labels
        );
        const probabilities = Object.values(pickedLabels);
        // Average probability of picked labels
        const probability =
          probabilities.reduce((a, b) => a + b, 0) /
          (probabilities.length || 1);
        return {
          x,
          y,
          labels: pickedLabels,
          probability,
          key
        };
      })
      .filter(cell => !isEmpty(cell.labels) && cell.probability >= threshold);
  }

  /**
   * Get bg color config for this prediction
   */
  getBgConfigure(threshold: number): Partial<ColorAdjustment> {
    if (!this.hasLabelsWithThreshold(threshold)) return {};
    // For cytology image, reduce brightness when highlighting prediction
    if (this.data.modelName?.toLowerCase().startsWith("cyto")) {
      return { bitmapBrightness: -0.25 };
    }
    // Otherwise grayscale
    return { bitmapSaturation: 0 };
  }

  /**
   * Get overlay getter which is consumed by BitmapLayer for generating overlay
   * @param isHeatmapMode whether overlay is for heatmap mode
   * @param isHighlightMode whether overlay is for highlight mode
   * @param labels labels of which a picked cell must include at least one
   * @param threshold min average probability threshold to pick a cell
   * @param imageWidth width of image
   * @param imageHeight height of image
   */
  getOverlay({
    isHeatmapMode,
    isHighlightMode,
    threshold,
    showGradientEdge,
    labels,
    imageWidth,
    imageHeight
  }: GetOverlay): OverlayGetter {
    if (!this.data.result) return null;
    const { cellSize } = this.data.result;
    // Get dimension of the overlay bitmap, in the unit of cell.
    // Note that the dimension of the overlay bitmap should be:
    // 1. power of 2 for performance and max compatibility (although it could be relieved by set TEXTURE_WRAP_S and could result larger bitmap)
    // 2. aligned to multiple of 4 since we are using one-byte per pixel format and has not set texture process unit to 1 (default to 4 bytes)
    const [width, height] = [imageWidth, imageHeight].map(x =>
      Math.max(4, Math.pow(2, Math.ceil(Math.log(x / cellSize) * Math.LOG2E)))
    );
    // Use format of one-byte per pixel format, initialized to 0
    // TODO: Use float array for float with better precision instead of integer?
    const data = new Uint8Array(1 * width * height);
    // Use the avg probability of each cell as the overlay bitmap data
    this.getCells({ labels, threshold }).forEach(
      ({ x, y, probability }) => (data[x + y * width] = probability)
    );
    const texture = {
      data,
      width,
      height,
      parameters: {
        // For highlight mode, show block of cells; otherwise, for heatmap mode, use the default LINEAR filter to show gradient at edge
        [GL.TEXTURE_MAG_FILTER]:
          isHeatmapMode && showGradientEdge ? GL.LINEAR : GL.NEAREST,
        [GL.TEXTURE_MIN_FILTER]: GL.NEAREST,
        [GL.TEXTURE_WRAP_S]: GL.CLAMP_TO_EDGE,
        [GL.TEXTURE_WRAP_T]: GL.CLAMP_TO_EDGE
      },
      // Ref https://luma.gl/docs/api-reference/webgl/texture#texture-format-combinations
      format: GL.LUMINANCE,
      mipmap: false
    };
    return ({ tileWidth, tileHeight, tileLeft, tileTop }) => {
      /**
       *
       *  ---------------|---------------
       *  | overlay cell |              |
       *  |     *************           |
       *  |     *  tile     *           |
       *  ------*           *------------
       *  |     *************           |
       *
       * Image tile, when been mapped to overlay region, overlaps with overlay cells.
       * In overlay bitmap (UV coordination), we could then specify image position by ratio and offset:
       * - ratio (normalized to 1): tile size / overlay size
       * - offset (normalized to 1): tile index * tile size / overlay size = tile index * ratio
       *
       * With given overlay bitmap coordinate uv, we could then get overlay value (texel) by using: offset + ratio * uv
       */
      const overlayRatioX = tileWidth / (width * cellSize);
      const overlayRatioY = tileHeight / (height * cellSize);
      const overlayOffsetX = tileLeft / (width * cellSize);
      const overlayOffsetY = tileTop / (height * cellSize);
      return {
        overlayTexture: texture,
        overlayRatioX,
        overlayRatioY,
        overlayOffsetX,
        overlayOffsetY,
        isHeatmapMode,
        isHighlightMode
      };
    };
  }

  /**
   * Get an array of cells which is augmented with polygon attribute, for Grid layer to consume
   * @param width width of image
   * @param height height of image
   */
  getGridData({ width, height, ...rest }: GetGridData) {
    if (!this.data.result) return [];
    const { cellSize } = this.data.result;
    class GridData {
      constructor(
        readonly cell: Cell,
        readonly polygon: [number, number][][]
      ) {}

      getTooltip() {
        return Object.keys(this.cell.labels)
          .map(key => aiLabelsMap[key]?.displayName.en || "")
          .join("\n");
      }
    }
    const scale = (x: number, y: number) => [
      Math.min(x * cellSize, width),
      Math.min(y * cellSize, height)
    ];

    // Organize cells into groups and generate grid for each group
    return getCellGroups(this.getCells(rest)).map(
      // @ts-ignore
      cells => new GridData(cells[0], getRings(cells, scale))
    );
  }
}

// Wrap region, probability
// Group cells, by labels, into different regions, get boundary polygon of each regions

function getCellMap(cells: Cell[]): Map<string, Cell> {
  return new Map(cells.map(cell => [cell.key, cell]));
}

function getLabelSet(cell: Cell) {
  return new Set(Object.keys(cell.labels));
}

// DFS reaches call stack limit very soon.
function getConnectedCells(
  predicate: (v: Cell) => boolean,
  cellMap: Map<string, Cell>,
  i: number,
  j: number
) {
  const queue = [[i, j]];
  const ret: Cell[] = [];
  while (queue.length) {
    const [x, y] = queue.pop()!;
    const key = [x, y].join(":");
    const cell = cellMap.get(key);
    if (!cell || !predicate(cell)) continue;
    cellMap.delete(key);
    ret.push(cell);
    queue.push([x, y - 1], [x + 1, y], [x, y + 1], [x - 1, y]);
  }
  return ret;
}

// Put cells to different groups by adjacence and predicate
export function getCellGroups(cells: Cell[]) {
  const map = getCellMap(cells);
  const groups = [];
  while (map.size) {
    const cell: Cell = map.values().next().value;
    const labelSet = getLabelSet(cell);
    const predicate = (v: Cell) => isEqual(labelSet, getLabelSet(v));
    // @ts-ignore
    const group = getConnectedCells(predicate, map, cell.x, cell.y);
    // @ts-ignore
    groups.push(group);
  }
  return groups;
}

const getUpEdge = (x: number, y: number) => [x, y, x, y - 1].join(":");
const getDownEdge = (x: number, y: number) => [x, y, x, y + 1].join(":");
const getLeftEdge = (x: number, y: number) => [x, y, x - 1, y].join(":");
const getRightEdge = (x: number, y: number) => [x, y, x + 1, y].join(":");

// Pop the first found edge from a vertex
function popEdge(edges: Set<string>, x: number, y: number) {
  for (const edge of [
    getUpEdge,
    getRightEdge,
    getDownEdge,
    getLeftEdge
  ].map(op => op(x, y))) {
    if (edges.delete(edge)) return edge;
  }
}

// For a set of adjacent cells, get polygons of boundaries (outer boundary and holes) of the entire region
function getRings(cells, scale) {
  const map = getCellMap(cells);
  const hasCell = (x: number, y: number) => map.has([x, y].join(":"));

  // Collect edges
  const edges: Set<string> = cells.reduce((ret: Set<string>, cell: Cell) => {
    const { x, y } = cell;
    // For edges on top/right/bottom/left, if there is no adjacent cell, the edge belongs to bounday and is collected.
    // Corresponding edge direction is also clockwise: leff-to-right, downwards, right-to-left, upwards.
    if (!hasCell(x, y - 1)) ret.add(getRightEdge(x, y));
    if (!hasCell(x + 1, y)) ret.add(getDownEdge(x + 1, y));
    if (!hasCell(x, y + 1)) ret.add(getLeftEdge(x + 1, y + 1));
    if (!hasCell(x - 1, y)) ret.add(getUpEdge(x, y + 1));
    return ret;
  }, new Set<string>());

  const rings = [];
  // Join edges until all edges are used
  while (edges.size) {
    const ring = [];
    // Start from the first edge
    let edge = edges.values().next().value;
    while (edge) {
      const [sx, sy, dx, dy] = edge.split(":").map(Number);
      // Push the start vertex
      // @ts-ignore
      ring.push(scale(sx, sy));
      // The end vertex becomes the new start vertex
      edge = popEdge(edges, dx, dy);
    }
    // The first edge was used twice, thus two vertice at the start and end
    ring.pop();
    // @ts-ignore
    rings.push(ring);
  }
  // Sort polygons, the outer boundary needs to be the first element
  // @ts-ignore
  return rings.sort((a, b) => b.length - a.length);
}
