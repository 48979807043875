import React from "react";
import {
  Alert,
  Box,
  ButtonGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text
} from "@chakra-ui/react";
import { useAsync } from "react-async";

import { useLocale } from "app/locale";
import { useAPI } from "api";
import { Button, ModalCloseButton } from "components/core";
import { useOrgLookup } from "app/auth-container";

export const DialogTransferCase = ({ pathologicCase, close }) => {
  const api = useAPI();
  const locale = useLocale();
  const { getOrgById } = useOrgLookup();

  const { caseNumber, id, intendedOwnerOrganizationId } = pathologicCase;
  const org = getOrgById(intendedOwnerOrganizationId);

  const { run: transferCase, isPending, error } = useAsync({
    deferFn: () => api.cases.transferOwnership(id, intendedOwnerOrganizationId),
    onResolve: () => close(true)
  });
  const onSubmit = event => {
    event.preventDefault();
    transferCase();
  };

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader>
        {locale.todo("Transfer Ownership")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text>
          Do you want to transfer the ownership of the case{" "}
          <code>{caseNumber}</code> to <b>{org.name}</b>?
        </Text>
        {error && (
          <Alert status="error" mt={4} display="block">
            <Box>{locale.todo("Unable to transfer the ownership")}</Box>
            {error.message && <Box>{error.message}</Box>}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => close(false)} isDisabled={isPending}>
            {locale.todo("Cancel")}
          </Button>
          <Button type="submit" primary isLoading={isPending}>
            {locale.todo("Transfer Ownership")}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};
