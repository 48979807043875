import { Global, css, useTheme } from "@emotion/react";

export const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        ::-webkit-scrollbar-track {
          background-color: ${theme.colors.gray["250"]};
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${theme.colors.gray["650"]};
        }
      `}
    />
  );
};
