import { AfterResponseHook } from "ky";
import debugModule from "debug";
import { parse, stringify } from "qs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";

import { useLocale } from "app/locale";
import { history } from "utils/history";

const debug = debugModule("medmain:terms");

export const checkAgreementTerms: AfterResponseHook = async (
  req,
  opts,
  res
) => {
  if (res.status !== 401) return res;

  const data = await res.json();
  if (data.message === "agreement") {
    const { pathname, search } = window.location;
    const q = parse(search, { ignoreQueryPrefix: true });
    const nextLocation = {
      pathname: "/terms/agreement",
      search: stringify({
        r: pathname === "/terms/agreement" ? q.r : pathname
      })
    };
    debug("Redirect to terms agreement.", nextLocation);
    history.replace(nextLocation);
  }
  return res;
};

export const useTermsAgreement = ({ type }: { type: "service" | "ai" }) => {
  const locale = useLocale();

  const validationSchema = yup.object().shape({
    ...(type === "service"
      ? {
          termsOfService: yup
            .boolean()
            .oneOf([true], locale.terms.agreementTermsOfServiceError)
        }
      : {}),
    ...(type === "ai"
      ? {
          termsOfAI: yup
            .boolean()
            .oneOf([true], locale.terms.ai.agreementTermsOfAIError)
        }
      : {})
  });

  const {
    register,
    errors,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  return {
    register,
    errors,
    isSubmitting,
    handleSubmit
  };
};
