import React, { lazy, Suspense } from "react";
import { Router, Switch, Route, Redirect, RouteProps } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { useMount, useBeforeUnload } from "react-use";

import { NotFound } from "pages/not-found";
import { CasesRoot } from "pages/cases/cases-root";
import { useAccount, useLogin } from "app/auth-container";
import { AgreementContainer } from "features/ai-legal-terms/agreement";
import { Agreement } from "features/ai-legal-terms/agreement-modal";
import { BulkUploadContainer } from "app/upload-bulk-images-container";
import { ImportCSVContainer } from "app/import-csv-container";
import { useAppUpdateChecker } from "app/app-update-checker";
import { UploadCasesImagesContainer } from "app/upload-cases-images-container";
import { NotificationsContainer } from "app/notification-container";
import { history } from "utils/history";
import { PageLocationContainer } from "utils/hooks";
import { customTheme } from "custom-theme";
import { ModalContainer, ModalRoot, Spinner, Splash } from "components/core";
import { Layout } from "components/layout";
import { ErrorBoundary, ErrorFallback } from "./error-handling";
import * as routes from "./routes";
import "./style.css";
import { ViewerSwitcher } from "pages/cases/viewer-switcher";
import { GlobalStyles } from "components/core";
import { ScrollableContainer } from "components/layout/container";

// Full-screen attachment pages
const AttachmentsViewerPage = lazy(() =>
  import("pages/cases/attachments-viewer-page")
);

// Full-screen viewer pages (specific layout)
const ImageViewerPage = lazy(() => import("pages/cases/image-viewer-page"));
const ViewerPage = lazy(() => import("pages/cases/viewer-page"));

// Lazy loaded pages for authenticated users
const BulkUploadPage = lazy(() => import("pages/bulk-upload/bulk-upload-page"));
const ImportCSVPage = lazy(() => import("pages/bulk-upload/import-csv-page"));
const OrganizationRoot = lazy(() =>
  import("pages/organizations/organization-root")
);
const ContractRoot = lazy(() => import("pages/contracts/contract-root"));
const UserPage = lazy(() => import("pages/users"));

const TermsPage = lazy(() => import("pages/terms"));

const ExportCSVPage = lazy(() => import("pages/export-csv"));

// For HMR, AuthContainer, ApiContainer and AccountContainer have been moved to index.tsx
export const Root = () => {
  useMount(() => {
    document.body.className = "";
  });
  return (
    <ChakraProvider theme={customTheme}>
      <Router history={history}>
        <GlobalStyles />
        <ModalContainer.Provider>
          <ModalRoot />
          <AppRoutes />
        </ModalContainer.Provider>
      </Router>
    </ChakraProvider>
  );
};

const AppRoutes = () => {
  // Using auth0-spa, explicit login and auth callback are no longer needed.
  // All routes in AuthenticatedUserRoutes, even 404, are private.

  useAppUpdateChecker({
    interval: 60 * 1000, // check if an app update is available every minute
    isSimulationMode: false // set to true to check the notification in dev
  });

  return (
    <Switch>
      {/* keep an explicit redirect and to avoid change on infra config */}
      <Redirect exact from="/signed-out" to="/" />
      <Route>
        <AuthenticatedUserRoot />
      </Route>
    </Switch>
  );
};

const AuthenticatedUserRoot = () => {
  useLogin();
  const { isReady } = useAccount();

  if (!isReady) return <Splash />;

  // The `BulkUploadContainer` needs to be called after `useLogin()` in the component tree
  return (
    <ActionContainers>
      <Agreement />
      <PageLocationContainer.Provider initialState={globalModals}>
        <ErrorBoundary
          fallback={
            <Layout>
              <ErrorFallback />
            </Layout>
          }
        >
          <Suspense fallback={<Splash />}>
            <Switch>
              <Route
                path={routes.IMAGE_ATTACHMENTS_FULL_VIEW_PAGE.route}
                component={AttachmentsViewerPage}
              />
              <Route
                path={routes.VIEWER_PAGE_SWITCHER.route}
                component={ViewerSwitcher}
              />
              <Route
                path={routes.IMAGE_VIEWER_PAGE.route}
                component={ImageViewerPage}
              />
              <Route path={routes.VIEWER_PAGE.route} component={ViewerPage} />
              <Layout>
                <AuthenticatedUserRoutes />
              </Layout>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </PageLocationContainer.Provider>
    </ActionContainers>
  );
};

// Global modals & drawers that have unique URL
const globalModals: RouteProps[] = [
  {
    path: "/my-account",
    component: lazy(() => import("pages/my-account/my-account-page"))
  }
];

const AuthenticatedUserRoutes = () => {
  const { pageLocation } = PageLocationContainer.useContainer();
  const {
    isMemberRole,
    isAdministratorRole,
    isProprietorRole,
    isCustomerSupportRole,
    isNikonCustomerSupportRole,
    isSuper
  } = useAccount();
  useWarnBeforeLeaving();

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Suspense fallback={<Spinner />}>
        <Switch location={pageLocation}>
          <Redirect exact from="/" to="/organizations" />

          {!isCustomerSupportRole && (
            <Route path="/cases">
              <CasesRoot />
            </Route>
          )}

          {!isCustomerSupportRole && isMemberRole && (
            <Route path="/bulk-upload">
              <ScrollableContainer>
                <BulkUploadPage />
              </ScrollableContainer>
            </Route>
          )}

          {!isCustomerSupportRole && isSuper() && (
            <Route path="/import-csv">
              <ScrollableContainer>
                <ImportCSVPage />
              </ScrollableContainer>
            </Route>
          )}

          <Route path="/organizations">
            <OrganizationRoot />
          </Route>

          {isAdministratorRole && (
            <Route path="/users">
              <UserPage />
            </Route>
          )}

          {(isProprietorRole || isNikonCustomerSupportRole) && (
            <Route path="/contracts">
              <ContractRoot />
            </Route>
          )}

          <Route path="/terms/agreement" exact>
            <TermsPage needAgreement />
          </Route>

          {(isSuper() ||
            isCustomerSupportRole ||
            isNikonCustomerSupportRole) && (
            <Route path="/export-csv" exact>
              <ExportCSVPage />
            </Route>
          )}

          <Route>
            <NotFound />
          </Route>
        </Switch>
        {globalModals.map((props, idx) => (
          <Route key={props.path?.toString() || idx} {...props} />
        ))}
      </Suspense>
    </ErrorBoundary>
  );
};

const ActionContainers = ({ children }) => {
  return (
    <AgreementContainer.Provider>
      <NotificationsContainer.Provider>
        <UploadCasesImagesContainer.Provider>
          <BulkUploadContainer.Provider>
            <ImportCSVContainer.Provider>
              {children}
            </ImportCSVContainer.Provider>
          </BulkUploadContainer.Provider>
        </UploadCasesImagesContainer.Provider>
      </NotificationsContainer.Provider>
    </AgreementContainer.Provider>
  );
};

// Warn before leaving or reloading the browser tab if files are being uploaded, whatever page is displayed
// The customized message does not show up on modern browsers
// Instead Chrome displays: "Changes you made may not be saved"
function useWarnBeforeLeaving() {
  const { currentFiles } = UploadCasesImagesContainer.useContainer();
  const uploadingFiles = currentFiles.filter(
    file => file.status === "uploading"
  );
  const isUploading = uploadingFiles.length > 0;

  useBeforeUnload(
    isUploading,
    `Are you sure you want to abort the image upload?\nClick on "Cancel" button to keep uploading images.`
  );
}
