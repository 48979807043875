import React from "react";
import { Icon, IconProps, createIcon } from "@chakra-ui/react";
import { AiOutlineDown, AiOutlineDownload, AiOutlineUp } from "react-icons/ai";
import { IoMdShareAlt } from "react-icons/io";
import { HiOutlineHand } from "react-icons/hi";
import {
  MdAdd,
  MdArrowBack,
  MdArrowForward,
  MdCheck,
  MdClose,
  MdDelete,
  MdEdit,
  MdHelpOutline,
  MdInfo,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdLoop,
  MdOpenInNew,
  MdRemove,
  MdSearch,
  MdUndo,
  MdWarning
} from "react-icons/md";
import { BsGear } from "react-icons/bs";
import { FaChevronDown, FaChevronUp, FaTrash } from "react-icons/fa";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { RiFileUploadLine, RiFolderUploadLine } from "react-icons/ri";

const wrap = (Component: React.FC, settings?: IconProps) => (
  props: IconProps
) => <Icon as={Component} {...settings} {...props} />;

export const AddIcon = createIcon({
  d:
    "M0,12a1.5,1.5,0,0,0,1.5,1.5h8.75a.25.25,0,0,1,.25.25V22.5a1.5,1.5,0,0,0,3,0V13.75a.25.25,0,0,1,.25-.25H22.5a1.5,1.5,0,0,0,0-3H13.75a.25.25,0,0,1-.25-.25V1.5a1.5,1.5,0,0,0-3,0v8.75a.25.25,0,0,1-.25.25H1.5A1.5,1.5,0,0,0,0,12Z",
  displayName: "AddIcon"
});

export const BackIcon = wrap(MdArrowBack, { fontSize: 24 });

export const CheckIcon = wrap(MdCheck, { fontSize: 24 });

export const ChevronDownIcon = wrap(MdKeyboardArrowDown);

export const ChevronRightIcon = wrap(MdKeyboardArrowRight);

export const AiOutlineDownIcon = wrap(AiOutlineDown);

export const AiOutlineUpIcon = wrap(AiOutlineUp);
export const FaChevronDownIcon = wrap(FaChevronDown);

export const FaChevronUpIcon = wrap(FaChevronUp);

export const CloseIcon = wrap(MdClose);

export const DeleteIcon = wrap(MdDelete, { fontSize: 20 });

export const DownloadIcon = wrap(AiOutlineDownload, { fontSize: 20 });

export const EditIcon = wrap(MdEdit);

export const GearIcon = wrap(BsGear, { fontSize: 20 });

export const TrashIcon = wrap(FaTrash, { fontSize: 15 });

export const InfoIcon = wrap(MdInfo, { fontSize: 24 });

export const MinusIcon = wrap(MdRemove, { fontSize: 12 });

export const NextIcon = wrap(MdArrowForward, { fontSize: 20 });

export const PreviousIcon = wrap(MdArrowBack, { fontSize: 20 });

export const HelpIcon = wrap(MdHelpOutline, { fontSize: 20 });

export const OpenInNewIcon = wrap(MdOpenInNew, { fontSize: 20 });

export const PlusIcon = wrap(MdAdd, { fontSize: 12 });

export const ReloadIcon = wrap(MdLoop, { fontSize: 20 });

export const ResetIcon = wrap(MdUndo, { fontSize: 20 });

export const SearchIcon = wrap(MdSearch, { fontSize: 20 });

export const UploadIcon = wrap(RiFileUploadLine);

export const WarningIcon = wrap(MdWarning, { fontSize: 24 });

export const HandIcon = wrap(HiOutlineHand, { fontSize: 24 });

export const AngleIcon = createIcon({
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  },
  path: [
    <polyline key="polyline" points="20 19 1 19 15 5" />,
    <path key="path" d="M 11.5 19A12 12 0 0 0 9 12" />
  ],
  displayName: "AngleIcon"
});

export const ShareIcon = wrap(IoMdShareAlt, { fontSize: 20 });

export const CaseListIcon = wrap(AiOutlineFileSearch, { fontSize: 30 });

export const UploadFilesIcon = wrap(RiFolderUploadLine, { fontSize: 30 });

export const UserListIcon = wrap(FiUsers, { fontSize: 30 });

export const ImportCSVIcon = wrap(RiFileUploadLine, { fontSize: 30 });
