import React, { forwardRef } from "react";
import {
  Checkbox as ChakraCheckbox,
  Input as ChakraInput,
  Textarea as ChakraTextarea,
  Radio as ChakraRadio,
  RadioProps,
  RadioGroup as ChakraRadioGroup,
  useColorMode
} from "@chakra-ui/react";
import get from "lodash/get";
import styled from "@emotion/styled";

const inputCommonStyles = props => `
  border-color: ${get(props.theme.colors, "gray.300")};
  border-radius: 2px;
  &[aria-invalid="true"] {
    box-shadow: none;
    border-color: ${get(props.theme.colors, "red.400")};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${props.theme.colors.primary700Alpha(0.5)};
    border-color: ${get(
      props.theme.colors,
      props.isInvalid ? "red.400" : "gray.300"
    )};
  }
  &:hover {
    border-color: ${get(
      props.theme.colors,
      props.isInvalid ? "red.500" : "gray.300"
    )};
  }
`;

// Hard to achieve following logic with combination of _hover _focus _invalid
// Either [aria-invalid="true"] or props.isInvalid could be used to identify invalid
export const Input = styled(ChakraInput)`
  ${props => inputCommonStyles(props)}
`;

export const Textarea = styled(ChakraTextarea)`
  ${props => inputCommonStyles(props)}
`;

export const Radio = forwardRef(
  (props: RadioProps, ref: React.Ref<HTMLInputElement>) => {
    return <ChakraRadio ref={ref} {...props} colorScheme="primary" />;
  }
);

export const RadioGroup = styled(ChakraRadioGroup)`
  ${props => {
    const { colorMode } = useColorMode();
    return colorMode === "light"
      ? `
      span[data-focus] {
        box-shadow: 0 0 0 2px rgba(255, 213, 0, 0.4);
      }
      span[data-checked] {
        border-color: ${props.theme.colors.primary["500"]};
        &::before {
          width: 6px;
          height: 6px;
        }
      }`
      : `
      span[data-focus] {
        box-shadow: none;
      }
      span.chakra-radio__control {
        background-color: ${props.theme.colors.gray["800"]};
        &:disabled {
          border-color: ${props.theme.colors.gray650Alpha(1)};
        }
        &:hover:not(:disabled) {
          border-color: ${props.theme.colors.gray250Alpha(1)};
        }
        &:active:not(:disabled) {
          background-color: ${props.theme.colors.gray["960"]};
          border-color: ${props.theme.colors.gray650Alpha(1)};
        }
        &[data-checked] {
          background-color: ${props.theme.colors.gray["800"]};
          &::before {
            width: 6px;
            height: 6px;
            background-color: ${props.theme.colors.gray["50"]};
          }
          &:disabled {
            border-color: ${props.theme.colors.gray650Alpha(0.7)};
            &::before {
              background-color: ${props.theme.colors.gray250Alpha(0.7)};
            }
          }
          &:hover:not(:disabled) {
            border-color: ${props.theme.colors.gray250Alpha(1)};
            &::before {
              background-color: ${props.theme.colors.gray250Alpha(1)};
            }
          }
          &:active:not(:disabled) {
            background-color: ${props.theme.colors.gray["960"]};
            border-color: ${props.theme.colors.gray650Alpha(1)};
            &::before {
              background-color: ${props.theme.colors.gray250Alpha(1)};
            }
          }
        }
      }`;
  }}
`;

export const Checkbox = styled(ChakraCheckbox)`
  ${props => {
    const { colorMode } = useColorMode();
    return colorMode === "light"
      ? `
      span.chakra-checkbox__control {
        border-color: ${props.theme.colors.gray["1000"]};
        background-color: transparent;
        color: ${props.theme.colors.gray["800"]};
      }
      span[data-checked].chakra-checkbox__control:hover {
        border-color: ${props.theme.colors.gray["1000"]};
        background-color: transparent;
      }
    `
      : `
      span[data-checked].chakra-checkbox__control {
        
      }
      
      `;
  }}
`;
