import React, { useEffect } from "react";
import ky from "ky";
import { Box, Stack, useToast } from "@chakra-ui/react";
import debugModule from "debug";

import { useLocale } from "app/locale";
import { Button } from "components/core";

const debug = debugModule("medmain:app-update-checker");
const TOAST_ID = "app-update-checker";

export function useAppUpdateChecker(options?: Options) {
  const locale = useLocale();
  const toast = useToast();

  useEffect(() => {
    const updateChecker = new AppUpdateChecker(async meta => {
      if (toast.isActive(TOAST_ID)) return false;
      return notifyUpdate({ ...meta, toast, locale });
    }, options);
    updateChecker.start();
    return () => updateChecker.stop();
  });
}

type Meta = {
  date: string;
  version: string;
};

type Callback = (Meta) => Promise<boolean>; // called when the user chooses "Update" (true) or "Ignore" (false)

type Settings = {
  url: string; // URL of the JSON file to ping to get app meta data
  interval: number; // the number of milliseconds between each request
  nextIntervalRatio: number; // by how much the interval changes, when the notification is ignored
  isSimulationMode: boolean; // used to skip the date comparison, to be able to check the notification in dev mode
};
type Options = Partial<Settings>;

export class AppUpdateChecker implements Settings {
  onUpdateAvailable: Callback;
  url: string;
  interval: number;
  nextIntervalRatio: number;
  isSimulationMode: boolean;
  initialMeta: Meta | undefined;
  timer: number | undefined;

  constructor(
    onUpdateAvailable: Callback,
    {
      url = "/meta.json",
      interval = 60 * 1000, // 1 minute by default
      nextIntervalRatio = 2, // the interval between consecutive notifications doubles every time the user "ignores"
      isSimulationMode = false // skip the data comparison test and always show the notification
    }: Options = {}
  ) {
    this.onUpdateAvailable = onUpdateAvailable;
    this.url = url;
    this.interval = interval;
    this.nextIntervalRatio = nextIntervalRatio;
    this.isSimulationMode = isSimulationMode;

    this.initialize();
  }

  initialize = async () => {
    this.initialMeta = await this.fetchMeta();
    if (!this.initialMeta) {
      this.stop();
    }
  };

  start = () => {
    if (!this.timer) {
      this.timer = window.setInterval(
        this.checkForUpdateAvailability,
        this.interval
      );
    }
  };

  stop = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  };

  async fetchMeta(): Promise<Meta | undefined> {
    try {
      debug(`Checking app update every ${this.interval / 1000} seconds`);
      const meta = await ky(this.url).json<Meta>();
      return meta;
    } catch (error) {
      debug("Unable to fetch meta data", error.message);
      return undefined;
    }
  }

  checkForUpdateAvailability = async () => {
    try {
      const latestMeta = await this.fetchMeta();
      if (!latestMeta) return false;

      if (
        this.isSimulationMode ||
        new Date(latestMeta.date) > new Date(this.initialMeta!.date)
      ) {
        this.stop();
        const isUpdateAccepted = await this.onUpdateAvailable(latestMeta);
        if (isUpdateAccepted) {
          window.location.reload();
        } else {
          this.interval *= this.nextIntervalRatio;
          this.start();
        }
      }
    } catch (error) {
      debug(`Unable to check for app update: ${error.message}`);
    }
  };
}

function notifyUpdate({ version, toast, locale }): Promise<boolean> {
  return new Promise(resolve => {
    toast({
      id: TOAST_ID, // to prevent a possible duplication of toasts on the screen
      position: "top-right",
      duration: null,
      render: ({ onClose }) => (
        <AppUpdateNotification
          version={version}
          onClose={value => {
            onClose();
            resolve(value);
          }}
          locale={locale}
        />
      )
    });
  });
}

type Props = {
  onClose: (boolean) => void;
  version: string;
  locale: any; // we can't just read the context using `useLocale` as usual because the toast is rendered outside the component tree
};
export const AppUpdateNotification = ({ version, onClose, locale }: Props) => {
  return (
    <Box
      m={4}
      p={4}
      bg="white"
      width={400}
      borderWidth="1px"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
    >
      <Box fontSize="xl" mb={2}>
        {locale.todo("Application Update")}
      </Box>

      <Box>
        {locale.todo(
          "A new version of Nikon Pathology Cloud Service is available."
        )}
      </Box>

      <Stack mt={4} spacing={4} isInline>
        <Box flexBasis="50%">
          <Button onClick={() => onClose(false)} width="100%">
            {locale.todo("Ignore")}
          </Button>
        </Box>
        <Box flexBasis="50%">
          <Button primary onClick={() => onClose(true)} width="100%">
            {locale.todo("Refresh")}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
