import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  Flex,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tag,
  TagLabel,
  TagCloseButton,
  Stack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { CustomTagProps } from "rc-select/lib/BaseSelect";

import { isNikon } from "api";
import { useAccount } from "app/auth-container";
import { useSearchQueryForm } from "app/form";
import { useLocale } from "app/locale";
import { Query } from "models/query";
import { SearchOptions } from "types";
import {
  EditableField,
  FieldGroup,
  ModelPicker,
  OrganPicker,
  SpecimenTypePicker
} from "components/common/fields";
import { TagPicker } from "components/common/tag-picker";
import {
  Button,
  Input,
  ModalCloseButton,
  Radio,
  RadioGroup
} from "components/core";
import { ImageQuery } from "./case-query";

export type CaseSearchState = {
  query: Query;
  visibility: SearchOptions["caseVisibility"];
  hasImage?: boolean;
};

type Props = Omit<CaseSearchState, "hasImage"> & {
  onClose: (state: CaseSearchState | undefined) => void;
  canCreatePrediction: boolean;
};
export const CaseSearch = ({
  query,
  visibility: defaultVisibility,
  onClose,
  canCreatePrediction
}: Props) => {
  const locale = useLocale();
  const { isSuper } = useAccount();
  const { getValue, clear, setExpression, setValue } = useSearchQueryForm(
    query
  );
  const [visibility, setVisibility] = useState(defaultVisibility);

  const handleChange = (fieldName: string) => value => {
    setValue(fieldName)(value);
  };

  const handleInputChange = (fieldName: string) => (
    event: React.FormEvent<HTMLInputElement>
  ) =>
    setExpression(fieldName, {
      operator: "is-like",
      value: event.currentTarget?.value
    });

  return (
    <>
      <ModalHeader>
        {locale.caseSearch.heading}
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <form
          id="caseSearch"
          onSubmit={event => {
            event.preventDefault();
            const hasImage = ImageQuery.schema.some(
              item => getValue(item["field"]) !== ""
            );
            onClose({ query, visibility, hasImage });
          }}
        >
          <Box mb={4}>{locale.caseSearch.tip}</Box>
          <Box borderWidth="1px" borderRadius="4px" py={2} px={4}>
            <FieldGroup>
              <EditableField label={locale.caseFields.caseNumber}>
                <Input
                  name="caseNumber"
                  value={getValue("caseNumber")}
                  onChange={handleInputChange("caseNumber")}
                />
              </EditableField>

              <EditableField label={locale.caseFields.diagnosis}>
                <Input
                  name="diagnosis"
                  value={getValue("diagnosis")}
                  onChange={handleInputChange("diagnosis")}
                />
              </EditableField>

              <EditableField label={locale.caseFields.tags}>
                <TagSearch
                  type="case"
                  expression={
                    query.getExpression("tags") || {
                      value: [],
                      operator: "contains-any"
                    }
                  }
                  onChange={(value, operator) => {
                    setExpression("tags", { operator, value });
                  }}
                />
              </EditableField>

              <EditableField label={locale.caseFields.grossFindings}>
                <Input
                  name="grossFindings"
                  value={getValue("grossFindings")}
                  onChange={handleInputChange("grossFindings")}
                />
              </EditableField>

              <EditableField label={locale.caseFields.microscopicFindings}>
                <Input
                  name="microscopicFindings"
                  value={getValue("microscopicFindings")}
                  onChange={handleInputChange("microscopicFindings")}
                />
              </EditableField>

              <EditableField label={locale.caseFields.comments}>
                <Input
                  name="comments"
                  value={getValue("comments")}
                  onChange={handleInputChange("comments")}
                />
              </EditableField>
              {isSuper() && !isNikon && (
                <EditableField label={locale.caseSearch.visibility.label}>
                  <RadioGroup
                    value={visibility}
                    onChange={value =>
                      setVisibility(value as SearchOptions["caseVisibility"])
                    }
                  >
                    <Stack
                      direction={{ base: "column", md: "row" }}
                      align={{ base: "flex-start", md: "center" }}
                    >
                      <Radio value="ALL">
                        {locale.caseSearch.visibility.options.ALL}
                      </Radio>
                      <Radio value="PRIVATE">
                        {locale.caseSearch.visibility.options.PRIVATE}
                      </Radio>
                      <Radio value="PUBLIC">
                        {locale.caseSearch.visibility.options.PUBLIC}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </EditableField>
              )}
              <CommonSearchForm
                query={query}
                getValue={getValue}
                setExpression={setExpression}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                canCreatePrediction={canCreatePrediction}
              />
            </FieldGroup>
          </Box>
        </form>
      </ModalBody>
      <ModalFooter>
        <Box flexGrow={1}>
          <Button
            type="button"
            onClick={() => {
              clear();
              setVisibility("ALL");
            }}
          >
            {locale.clearButtonLabel}
          </Button>
        </Box>

        <Box>
          <ButtonGroup>
            <Button type="button" onClick={() => onClose(undefined)}>
              {locale.cancelButtonLabel}
            </Button>

            <Button type="submit" form="caseSearch" primary>
              {locale.searchButtonLabel}
            </Button>
          </ButtonGroup>
        </Box>
      </ModalFooter>
    </>
  );
};

export const DialogCaseDetailSearch = ({
  canCreatePrediction,
  query,
  onClose
}: Pick<CaseSearchState, "query"> & {
  canCreatePrediction: boolean;
  onClose: (state: Pick<CaseSearchState, "query"> | undefined) => void;
}) => {
  const locale = useLocale();
  const { getValue, setValue, setExpression, clear } = useSearchQueryForm(
    query
  );
  const handleChange = (fieldName: string) => value =>
    setValue(fieldName)(value);

  const handleInputChange = (fieldName: string, operator: string) => (
    event: React.FormEvent<HTMLInputElement>
  ) =>
    setExpression(fieldName, {
      operator,
      value: event.currentTarget?.value
    });

  return (
    <>
      <ModalHeader>
        {locale.todo("Search")}
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <form
          id="imageSearch"
          onSubmit={event => {
            event.preventDefault();
            onClose({ query });
          }}
        >
          <Box mb={4}>
            {locale.todo("Please enter your search criteria below")}
          </Box>
          <Box>
            <FieldGroup>
              <CommonSearchForm
                query={query}
                setExpression={setExpression}
                getValue={getValue}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                canCreatePrediction={canCreatePrediction}
              />
            </FieldGroup>
          </Box>
        </form>
      </ModalBody>
      <ModalFooter>
        <Flex w="full" justifyContent="space-between">
          <Button type="button" onClick={clear}>
            {locale.clearButtonLabel}
          </Button>
          <Button type="submit" form="imageSearch" primary>
            {locale.searchButtonLabel}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};

const CommonSearchForm = ({
  query,
  getValue,
  handleChange,
  setExpression,
  handleInputChange,
  canCreatePrediction
}) => {
  const locale = useLocale();
  const wrapItem = [
    {
      label: locale.caseFields.organ,
      picker: (
        <OrganPicker
          name="organ"
          value={getValue("organ")}
          onChange={handleChange("organ")}
        />
      )
    },
    canCreatePrediction && {
      label: locale.caseFields.model,
      picker: (
        <ModelPicker
          isEnum
          name="modelName"
          value={getValue("modelName")}
          onChange={handleChange("modelName")}
          currentOrgan={getValue("organ")}
        />
      )
    },
    {
      label: locale.caseFields.specimenType.label,
      picker: (
        <SpecimenTypePicker
          name="specimenType"
          value={getValue("specimenType")}
          onChange={handleChange("specimenType")}
        />
      )
    }
  ].filter(value => !!value) as {
    label: string;
    picker: React.ReactNode;
  }[];
  return (
    <>
      <EditableField label={locale.caseFields.displayName}>
        <Input
          name="displayName"
          value={getValue("displayName")}
          onChange={handleInputChange("displayName", "is-like")}
        />
      </EditableField>

      <Wrap w="full" spacing="20px">
        {wrapItem.map(({ label, picker }, index) => (
          <WrapItem
            key={index}
            w={{ base: "full", md: "calc(50% - 20px)" }}
            flexDirection="column"
          >
            <EditableField label={label} w="full" mb={2}>
              {picker}
            </EditableField>
          </WrapItem>
        ))}
      </Wrap>
      <EditableField label={locale.imageList.imageTags}>
        <TagSearch
          type="image"
          expression={
            query.getExpression("imageTags") || {
              value: [],
              operator: "contains-any"
            }
          }
          onChange={(value, operator) => {
            setExpression("imageTags", { operator, value });
          }}
        />
      </EditableField>
    </>
  );
};

type TagSearchProps = {
  type?: "case" | "image";
  expression: {
    value: string[];
    operator: string;
  };
  onChange: (value: string[], operator: string) => void;
};
const TagSearch = ({ type = "case", expression, onChange }: TagSearchProps) => {
  const locale = useLocale();
  const { value, operator } = expression;
  const isDisabled = value.length < 2;

  return (
    <>
      <TagPicker
        id="tags"
        type={type}
        tags={value}
        onChange={tags => onChange(tags, operator)}
        onKeyDown={event => {
          // don't close the Search modal if Escape key is pressed
          if (event.key === "Escape") {
            event.stopPropagation();
          }
        }}
        placeholder="Pick one or several tags"
        maxTagTextLength={200}
        tagRender={tagRender}
      />
      <RadioGroup
        value={operator}
        onChange={operator => {
          onChange(value, operator as string);
        }}
        my={2}
      >
        <Stack direction={{ base: "column", md: "row" }}>
          <Radio value="contains-any" isDisabled={isDisabled}>
            {locale.caseSearch.includeAnyTag}
          </Radio>
          <Radio value="contains-all" isDisabled={isDisabled}>
            {locale.caseSearch.includeAllTags}
          </Radio>
        </Stack>
      </RadioGroup>
    </>
  );
};

// Customize the tag rendered inside the `<Select>` component,
// setting a `max-width` to avoid breaking the layout for very long tags
function tagRender({ label, onClose }: CustomTagProps) {
  return (
    <Tag mr={1} maxW={300}>
      <TagLabel>{label}</TagLabel>
      <TagCloseButton
        onClick={event => {
          event.preventDefault();
          event.stopPropagation(); // needed otherwise the modal is closed when the button is clicked!
          onClose();
        }}
      />
    </Tag>
  );
}
