import React, { useCallback, useState, useEffect } from "react";
import {
  Link as RouterLink,
  useLocation,
  useHistory,
  useParams
} from "react-router-dom";
import { useLocalStorage } from "react-use";
import {
  Badge,
  Box,
  BoxProps,
  Flex,
  Icon,
  IconButton as ChakraIconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
  useToast
} from "@chakra-ui/react";
import { MdArrowDownward, MdForward, MdLock, MdMoreVert } from "react-icons/md";
import { GoNote } from "react-icons/go";
import { stringify } from "qs";
import styled from "@emotion/styled";

import { useAPI } from "api";
import { useAccount, useOrgLookup } from "app/auth-container";
import { useLocale } from "app/locale";
import { hasPermission } from "app/permissions";
import { usePersistSearchParams } from "app/persist-search-params";
import { useQueryStringParams } from "app/query-string-params";
import { useSearch } from "app/search";
import {
  Button,
  DeleteIcon,
  IconButton,
  InfoButton,
  Link,
  MenuButton,
  RoundBackToListButton,
  SearchIcon,
  useModal
} from "components/core";
import { ImageQuery } from "components/cases/case-query";
import {
  DialogCaseDetailSearch,
  CaseSearchState
} from "components/cases/case-search";
import { BatchProcessingButtons } from "components/cases/case-batch-processing-buttons";
import { DialogDeleteCase } from "components/cases/dialog-delete-case";
import { DialogSortCaseImages } from "components/cases/dialog-sort-case-images";
import { DialogTransferCase } from "components/cases/dialog-transfer-case";
import {
  FileIconImage,
  FileIconPDF,
  CommentIcon
} from "components/cases/case-icons";
import { getCommonStatusFromImages } from "components/cases/case-table";
import {
  StatusIcons,
  ToggledPrivateLabelledIcon,
  useLabelled
} from "components/labelled";
import {
  CASE_DETAILS_PAGE,
  CASE_LIST_IMAGES_PAGE,
  CASE_ATTACHMENTS_PAGE,
  CASE_COMMENTS_PAGE,
  CASE_PERMISSIONS_PAGE
} from "routes";
import { FetchCaseState } from "./case-item-fetch-data";
import { PANEL_ANCHOR_ID } from "./case-item-info-panel";
import { InfoIcon } from "components/core";
import { SortIcon } from "components/icons/sort-icon";
import { SearchSummary } from "pages/cases/case-list-page";
import { SearchOptions } from "types";
import { AgreementContainer } from "features/ai-legal-terms/agreement";

const SortButton = ({
  caseSorts,
  setCaseSorts
}: {
  caseSorts: Medmain.CaseSort[];
  setCaseSorts: (arr: Medmain.CaseSort[]) => void;
}) => {
  const modal = useModal();
  const showSortDialog = async () => {
    await modal.dialog({
      render: close => (
        <DialogSortCaseImages
          caseSorts={caseSorts}
          setCaseSorts={setCaseSorts}
          close={close}
        />
      ),
      modalProps: {
        size: "md",
        isCentered: true
      }
    });
  };

  return (
    <IconButton
      aria-label="Sort"
      variant="outline"
      icon={<SortIcon />}
      onClick={showSortDialog}
    />
  );
};

interface PageCaseIdsMap {
  [page: number]: string[];
}

export const CASE_NAVIGATION_KEY = "pidport-case-navigation";

const CaseNavigation = ({ caseSorts }: { caseSorts: Medmain.CaseSort[] }) => {
  const locale = useLocale();
  const api = useAPI();
  const { caseId: currentCaseId } = useParams<{ caseId: string }>();
  const history = useHistory();
  const {
    from,
    prev,
    next,
    index,
    total
  } = useQueryStringParams().readParams();

  const currentIndex = parseInt(index, 10); // 0 〜 limit-1
  const limit = parseInt(from?.limit, 10);
  const page = parseInt(from?.page, 10); // 1 〜
  const totalCaseNum = parseInt(total, 10); // 1 〜
  const prevStr: string | undefined = prev;
  const nextStr: string | undefined = next;

  const canShowNavigationButtons =
    !isNaN(currentIndex) &&
    !isNaN(limit) &&
    !isNaN(page) &&
    !isNaN(totalCaseNum) &&
    (prevStr || nextStr);

  const currentIndexOfTotal = canShowNavigationButtons
    ? limit * (page - 1) + (currentIndex + 1)
    : 0; // 1 〜

  const [prevCaseId, setPrevCaseId] = useState(prevStr);
  const [nextCaseId, setNextCaseId] = useState(nextStr);
  const [storedNavigation, setNavigation] = useLocalStorage<PageCaseIdsMap>(
    CASE_NAVIGATION_KEY,
    {}
  );

  const query: SearchOptions = {
    ...from,
    pathname: "/cases"
  };

  if (!query.query) {
    query.query = [];
  }

  query.query = query.query.map(item => {
    return item.operator === "is-like"
      ? {
          ...item,
          value: `%${item.value}%`
        }
      : item;
  });

  const setPrevNextCaseId = useCallback(
    (prevId?: string, nextId?: string) => {
      setPrevCaseId(prevId);
      setNextCaseId(nextId);
    },
    [setPrevCaseId, setNextCaseId]
  );

  const findAndStoreNavigation = useCallback(
    async (options: SearchOptions) => {
      const res = await api.cases.find(options);
      const caseIds = res.data.map(item => item.id);
      const m: PageCaseIdsMap = storedNavigation ?? {};
      m[options.page] = caseIds;
      setNavigation(m);
    },
    [api.cases, storedNavigation, setNavigation]
  );

  const findPrevNextCaseId = useCallback(async () => {
    const navigationLimit = 50;
    const navigationPage =
      Math.floor((currentIndexOfTotal - 1) / navigationLimit) + 1; // 1 〜
    const idx =
      currentIndexOfTotal - 1 - (navigationPage - 1) * navigationLimit; // 0 〜
    const options: SearchOptions = {
      ...query,
      limit: navigationLimit
    };

    if (!storedNavigation || !storedNavigation[navigationPage]) {
      options.page = navigationPage;
      await findAndStoreNavigation(options);
    }

    if (!storedNavigation) {
      return;
    }

    const caseIds = storedNavigation[navigationPage];

    if (idx === 0) {
      if (navigationPage > 1) {
        if (!storedNavigation[navigationPage - 1]) {
          options.page = navigationPage - 1;
          await findAndStoreNavigation(options);
        }
        const prevCaseIds = storedNavigation[navigationPage - 1];
        setPrevNextCaseId(prevCaseIds[navigationLimit - 1], caseIds[idx + 1]);
      } else {
        setPrevNextCaseId(undefined, caseIds[idx + 1]);
      }
      return;
    }

    if (idx === navigationLimit - 1 || currentIndexOfTotal === totalCaseNum) {
      if (currentIndexOfTotal < totalCaseNum) {
        if (!storedNavigation[navigationPage + 1]) {
          options.page = navigationPage + 1;
          await findAndStoreNavigation(options);
        }
        const nextCaseIds = storedNavigation[navigationPage + 1];
        setPrevNextCaseId(caseIds[idx - 1], nextCaseIds[0]);
      } else {
        setPrevNextCaseId(caseIds[idx - 1], undefined);
      }
      return;
    }

    setPrevNextCaseId(caseIds[idx - 1], caseIds[idx + 1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    storedNavigation,
    findAndStoreNavigation,
    setPrevNextCaseId,
    totalCaseNum,
    currentIndexOfTotal
  ]);

  useEffect(() => {
    if (canShowNavigationButtons) {
      findPrevNextCaseId();
    }
  }, [canShowNavigationButtons, findPrevNextCaseId]);

  const goTo = useCallback(
    (isPrev: boolean, to?: string) => {
      if (!to) {
        return;
      }

      const newIdx = isPrev ? currentIndex - 1 : currentIndex + 1;
      const newPage =
        newIdx < 0 ? page - 1 : newIdx > limit - 1 ? page + 1 : page;
      const queryParams = stringify(
        {
          from: { ...from, page: newPage },
          prev: isPrev ? "" : currentCaseId,
          next: isPrev ? currentCaseId : "",
          index: newIdx < 0 ? limit - 1 : newIdx > limit - 1 ? 0 : newIdx,
          total: totalCaseNum
        },
        { encode: true, skipNulls: true }
      );

      history.push({
        pathname: `/cases/${to}`,
        search: queryParams
      });
    },
    [currentIndex, page, from, currentCaseId, limit, totalCaseNum, history]
  );

  const getSortCondition = (arr: Medmain.CaseSort[]): string => {
    return arr.reduce((accum, x, idx) => {
      return x.field === "filename"
        ? `${accum} ${idx > 0 ? "and " : ""}${
            locale.caseSort.options.filename
          }(${x.order === "ASC" ? "A-Z" : "Z-A"})`
        : `${accum} ${idx > 0 ? "and " : ""}${
            locale.caseSort.options.createdAt
          }(${x.order === "ASC" ? "Previously" : "Recently"})`;
    }, "Sort by ");
  };

  return canShowNavigationButtons ? (
    <Flex>
      <Stack isInline alignItems="center">
        {totalCaseNum && (
          <VStack spacing={0}>
            <Text color="gray.500" fontSize="sm">
              {currentIndexOfTotal} of {totalCaseNum}
            </Text>
            {caseSorts?.length > 0 && (
              <Text
                color="gray.500"
                fontSize="sm"
                textAlign="center"
                wordBreak="break-word"
              >
                {getSortCondition(caseSorts)}
              </Text>
            )}
          </VStack>
        )}
        <Button
          size="xs"
          isDisabled={!prevCaseId}
          onClick={() => goTo(true, prevCaseId)}
        >
          ←
        </Button>
        <Button
          size="xs"
          isDisabled={!nextCaseId}
          onClick={() => goTo(false, nextCaseId)}
        >
          →
        </Button>
      </Stack>
    </Flex>
  ) : (
    <Flex>
      <Stack isInline alignItems="center">
        <VStack spacing={0}>
          {caseSorts?.length > 0 && (
            <Text color="gray.500" fontSize="sm" wordBreak="break-word">
              {getSortCondition(caseSorts)}
            </Text>
          )}
        </VStack>
      </Stack>
    </Flex>
  );
};

export const CaseItemHeader = ({
  pathologicCase,
  isBeingEdited,
  reload,
  isOpen,
  canCreatePrediction,
  isPending,
  isRejected,
  isFulfilled,
  onToggle,
  searchOptions
}: {
  pathologicCase: Medmain.Case;
  reload: FetchCaseState["reload"];
  isBeingEdited?: boolean;
  isOpen: boolean;
  onToggle: () => void;
  canCreatePrediction: boolean;
  isPending: boolean;
  isRejected: boolean;
  isFulfilled: boolean;
  searchOptions: SearchOptions;
}) => {
  const history = useHistory();
  const { caseId } = useParams<{ caseId: string }>();
  const { showList } = usePersistSearchParams();
  const { isSuper } = useAccount();
  const locale = useLocale();
  const modal = useModal();
  const toast = useToast();
  const {
    caseNumber,
    id,
    intendedOwnerOrganizationId,
    organizationId,
    images
  } = pathologicCase;
  const { getOrgById } = useOrgLookup();
  const { togglePrivateLabelledCase, togglePublicLabelledCase } = useLabelled(
    reload
  );
  const publicLabelleds = getCommonStatusFromImages(images, caseId);
  const { query } = searchOptions;
  const imageQuery = ImageQuery.fromJSON(query);
  const { setSearchParams } = useSearch(searchOptions);

  const canDeleteCase = hasPermission("case/delete", pathologicCase);
  const canTransfer =
    isSuper() &&
    intendedOwnerOrganizationId &&
    intendedOwnerOrganizationId !== organizationId;
  const canViewLabelled = hasPermission("case/view_labelled", pathologicCase);
  const canModPrivateLabelled = hasPermission(
    "case/mod_private_labelled",
    pathologicCase
  );
  const canModPublicLabelled = hasPermission(
    "case/mod_public_labelled",
    pathologicCase
  );
  const showContextMenu = !isBeingEdited && (canDeleteCase || canTransfer);
  const shouldShowSearchSummary = query.some(({ field }) => field !== "id");

  const deleteCase = async () => {
    const deleted = await modal.dialog({
      render: close => (
        <DialogDeleteCase close={close} pathologicCase={pathologicCase} />
      ),
      modalProps: { size: "xl" }
    });
    if (!deleted) return false;
    toast({
      title: locale.todo("Case deleted"),
      description: `Case Number: ${caseNumber}`,
      isClosable: true
    });
    showList("/cases"); // back to the Case List page after a case is deleted
  };

  const transferOwnership = async () => {
    const success = await modal.dialog({
      render: close => (
        <DialogTransferCase close={close} pathologicCase={pathologicCase} />
      ),
      modalProps: { size: "xl" }
    });
    if (!success) return false;
    toast({
      title: locale.todo("Case ownership transferred"),
      description: `Case Number: ${caseNumber}`,
      isClosable: true
    });
    reload(); // to  avoid showing stale data
  };

  const handleSearch = async () => {
    const searchState: CaseSearchState = await modal.dialog({
      modalProps: { size: "4xl" },
      render: close => (
        <DialogCaseDetailSearch
          query={imageQuery}
          onClose={close}
          canCreatePrediction={canCreatePrediction}
        />
      )
    });

    if (!searchState) return;

    const nextLocation = setSearchParams({
      query: [
        { field: "id", value: caseId, operator: "is" },
        ...searchState.query.toJSON()
      ],
      hasImage: true
    });
    history.push(nextLocation);
  };

  const org = getOrgById(organizationId);
  const orgName = org?.name || locale.unknownOrg;

  useEffect(() => {
    if (isPending || (!isRejected && !isFulfilled)) return;
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending, isRejected, isFulfilled]);

  return (
    <>
      <Flex
        mb="6"
        flexDirection={{ sm: "column", md: "row" }}
        justifyContent="space-between"
        alignItems={{ sm: "flex-end", md: "center" }}
      >
        <Stack
          direction="row"
          mb={{ sm: 6, md: 0 }}
          alignSelf="start"
          w={{ sm: "100%", md: "auto" }}
        >
          <Box>
            <RoundBackToListButton
              pathname="/cases"
              aria-label="Go to the case list"
            />
          </Box>
          <HeaderCell
            maxW={{
              sm: "calc(50% - 15px)",
              md: "none"
            }}
          >
            <Box>
              <HeaderLabel>{locale.todo("Case Number")}</HeaderLabel>
              <HeaderValue>
                <RouterLink
                  to={`/cases/${id}`}
                  key={caseNumber}
                  title={caseNumber}
                >
                  {caseNumber}
                </RouterLink>
              </HeaderValue>
            </Box>
          </HeaderCell>
          <HeaderCell
            borderLeftWidth="1px"
            maxW={{
              sm: "calc(50% - 15px)",
              md: "none"
            }}
          >
            <HeaderLabel>{locale.todo("Group")}</HeaderLabel>
            <HeaderValue key={orgName}>
              <span title={orgName}>{orgName}</span>
            </HeaderValue>
          </HeaderCell>
        </Stack>

        <Stack
          direction="row"
          spacing="10px"
          flexShrink={0}
          alignItems="center"
        >
          {canViewLabelled && (
            <>
              <ToggledPrivateLabelledIcon
                privateLabelled={images.some(image => image.privateLabelled)}
                onClick={() => {
                  togglePrivateLabelledCase.run({ caseId: id, images });
                }}
                size={26}
                isDisabled={
                  !canModPrivateLabelled || togglePrivateLabelledCase.isPending
                }
              />
              <StatusIcons
                ml={6}
                publicLabelleds={publicLabelleds}
                onClick={({ name, color }) => {
                  togglePublicLabelledCase.run({
                    caseId: id,
                    images,
                    name,
                    color
                  });
                }}
                isActive={({ name, color }) =>
                  publicLabelleds?.some(
                    item => item.name === name && item.color === color
                  ) ?? false
                }
                isDisabled={
                  !canModPublicLabelled || togglePublicLabelledCase.isPending
                }
                canAddIcon={canModPublicLabelled}
                p={2}
                borderRadius="5px"
                backgroundColor={
                  canModPublicLabelled ? "statusBackgroundColor" : "transparent"
                }
              />
            </>
          )}
          <Stack isInline spacing={2}>
            {showContextMenu && (
              <Menu placement="bottom-end">
                <MenuButton
                  id="case-options"
                  as={ChakraIconButton}
                  boxSize="40px"
                  data-testid="case-options"
                  icon={<Icon as={MdMoreVert} boxSize="28px" />}
                  borderRadius="99px"
                />
                <MenuList zIndex={2}>
                  {canTransfer && (
                    <MenuItem onClick={transferOwnership}>
                      <CaseMenuIcon as={MdForward} />
                      {locale.todo("Transfer Ownership")}
                    </MenuItem>
                  )}
                  <MenuItem onClick={deleteCase} isDisabled={!canDeleteCase}>
                    <CaseMenuIcon as={DeleteIcon} />
                    {locale.todo("Delete Case")}
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            <InfoButton
              isOpen={isOpen}
              onClick={onToggle}
              variant="outline"
              borderRadius="999px"
              boxSize="40px"
              icon={<InfoIcon />}
              aria-label="Case Information"
            />
          </Stack>
        </Stack>
      </Flex>

      {shouldShowSearchSummary && (
        <SearchSummary
          query={imageQuery}
          onEdit={handleSearch}
          onReset={() => {
            const nextLocation = setSearchParams({
              query: []
            });
            history.push(nextLocation);
          }}
        />
      )}
    </>
  );
};

const HeaderCell = ({ children, ...props }: BoxProps) => (
  <Box px={4} {...props}>
    {children}
  </Box>
);

const HeaderLabel = ({ children, ...props }: BoxProps) => (
  <Box color="gray.500" fontSize="sm" whiteSpace="nowrap" {...props}>
    {children}
  </Box>
);
const HeaderValue = ({ children, ...props }: BoxProps) => (
  <Box fontSize="xl" mt={-1} {...props}>
    <Text noOfLines={2}>{children}</Text>
  </Box>
);

type CaseItemNavProps = {
  pathologicCase: Medmain.Case;
  current: "details" | "images" | "attachments" | "comments" | "permissions";
};
export const CaseItemNav = ({ pathologicCase, current }: CaseItemNavProps) => {
  const history = useHistory();
  const location = useLocation();
  const locale = useLocale();
  const { organizations } = useAccount();

  const { id, attachments, images, numberOfComments } = pathologicCase;
  const numberOfImages = images.length;
  const numberOfAttachments = attachments.length;

  const paths: { key: CaseItemNavProps["current"]; path: string }[] = [
    { key: "details", path: CASE_DETAILS_PAGE.getPath({ caseId: id }) },
    { key: "images", path: CASE_LIST_IMAGES_PAGE.getPath({ caseId: id }) },
    { key: "attachments", path: CASE_ATTACHMENTS_PAGE.getPath({ caseId: id }) },
    { key: "comments", path: CASE_COMMENTS_PAGE.getPath({ caseId: id }) },
    { key: "permissions", path: CASE_PERMISSIONS_PAGE.getPath({ caseId: id }) }
  ];

  const currentIndex = paths.findIndex(({ key }) => key === current);

  const caseOrg = organizations.find(
    org => org.id === pathologicCase.organizationId
  );
  const canSeePermissions =
    caseOrg && hasPermission("org/read_partner_list", caseOrg);

  const switchTab = (tabIndex: number) => {
    const pathname = paths[tabIndex].path;
    const nextLocation = { ...location, pathname };
    history.push(nextLocation);
  };

  return (
    <Tabs
      index={currentIndex}
      onChange={switchTab}
      colorScheme="primary"
      isFitted={true}
      size="md"
      mb={6}
    >
      <TabList id="case-tablist">
        <CaseTab>
          <CaseTabIcon as={GoNote} />
          {locale.caseTabs.details}
        </CaseTab>
        <CaseTab>
          <CaseTabIcon as={FileIconImage} />
          {locale.todo("Images")}
          {numberOfImages > 0 && (
            <Badge data-testid="number-of-images" ml={2}>
              {numberOfImages}
            </Badge>
          )}
        </CaseTab>
        <CaseTab>
          <CaseTabIcon as={FileIconPDF} />
          {locale.caseTabs.attachments}
          {numberOfAttachments > 0 && (
            <Badge ml={2}>{numberOfAttachments}</Badge>
          )}
        </CaseTab>
        <CaseTab>
          <CaseTabIcon as={CommentIcon} />
          {locale.caseTabs.comments}
          {numberOfComments > 0 && <Badge ml={2}>{numberOfComments}</Badge>}
        </CaseTab>
        {canSeePermissions && (
          <CaseTab>
            <CaseTabIcon as={MdLock} />
            {locale.caseTabs.permissions}
          </CaseTab>
        )}
      </TabList>
    </Tabs>
  );
};

const CaseTab = styled(Tab)`
  &[aria-selected="true"] svg {
    color: ${props => props.theme.colors.primary["500"]};
  }
`;

const CaseTabIcon = ({ ...props }: React.ComponentProps<typeof Icon>) => (
  <Icon fontSize="20px" color="gray.500" mr={2} {...props} />
);

const CaseMenuIcon = (props: React.ComponentProps<typeof Icon>) => (
  <Icon color="gray.500" mr={2} boxSize="16px" {...props} />
);

export type CaseItemPagerProps = {
  pathologicCase: Medmain.Case;
  reload: FetchCaseState["reload"];
  canCreatePrediction: boolean;
  checkBox: string[];
  onCheckBoxAll: (values: string[]) => void;
  startAIAnalysis: (
    organizationId: string,
    checkedImages: (Medmain.Image & {
      caseNumber: Medmain.Case["caseNumber"];
    })[]
  ) => void;
  updateAllOrganModel: (
    checkedImages: Medmain.Image[],
    reload: () => void
  ) => Promise<void>;
  retrieveCheckedImages: (
    images: (Medmain.Image & { caseNumber: Medmain.Case["caseNumber"] })[],
    key: keyof Medmain.Image
  ) => (Medmain.Image & { caseNumber: Medmain.Case["caseNumber"] })[];
  isPending: boolean;
  caseSorts: Medmain.CaseSort[];
  setCaseSorts: (arr: Medmain.CaseSort[]) => void;
  searchOptions: SearchOptions;
};
export const CaseItemPager = ({
  pathologicCase,
  reload,
  canCreatePrediction,
  checkBox,
  onCheckBoxAll,
  startAIAnalysis,
  updateAllOrganModel,
  retrieveCheckedImages,
  isPending,
  caseSorts,
  setCaseSorts,
  searchOptions
}: CaseItemPagerProps) => {
  const history = useHistory();
  const modal = useModal();
  const locale = useLocale();

  const { checkAIAgreement } = AgreementContainer.useContainer();
  const { images, caseNumber, organizationId } = pathologicCase;
  const { query } = searchOptions;
  const { caseId } = useParams<{ caseId: string }>();
  const { setSearchParams } = useSearch(searchOptions);

  const checkedImages = retrieveCheckedImages(
    images.map(image => ({ caseNumber, ...image })),
    "displayName"
  );
  const imageQuery = ImageQuery.fromJSON(query);

  const handleSearch = async () => {
    const searchState: CaseSearchState = await modal.dialog({
      modalProps: { size: "4xl", isCentered: true },
      render: close => (
        <DialogCaseDetailSearch
          query={imageQuery}
          onClose={close}
          canCreatePrediction={canCreatePrediction}
        />
      )
    });

    if (!searchState) return;

    const nextLocation = setSearchParams({
      query: [
        { field: "id", value: caseId, operator: "is" },
        ...searchState.query.toJSON()
      ],
      hasImage: true
    });
    history.push(nextLocation);
  };

  return (
    <Flex
      align={{ sm: "flex-start", md: "center" }}
      flexDirection={{ sm: "column-reverse", md: "row" }}
      justifyContent={canCreatePrediction ? "space-between" : "flex-end"}
    >
      {canCreatePrediction && (
        <BatchProcessingButtons
          checkBox={checkBox}
          checkedImages={checkedImages}
          images={images}
          isPending={isPending}
          onCheckBoxAll={() =>
            onCheckBoxAll(images.map(image => image.displayName))
          }
          startAIAnalysis={() =>
            checkAIAgreement(() =>
              startAIAnalysis(organizationId, checkedImages)
            )
          }
          updateAllOrganModel={() =>
            checkAIAgreement(() => updateAllOrganModel(checkedImages, reload))
          }
        />
      )}

      <Stack
        isInline
        marginLeft="auto"
        spacing="10px"
        alignItems="center"
        mb={{ sm: "15px", md: 0 }}
      >
        <Stack
          isInline
          spacing="20px"
          alignItems="center"
          pl={{ sm: 0, md: "10px" }}
        >
          <CaseNavigation caseSorts={caseSorts} />
          <SortButton caseSorts={caseSorts} setCaseSorts={setCaseSorts} />
        </Stack>

        {pathologicCase.images.length > 5 && (
          <Link
            flexShrink={0}
            href={`#${PANEL_ANCHOR_ID}`}
            borderRadius="999px"
            borderWidth="1px"
            boxSize="40px"
            aria-label="Case Information"
            alignItems="center"
            justifyContent="center"
            display={{ base: "flex", lg: "none" }}
          >
            <MdArrowDownward fontSize="24px" />
          </Link>
        )}
        <Button flexShrink={0} onClick={handleSearch} leftIcon={<SearchIcon />}>
          {locale.searchButtonLabel}
        </Button>
      </Stack>
    </Flex>
  );
};
