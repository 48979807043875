import React from "react";
import {
  Box,
  BoxProps,
  Flex,
  Icon,
  IconProps,
  Tag,
  TagProps,
  Tooltip,
  Wrap,
  WrapItem,
  WrapProps
} from "@chakra-ui/react";
import { MdHourglassEmpty } from "react-icons/md";
import compact from "lodash/compact";
import countBy from "lodash/countBy";
import flatten from "lodash/flatten";

import { useLocale } from "app/locale";
import { CheckIcon, WarningIcon } from "components/core";
import aiLabels from "data/ai-labels.json";
import aiLabelGroups from "data/ai-label-groups.json";
import { AgreementContainer } from "features/ai-legal-terms/agreement";

type PredictionLabel = {
  name: string;
  displayName?: {
    en: string;
  };
  shortName?: {
    en: string;
  };
  positive?: boolean;
};

export const CasePredictionSummary = ({
  images
}: {
  images: Medmain.Image[];
}) => {
  const summaries = getCaseSummary(images);

  return (
    <Wrap spacing={2}>
      {summaries.map(({ labels, noResults, statuses }, i) => (
        <React.Fragment key={i}>
          {Object.keys(labels).map(labelName => (
            <WrapItem key={labelName}>
              <PredictionFoundLabelTag
                labelName={labelName}
                count={labels[labelName]}
                ellipsisWidth="70px"
              />
            </WrapItem>
          ))}
          {Object.keys(noResults).map(modelName => (
            <WrapItem key={modelName}>
              <PredictionNoResultsTag
                modelName={modelName}
                count={noResults[modelName]}
                ellipsisWidth="70px"
              />
            </WrapItem>
          ))}
          {Object.keys(statuses)
            .filter(status => status !== "completed")
            .map(status => (
              <WrapItem key={status}>
                <PredictionStatusTag
                  status={status as Medmain.Prediction["status"]}
                />
              </WrapItem>
            ))}
        </React.Fragment>
      ))}
    </Wrap>
  );
};

// Organ + Prediction results summary displayed in Case Table and image grid thumbnails
export const ImageSummary = ({
  image,
  visibleImageTag = false,
  ...props
}: { image: Medmain.Image; visibleImageTag?: boolean } & WrapProps) => {
  const { canUsePredictionFeatures } = AgreementContainer.useContainer();
  const { organ, predictionSummary } = image;

  const hasContent =
    image.organ ||
    (image.predictionSummary && image.predictionSummary.length > 0) ||
    image.imageTags?.length > 0;
  if (!hasContent) return <Box />;

  return (
    <Wrap spacing={2} {...props}>
      {organ && (
        <WrapItem>
          <ImageTag>{organ}</ImageTag>
        </WrapItem>
      )}
      {canUsePredictionFeatures &&
        predictionSummary &&
        predictionSummary.length > 0 && (
          <ImagePredictionSummary predictionSummary={predictionSummary} />
        )}
      {visibleImageTag &&
        image.imageTags.map(tag => (
          <ImageTag backgroundColor="primary.500">{tag}</ImageTag>
        ))}
    </Wrap>
  );
};

// Can return one single tag, several tags or nothing at all, depending on the prediction status
export const ImagePredictionSummary = ({
  predictionSummary,
  ...props
}: { predictionSummary: Medmain.Image["predictionSummary"] } & TagProps) => {
  if (!predictionSummary || predictionSummary.length === 0) return null;

  const render = (summary: Medmain.PredictionSummary) => {
    const { status, parsedResult: labelNames, modelName } = summary;
    if (status === "completed") {
      const hasResults = labelNames.length > 0;
      if (hasResults) {
        return (
          <>
            {labelNames &&
              labelNames.map(labelName => (
                <WrapItem key={labelName}>
                  <PredictionFoundLabelTag labelName={labelName} {...props} />
                </WrapItem>
              ))}
          </>
        );
      } else {
        return (
          <WrapItem>
            <PredictionNoResultsTag modelName={modelName} {...props} />
          </WrapItem>
        );
      }
    }

    return (
      <WrapItem>
        <PredictionStatusTag status={status} />
      </WrapItem>
    );
  };

  return (
    <>
      {predictionSummary.map((s, i) => (
        <React.Fragment key={i}>{render(s)}</React.Fragment>
      ))}
    </>
  );
};

const PredictionStatusTag = ({
  status,
  ...props
}: {
  status: Medmain.Prediction["status"];
} & TagProps) => {
  const locale = useLocale();
  const statusDisplayInfo = locale.predictionSummary.status(status);
  const info = `AI Prediction Status: ${statusDisplayInfo}`;

  return (
    <ImageTag {...props}>
      <Tooltip label={info} aria-label="AI status" placement="bottom">
        <Flex alignItems="center">
          <PredictionStatusIcon status={status} />
        </Flex>
      </Tooltip>
    </ImageTag>
  );
};

const PredictionFoundLabelTag = ({
  labelName,
  count,
  ellipsisWidth,
  ...props
}: {
  labelName: string;
  count?: number;
  ellipsisWidth?: string;
} & TagProps) => {
  const locale = useLocale();
  const label = getLabelData(labelName);
  const fullName = locale.get(label.displayName) || label.name;
  const shortName = locale.get(label.shortName);
  const description = `AI Results: "${fullName}" was found`;
  const showCount = Boolean(count && count > 1);

  return (
    <ImageTag
      bg={label.positive === false ? "orange.900" : "blue.900"}
      hasCount={showCount}
      {...props}
    >
      <Tooltip label={description} aria-label="AI Results" placement="bottom">
        <>
          <span
            title={shortName || fullName}
            style={{
              ...(ellipsisWidth && {
                maxWidth: ellipsisWidth,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
              })
            }}
          >
            {shortName || fullName}
          </span>
          {showCount && (
            <TagCount bg={label.positive === false ? "orange.400" : "blue.400"}>
              {count}
            </TagCount>
          )}
        </>
      </Tooltip>
    </ImageTag>
  );
};

const PredictionNoResultsTag = ({
  modelName,
  count,
  ellipsisWidth,
  ...props
}: {
  count?: number;
  modelName: string;
  ellipsisWidth?: string;
} & TagProps) => {
  const locale = useLocale();
  const displayName = locale.predictionSummary.negativeLabel(modelName);
  const description = `AI Prediction Completed: ${displayName} `;
  const showCount = Boolean(count && count > 1);

  return (
    <ImageTag hasCount={showCount} bg="orange.900" {...props}>
      <Tooltip label={description} aria-label="AI Results" placement="bottom">
        <>
          <span
            title={displayName}
            style={{
              ...(ellipsisWidth && {
                maxWidth: ellipsisWidth,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
              })
            }}
          >
            {displayName}
          </span>
          {showCount && <TagCount bg="orange.400">{count}</TagCount>}
        </>
      </Tooltip>
    </ImageTag>
  );
};

export const PredictionStatusIcon = ({
  status,
  ...props
}: {
  status: Medmain.Prediction["status"];
} & IconProps) => {
  switch (status) {
    case "waiting":
    case "running":
      return <Icon as={MdHourglassEmpty} fontSize="20px" ml={0} {...props} />;
    case "completed":
      return <CheckIcon fontSize="16px" color="green.600" {...props} />;
    case "failed":
      return <WarningIcon color="red.600" {...props} />;
    default:
      return null;
  }
};

export const ImageTag = ({
  hasCount,
  ...props
}: { hasCount?: boolean } & TagProps) => {
  return (
    <Tag
      color="gray.900"
      colorScheme="gray"
      rounded="full"
      pr={hasCount ? 1 : 2}
      py={2}
      {...props}
    />
  );
};

const TagCount = (props: BoxProps) => {
  return (
    <Box
      d="inline-flex"
      ml={1}
      px={2}
      py={0}
      height="20px"
      alignItems="center"
      fontSize="13px"
      borderRadius="999px"
      color="white"
      {...props}
    />
  );
};

// Lookup label data from JSON files, "groups of labels" having a higher priority
function getLabelData(labelName: string): PredictionLabel {
  const foundGroup = aiLabelGroups.find(group =>
    group.labelNames.includes(labelName)
  );
  if (foundGroup) {
    return foundGroup;
  }

  const labelFound = aiLabels.find(label => label.name === labelName);
  if (labelFound) {
    return labelFound;
  }

  return { name: labelName }; // fallback if we get a label not setup in the JSON files (it should not happen!)
}

export function getCaseSummary(images: Medmain.Image[]) {
  const summariesArray = compact(images.map(image => image.predictionSummary));

  return summariesArray.map(summaries => {
    const statuses = countBy<Medmain.Prediction["status"]>(
      summaries.map(summary => summary.status)
    );

    const labels = countBy<string>(
      flatten(summaries.map(summary => summary.parsedResult))
    );

    const noResults = countBy<Medmain.Prediction["modelName"]>(
      summaries
        .filter(
          summary =>
            summary.status === "completed" && summary.parsedResult.length === 0
        )
        .map(summary => summary.modelName)
    );

    return { statuses, labels, noResults };
  });
}
