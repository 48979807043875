import React from "react";
import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Icon,
  IconButton,
  Link,
  LinkProps,
  List,
  ListItem,
  Stack,
  useBreakpoint,
  Text
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { IconType } from "react-icons";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";
import { HiOutlineChat } from "react-icons/hi";
import { CgFileDocument } from "react-icons/cg";
import { FaRegBuilding } from "react-icons/fa";
import { FiUsers, FiX } from "react-icons/fi";
import {
  RiFileDownloadLine,
  RiFileUploadLine,
  RiFolderUploadLine
} from "react-icons/ri";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink,
  useHistory
} from "react-router-dom";
import { useLocale } from "app/locale";
import { useAccount } from "app/auth-container";
import { BulkUploadContainer } from "app/upload-bulk-images-container";
import { ImportCSVContainer } from "app/import-csv-container";
import { UserMenu } from "./user-menu";

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  &:hover:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["400"]};
  }
`;

export function Sidebar({
  isOpen,
  width,
  onClose
}: {
  isOpen: boolean;
  width: number | string;
  onClose: () => void;
}) {
  const screenSize = useBreakpoint() ?? "";

  React.useEffect(() => {
    let className = "body-lock";

    if (isOpen) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }, [isOpen]);

  return (
    <Flex
      backgroundColor="gray.900"
      flexDirection="column"
      borderRightWidth="1px"
      width={width}
      height="100%"
      pt={{ base: 11, md: 0 }}
      pb={{ base: 30, md: 7 }}
      position={{ base: "fixed" }}
      zIndex={{ base: 20 }}
      transform={{
        base: `translateY(${isOpen ? 0 : "-100%"})`,
        md: "initial"
      }}
      transition={{ base: "transform 0.5s", md: "none" }}
    >
      <Stack
        position="relative"
        pt={{ base: 0, md: 7 }}
        spacing={{ base: 0, md: 12 }}
        flexGrow={{ base: "initial", md: 1 }}
        shouldWrapChildren
      >
        <Flex justifyContent="space-between" pr={{ base: 6, md: 0 }}>
          <Logo />
          <Flex>
            {screenSize === "sm" && <UserMenu />}
            <StyledIconButton
              display={{ base: "block", md: "none" }}
              ml={0}
              mx={{ base: 0, md: 6 }}
              icon={<FiX fontSize="28px" />}
              boxSize="40px"
              aria-label="Close the Menu"
              onClick={onClose}
              variant="ghost"
              color="white"
              borderRadius="99px"
            />
          </Flex>
        </Flex>
        <MainNav onClose={onClose} />
      </Stack>
    </Flex>
  );
}

const StyledLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  :hover {
    text-decoration: none;
  }
`;

// Show an indication about the current environment, when we are not in production.
export const EnvironmentMark = () => {
  const envName = process.env.REACT_APP_ENV_NAME;
  const color = process.env.REACT_APP_ENV_COLOR || "primary.300";
  if (!envName) return null;

  return (
    <Box
      backgroundColor={color}
      position="absolute"
      m={0}
      bottom={{ base: "-4px", md: "-22px" }}
      right={0}
      px={1}
      py={0}
      color="white"
      textTransform="uppercase"
      fontSize={{ base: "10px", md: "11px" }}
      fontWeight="bold"
      borderRadius="3px"
    >
      {envName}
    </Box>
  );
};

const CountBadge = (props: BoxProps) => {
  return (
    <Box position="absolute" top={2} right={4} color="gray.500" {...props} />
  );
};

const NavBox = styled(Box, {
  shouldForwardProp: prop => prop !== "screenSize"
})<{ screenSize: string }>`
  li > a {
    color: white;
    display: block;
    outline: none;
    border-radius: ${props =>
      props.screenSize === "sm" ? "0;" : "0 66px 66px 0;"};
  }
  .active {
    color: white;
    border-radius: ${props =>
      props.screenSize === "sm" ? "0;" : "0 66px 66px 0;"};
    background-color: ${props =>
      props.screenSize === "sm" ? "transparent" : "rgba(166, 175, 181, 0.5)"};
  }
  a:hover {
    text-decoration: none;
  }
  li > a:not(.active):hover {
    color: white;
    text-decoration: none;
    border-radius: ${props =>
      props.screenSize === "sm" ? "0;" : "0 66px 66px 0;"};
    background-color: ${props =>
      props.screenSize === "sm" ? "transparent" : "rgba(203, 209, 211, 0.2)"};
  }
  li > a:focus {
    box-shadow: none;
  }
`;

export const Logo = () => {
  const screenSize = useBreakpoint() ?? "";
  const envName = process.env.REACT_APP_ENV_NAME;
  const src = `${process.env.PUBLIC_URL}/assets/${
    screenSize === "sm" ? "logo_200x40.png" : "nikon-logo_2lines.svg"
  }`;

  return (
    <Box
      display={{ base: envName ? "block" : "flex", md: "flex" }}
      ml={8}
      height={{ base: "34px", md: "auto" }}
      position="relative"
    >
      <StyledLink to="/">
        <img alt="Logo" width={screenSize === "sm" ? 200 : 176} src={src} />
      </StyledLink>
      <EnvironmentMark />
    </Box>
  );
};

const Nav = ({ children }) => {
  const screenSize = useBreakpoint() ?? "";
  return (
    <NavBox
      sx={{
        height: { base: "calc(100vh - 180px)", md: "auto" },
        "&": {
          height: { base: "calc(100svh - 180px)", md: "auto" }
        }
      }}
      display={{ base: "flex", md: "block" }}
      alignItems="center"
      screenSize={screenSize}
    >
      <List
        w="100%"
        h={{ base: "min(100%, 400px)", md: "100%" }}
        display={{ base: "flex", md: "block" }}
        flexDirection="column"
        borderTop={{ base: "1px solid #7D7D7D", md: "none" }}
        fontWeight={{ base: "bold", md: "normal" }}
      >
        {children}
      </List>
    </NavBox>
  );
};

const NavItem = ({
  icon,
  children,
  ...props
}: { icon?: IconType } & RouterLinkProps<unknown>) => {
  return (
    <ListItem>
      <Link
        as={NavLink}
        {...props}
        pl={{ base: "20px", md: "25px" }}
        py={{ base: "11px", md: "7px" }}
        mr={{ base: 0, md: "15px" }}
        borderBottom={{ base: "1px solid #7D7D7D", md: "none" }}
        position="relative"
      >
        <Flex as="span" alignItems="center">
          {<Icon as={icon} boxSize={6} mr={3} />}
          {children}
        </Flex>
      </Link>
    </ListItem>
  );
};

const MainNav = ({ onClose }) => {
  const locale = useLocale();
  const history = useHistory();
  const screenSize = useBreakpoint() ?? "";
  const { imageTotal } = BulkUploadContainer.useContainer();
  const { rowTotal } = ImportCSVContainer.useContainer();
  const {
    menuList,
    isAdministratorRole,
    isMemberRole,
    isProprietorRole,
    isCustomerSupportRole,
    isNikonCustomerSupportRole,
    isSuper,
    signOut
  } = useAccount();

  const defaultMenuList = [
    {
      menuName: "Groups",
      url: "/organizations",
      icon: FaRegBuilding
    },
    {
      menuName: "Cases",
      url: "/cases",
      icon: AiOutlineFileSearch
    },
    isMemberRole && {
      menuName: "Upload Files",
      url: "/bulk-upload",
      icon: RiFolderUploadLine
    },
    isAdministratorRole && {
      menuName: "Users",
      url: "/users",
      icon: FiUsers
    },
    (isNikonCustomerSupportRole || isProprietorRole) && {
      menuName: "Contracts",
      url: "/contracts",
      icon: CgFileDocument
    },
    isSuper() && {
      menuName: "Import CSV",
      url: "/import-csv",
      icon: RiFileUploadLine
    },
    (isSuper() || isCustomerSupportRole || isNikonCustomerSupportRole) && {
      menuName: "Export CSV",
      url: "/export-csv",
      icon: RiFileDownloadLine
    }
  ].filter(value => !!value) as Medmain.Menu[];

  const userMenu = [
    {
      menuName: locale.userMenuAccountItemLabel,
      onClick: () => {
        history.push("/my-account");
        onClose();
      }
    },
    {
      menuName: locale.userMenuSignOutItemLabel,
      onClick: signOut
    }
  ];

  return (
    <Nav>
      {(menuList?.menuList.length
        ? (menuList?.menuList as Medmain.Menu[])
        : defaultMenuList
      ).map((item, index) => (
        <NavItem
          key={index}
          to={item.url}
          icon={
            defaultMenuList.find(
              icon => icon.menuName === item.menuName && icon.url === item.url
            )?.icon
          }
        >
          {locale.todo(item.menuName)}
          {item.menuName === "Upload Files" && imageTotal > 0 && (
            <CountBadge>{imageTotal}</CountBadge>
          )}
          {item.menuName === "Import CSV" && rowTotal > 0 && (
            <CountBadge>{rowTotal}</CountBadge>
          )}
        </NavItem>
      ))}
      <CustomerHelpNav />
      {screenSize === "sm" && (
        <>
          <Divider color="dividerColor" m="10px auto" w="90%" />
          {userMenu.map((menu, index) => (
            <Flex
              key={index}
              as="span"
              py={1}
              pl="20px"
              onClick={menu.onClick}
              alignItems="center"
            >
              {menu.menuName}
            </Flex>
          ))}
        </>
      )}
    </Nav>
  );
};

const SupportBox = styled(Box, {
  shouldForwardProp: prop => prop !== "screenSize"
})<{ screenSize: string }>`
  padding: 7px 0;
  width: fit-content;
  color: white;
`;

export const ExternalLink = ({
  _hover = { color: "inherit" },
  ...props
}: LinkProps) => {
  return (
    <Link display="block" target="_blank" _hover={_hover} {...props}></Link>
  );
};

const CustomerHelpNav = () => {
  const locale = useLocale();
  const screenSize = useBreakpoint() ?? "";

  return (
    <Box
      position={{ md: "absolute" }}
      bottom={0}
      pl={{ base: "20px", md: "25px" }}
      pt={3}
    >
      <SupportBox screenSize={screenSize}>
        <Link
          display="flex"
          alignItems="center"
          target="_blank"
          href={process.env.REACT_APP_ZENDESK_HELP_CENTER_URL}
          _hover={{
            color: "white"
          }}
        >
          <Icon as={BiHelpCircle} boxSize={6} mr={3} />
          <Text
            _hover={{
              textDecoration: "underline"
            }}
          >
            {locale.todo("Help")}
          </Text>
        </Link>
      </SupportBox>
      <SupportBox screenSize={screenSize}>
        <Link
          display="flex"
          target="_blank"
          href={process.env.REACT_APP_ZENDESK_INQUIRY_URL}
          _hover={{
            color: "white"
          }}
        >
          {<Icon as={HiOutlineChat} boxSize={6} mr={3} />}
          <Text
            _hover={{
              textDecoration: "underline"
            }}
          >
            {locale.todo("Contact Us")}
          </Text>
        </Link>
      </SupportBox>
    </Box>
  );
};
