import { Link as ChakraLink } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const Link = styled(ChakraLink)`
  ${props => `
  word-break: break-all;
  outline: none;
  svg {
    color: ${props.theme.colors.gray["500"]};
    transition: all 0.15s ease-out;
  }
  &:hover:not(:disabled) {
    color: ${props.theme.colors.gray["900"]};
    text-decoration: underline;
    svg {
      color: ${props => props.theme.colors.primary["500"]};
    }
  }
  &:active:not(:disabled) {
    box-shadow: 0 0 0 2px ${props.theme.colors.gray900Alpha(0.5)};
    color: ${props.theme.colors.gray["900"]};
    text-decoration: none;
  }
  `}
`;
